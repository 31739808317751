import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button } from "@mui/material";
import UserContext from "./../../../Context/UserContext";
import FormContext from "./../../../Context/FormContext";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { FileUpload } from "./../../Misc/Misc"; 
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";

const LogoNameSettings = (props) => {
	// context
	const context = useContext(UserContext);
	const forms = useContext(FormContext);
	// state
	const [settings, setSettings] = useState(null);
	const [errors, setErrors] = useState({});
	// validation constraints
	const constraints = {
		"application_name": { presence: { allowEmpty: true }, length: { maximum: 255 } }
	};
	// loading
	useEffect(()=>{
		if(settings !== null){
			return;
		}
		const s = (response) => { 
			if(response.status === 204){
				// create placeholder settings
				const settings = {
					"admin_logo_data": "",
					"customer_logo_data": "",
					"public_logo_data": "",
					"admin_logo_mime_type": "",
					"customer_logo_mime_type": "",
					"public_logo_mime_type": "",
					"admin_logo_name": "",
					"customer_logo_name": "",
					"public_logo_name": "",
					"application_name": ""
				};
				setSettings(settings);
				return;
			}
			const data = response.data;
			setSettings(data);
		};
		const e = (error) => { toast.error("Failed to load logo and name settings, please contact support."); };
		context.api.getLatestLogoName(s, e);
	});
	// events
	const onApplicationNameChanged = (event) => {
		const value = event.target.value;
		const copy = {...settings};
		copy.application_name = value;
		setSettings(copy);
	};
	const addLogoNameSettings = (event) => {
		const errors = forms.formHelper.validateAll(constraints, settings);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const s = (response) => {
			const data = response.data;
			toast.success("Logo and name settings updated");
			setSettings(data);
		};
		const e = (error) => { toast.error("Failed to update logo and name settings, please contact support."); };
		context.api.addLogoNameSettings(s, e, settings);
	};
	const isFileAccepted = (file) => {
		return file.size <= 2 * 1024 * 1024 && 
			( file.type === "image/png" || file.type === "image/jpeg" ); 
	};
	const onAdminLogoChanged = (file) => {
		const copy = {...settings};
		copy.admin_logo_name = file.name;
		copy.admin_logo_data = file.data;
		copy.admin_logo_mime_type = file.type;
		setSettings(copy);
	};
	const onCustomerLogoChanged = (file) => {
		const copy = {...settings};
		copy.customer_logo_name = file.name;
		copy.customer_logo_data = file.data;
		copy.customer_logo_mime_type = file.type;
		setSettings(copy);
	};
	const onPublicLogoChanged = (file) => {
		const copy = {...settings};
		copy.public_logo_name = file.name;
		copy.public_logo_data = file.data;
		copy.public_logo_mime_type = file.type;
		setSettings(copy);
	};
	const rejectFile = () => {
		toast.error("Logos must be at most 2MB and either a PNG or JPEG image.");
	};
	// helpers
	const validateSingle = (field, value) => {
		const copy = {...errors};
		delete copy[field];
		setErrors(copy);
		const e = forms.formHelper.validateSingle(constraints, field, value);
		if(e !== undefined){
			setErrors({...e, ...errors});
		}
	};
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{ return <small style={{display:"block"}} key={`${field}_error_${i}`}>{e}</small>});
		}
		const helperText = {
			"application_name": "The name of this application",
		};
		return helperText[field];
	}
	const getHasError = (field) => {
		return errors[field] !== undefined;
	}
	let content = <LoadingSpinner />;
	if(settings !== null){
		const exists = settings.id !== undefined;
		let header = `Current Logo and Name Settings - None`;
		if(exists){
			const dateCreated = new Date(Date.parse(settings.created_at));
			header = `Current Logo and Name Settings - Created ${dateCreated.toLocaleDateString()}`;
		}
		// handle missing, updated and existing URIs
		// for all logos 
		let adminURI = settings.admin_uri;
		let customerURI = settings.customer_uri;
		let publicURI = settings.public_uri;
		if(adminURI === undefined){
			if(settings.admin_logo_data !== undefined){
				adminURI = `data:${settings.admin_logo_mime_type};base64,${settings.admin_logo_data}`;
			}
		}
		content = (
			<Stack spacing={2}>
				<Box sx={{textAlign:"center"}}>
					<Stack spacing={1}>
						<InStockHeader>{header}</InStockHeader>
						<InStockSubHeader>Please complete the following form to update logo and name settings</InStockSubHeader>
						<InStockSubHeader><strong>All</strong> of these inputs are required.</InStockSubHeader>
					</Stack>
				</Box>
				<TextField 
					error={getHasError("application_name")}
					type={"text"} 
					fullWidth 
					label="Application Name"
					size={"large"} 
					value={settings.application_name} 
					onChange={onApplicationNameChanged}
					onBlur={()=>{validateSingle("application_name", settings.application_name);}}
					helperText={getHelperText("application_name")} />
				<Grid container>
					<Grid item lg={4} sm={12} sx={{textAlign:"center"}}>
						<Box sx={{padding:"15px", border:"1px solid #CDCDCD", borderRadius:"10px"}}>
							<img className={"ImagePreview"} src={adminURI} alt={"admin logo"} style={{width:"100%", height:"auto"}} />
						</Box>
					</Grid>
					<Grid item lg={8} sm={12}>
						<Box sx={{padding:"15px"}}>
							<FileUpload id={"admin"} helperText={"Logo for admin portal"} 
								fileName={settings.admin_logo_name} 
								onFileChanged={onAdminLogoChanged} 
								acceptFile={isFileAccepted} 
								rejectFile={rejectFile} />
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item lg={4} sm={12} sx={{textAlign:"center"}}>
						<Box sx={{padding:"15px", border:"1px solid #CDCDCD", borderRadius:"10px"}}>
							<img className="ImagePreview" src={customerURI} 
								alt={"customer logo"} style={{verticalAlign:"middle", width:"100%", height:"auto"}} />
						</Box>
					</Grid>
					<Grid item lg={8} sm={12}>
						<Box sx={{padding:"15px"}}>
						<FileUpload id={"customer"} helperText={"Logo for customer portal"} 
							fileName={settings.customer_logo_name} 
							onFileChanged={onCustomerLogoChanged} 
							acceptFile={isFileAccepted} 
							rejectFile={rejectFile} />
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item lg={4} sm={12} sx={{textAlign:"center"}}>
						<Box sx={{padding:"15px", border:"1px solid #CDCDCD", borderRadius:"10px"}}>
							<img className={"ImagePreview"} src={publicURI} 
								alt={"public logo"} style={{verticalAlign:"middle", width:"100%", height:"auto"}} />
						</Box>
					</Grid>
					<Grid item lg={8} sm={12}>
						<Box sx={{padding:"15px"}}>
						<FileUpload id={"public"} helperText={"Logo for public facing website."} 
							fileName={settings.public_logo_name} 
							onFileChanged={onPublicLogoChanged} 
							acceptFile={isFileAccepted} 
							rejectFile={rejectFile} />
						</Box>
					</Grid>
				</Grid>
				<Grid container>	
					<Grid item lg={4} sm={12}/>
					<Grid item lg={4} sm={12}>
						<InStockButton size={"large"} fullWidth onClick={addLogoNameSettings}>Submit</InStockButton>
					</Grid>	
				</Grid>
			</Stack>
		);
	}
	return (
		<Container maxWidth="md">
			<Paper elevation={3} sx={{padding:"15px"}}>
				{content}
			</Paper>			
		</Container>
	);
};

export default LogoNameSettings;
