// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Box, Dialog } from "@mui/material";
import NewStockDialog from "./../../Components/AddStockDialog/AddStockDialog";
import LoadingDialog from "./../../Components/LoadingDialog/LoadingDialog";
import DraftSelectionDialog from "./../../Components/DraftSelectionDialog/DraftSelectionDialog";
import TakeStockDialog from "./../../Components/TakeStockDialog/TakeStockDialog";
import ReceiveStockDialog from "./../../Components/ReceiveStockDialog/ReceiveStockDialog";
import UserProfileDialog from "./../../Components/UserProfileDialog/UserProfileDialog";
import ConfirmationDialog from "./../../Components/ConfirmationDialog/ConfirmationDialog";
import UserContext from "./../../Context/UserContext";
import DownloadDialog from "./../../Components/DownloadDialog/DownloadDialog";
import AddCategoryDialog from "./../../Components/AddCategoryDialog/AddCategoryDialog";
import AddStocktakingLocationDialog from "./../../Components/AddStocktakingLocationDialog/AddStocktakingLocationDialog";
import ActivateSalesItemDialog from "./../../Components/ActivateSalesItemDialog/ActivateSalesItemDialog";
import ReportUsageDialog from "./../../Components/ReportUsageDialog/ReportUsageDialog";
import ViewStockTakesDialog from "./../../Components/ViewStockTakesDialog/ViewStockTakesDialog";
import StockReportsDialog from "./../../Components/StockReportsDialog/StockReportsDialog";

const Dialogs = (props) => {
	// context
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	// state
	const [reports, setReports] = useState(props.reports);
	const [reportUsage, setReportUsage] = useState(props.reportUsage);
	const [activateSalesItem, setActivateSalesItem] = useState(props.activateSalesItem);
	const [addStocktakingLocation, setAddStocktakingLocation] = useState(props.addStocktakingLocation);
	const [addCategory, setAddCategory] = useState(props.addCategory);
	const [addStock, setAddStock] = useState(props.addStock);
	const [viewStockTakes, setViewStockTakes] = useState(props.viewStockTakes);
	const [loading, setLoading] = useState(props.loading);
	const [loadingMessage, setLoadingMessage] = useState(props.loadingMessage);
	const [takeStockDraft, setTakeStockDraft] = useState(props.takeStockDraft);
	const [receiveStockDraft, setReceiveStockDraft] = useState(props.receiveStockDraft);
	const [confirmAction, setConfirmAction] = useState(props.confirmAction);
	const [profile, setProfile] = useState(props.profile);
	const [download, setDownload] = useState(props.download);
	const [draftId, setDraftId] = useState(null);
	// draft state
	// drafts might not need to be stored in here
	// consider moving them out to component state?
	const [drafts, setDrafts] = useState(null);
	const [draftMode, setDraftMode] = useState(null);
	const [takeStock, setTakeStock] = useState(false);
	const [receiveStock, setReceiveStock] = useState(false);
	// effects
	const watchReports = () => {
		if(props.reports !== reports){
			setReports(props.reports);
		}
	};
	const watchViewStockTakes = () => {
		if(props.viewStockTakes !== viewStockTakes){
			setViewStockTakes(props.viewStockTakes);
		}	
	};
	const watchReportUsage = () => {
		if(props.reportUsage !== reportUsage){
			setReportUsage(props.reportUsage);
		}
	};
	const watchActivateSalesItem = () => {
		if(props.activateSalesItem !== activateSalesItem){
			setActivateSalesItem(props.activateSalesItem);
		}
	};
	const watchStocktakingLocation = () => {
		if(props.addStocktakingLocation !== addStocktakingLocation){
			setAddStocktakingLocation(props.addStocktakingLocation);
		}
	};
	const watchCategory = () => {
		if(props.addCategory !== addCategory){
			setAddCategory(props.addCategory);
		}
	}
	const watchDownload = () => {
		if(props.download !== download){
			setDownload(props.download);
		}
	};
	const watchConfirmation = () => {
		if(props.confirmAction !== confirmAction){
			setConfirmAction(props.confirmAction);
		}
	};
	const watchStock = () => {
		if(props.addStock !== addStock){
			setAddStock(props.addStock);
		}	
	};
	const watchProfile = () => {
		if(props.profile !== profile){	
			setProfile(props.profile);
		}
	};
	const watchLoading = () => {
		if(props.loading !== loading){
			setLoadingMessage(props.loadingMessage);
			setLoading(props.loading);
		}
	};
	const watchTakeStock = () => {
		if(props.takeStockDraft !== takeStockDraft){
			const s = (response) => {
				const drafts = response.data;
				if(drafts.length > 0){
					setDraftMode("take");
					setDrafts(drafts);
					setTakeStockDraft(true);	
					setTakeStock(false);
					return;
				}
				setDraftId(null);
				setTakeStockDraft(true);	
				setTakeStock(true);
			};
			const e = (error) => {
				toast.error("Failed to get take stock drafts, please contact support.");
			};
			if(props.takeStockDraft){
				if(!impersonate){
					context.api.getTakeStockDrafts(context, s, e);
					return;
				}
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts`,
      		"method": "GET",
				};
				context.api.doImpersonate(context, s, e, payload);
			}else{
				setTakeStockDraft(false);
				setTakeStock(false);
			}
		}	
	};
	const watchReceiveStock = () => {
		if(props.receiveStockDraft !== receiveStockDraft){
			const s = (response) => {
				const drafts = response.data;
				if(drafts.length > 0){
					setDraftMode("receive");
					setDrafts(drafts);
					setReceiveStockDraft(true);	
					setReceiveStock(false);
					return;
				};
				setDraftId(null);
				setReceiveStockDraft(true);
				setReceiveStock(true);
			};
			const e = (error) => {
				toast.error("Failed to get receive stock drafts, please contact support.");
			};
			if(props.receiveStockDraft){
				if(!impersonate){
					context.api.getReceiveStockDrafts(context, s, e);
					return;
				}
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts`,
      		"method": "GET",
				};
				context.api.doImpersonate(context, s, e, payload);
			}else{
				setReceiveStockDraft(false);
				setReceiveStock(false);
			}
		}	
	};
	// watch for changes in the addStock property
	// as callee component needs to inform this component
	// when to display each dialog
	useEffect(watchProfile);
	useEffect(watchStock);
	useEffect(watchLoading);
	useEffect(watchTakeStock);
	useEffect(watchReceiveStock);
	useEffect(watchConfirmation);
	useEffect(watchDownload);
	useEffect(watchCategory);
	useEffect(watchStocktakingLocation);
	useEffect(watchActivateSalesItem);
	useEffect(watchReportUsage);
	useEffect(watchViewStockTakes);
	useEffect(watchReports);
	// events
	const onNewDraftClicked = () => {
		if(draftMode === "receive"){
			setDraftId(null);
			setReceiveStock(true);
			return;
		}	
		if(draftMode === "take"){
			setDraftId(null);
			setTakeStock(true);
			return;
		}
	};
	const onDeleteDraftClicked = (id) => {
		if(draftMode === "receive"){
			const s = (response) => { const drafts = response.data; toast.success("Draft deleted"); setDrafts(drafts); };
			const e = (error) => { toast.error("Failed to delete draft, please contact support.");};
			if(!impersonate){
				context.api.deleteReceiveStockDraft(context, s, e, id);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/${id}`,
      	"method": "DELETE",
			};
			context.api.doImpersonate(context, s, e, payload);
			return;
		}	
		if(draftMode === "take"){
			const s = (response) => { const drafts = response.data; toast.success("Draft deleted"); setDrafts(drafts); };
			const e = (error) => { toast.error("Failed to delete draft, please contact support. "); };
			if(!impersonate){
				context.api.deleteTakeStockDraft(context, s, e, id);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts/${id}`,
      	"method": "DELETE",
			};
			context.api.doImpersonate(context, s, e, payload);
			return;
		}
	};
	const onResumeDraftClicked = (id) => {
		if(draftMode === "receive"){
			setDraftId(id*1);
			setReceiveStock(true);
			setTakeStock(false);
			return;
		}	
		if(draftMode === "take"){	
			setDraftId(id*1);
			setTakeStock(true);
			setReceiveStock(false);
			return;
		}
	};
	return (
		<Box sx={{backgroundColor:"inherit"}}>
			{/* dialog for viewing all stock takes, finalised and new */}
			<Dialog maxWidth={"lg"} fullWidth open={viewStockTakes} onClose={props.onViewStockTakesClosed}>
				<ViewStockTakesDialog impersonate={impersonate} 
					onCloseClicked={props.onViewStockTakesClosed} />
			</Dialog>
			{/* dialog for generating reports on stock items */}
			<Dialog maxWidth={"xl"} fullWidth open={reports} onClose={props.onReportsClosed}>
				<StockReportsDialog impersonate={impersonate} 
					onCloseClicked={props.onReportsClosed} />
			</Dialog>
			{/* dialog for reporting usage of a stock item */}
			<Dialog maxWidth={"lg"} fullWidth open={reportUsage} onClose={props.onReportUsageClosed}>
				<ReportUsageDialog impersonate={impersonate} 
					onCloseClicked={props.onReportUsageClosed}
					onReportUsage={props.onReportUsage} />
			</Dialog>
			{/* dialog for activating sales items */}
			<Dialog maxWidth={"sm"} fullWidth open={activateSalesItem} onClose={props.onActivateSalesItemClosed}>
				<ActivateSalesItemDialog impersonate={impersonate} 
					onCloseClicked={props.onActivateSalesItemClosed} 
					onSalesItemActivated={props.onSalesItemActivated} />
			</Dialog>
			{/* dialog for adding/deleting stock taking locations */}
			<Dialog maxWidth={"sm"} fullWidth 
					open={addStocktakingLocation} 
					onClose={props.onAddStocktakingLocationClosed}>
				<AddStocktakingLocationDialog 
					impersonate={impersonate}
					onCloseClicked={props.onAddStocktakingLocationClosed} 
					onStocktakingLocationAdded={props.onStocktakingLocationAdded}
					onStocktakingLocationDeleted={props.onStocktakingLocationDeleted} />	
			</Dialog>
			{/* dialog for add categories */}
			<Dialog maxWidth={"sm"} fullWidth open={addCategory} onClose={props.onAddCategoryClosed}>
				<AddCategoryDialog impersonate={impersonate} 
					onCloseClicked={props.onAddCategoryClosed} 
					onCategoryAdded={props.onCategoryAdded} />
			</Dialog>
			{/* dialog for updating profile */}
			<Dialog maxWidth={"lg"} fullWidth open={profile} onClose={props.onProfileClosed}>
				<UserProfileDialog impersonate={impersonate} 
					onCloseClicked={props.onProfileClosed} 
					onUserChanged={props.onUserChanged} />
			</Dialog>
			{/* dialog for downloading files, reports, etc ... */}
			<Dialog maxWidth={"sm"} fullWidth open={download} onClose={props.onDownloadClosed}>
				<DownloadDialog impersonate={impersonate} 
					onCloseClicked={props.onDownloadClosed} 
					data={props.fileToDownload} 
          fileName={props.fileName}/>
			</Dialog>
			{/* dialog for confirming actions */}
			<Dialog maxWidth={"sm"} fullWidth open={confirmAction} onClose={props.onConfirmationClosed}>
				<ConfirmationDialog 
					impersonate={impersonate}
					message={props.confirmationMessage}
					onConfirmed={props.onConfirmationAccepted} 
					onCancelled={props.onConfirmationRejected} 
					onClose={props.onConfirmationClosed}
					onCloseClicked={props.onConfirmationClosed} />
			</Dialog>
			{/* dialog for adding stock */}
			<Dialog maxWidth="sm" fullWidth open={addStock} onClose={props.onStockCloseClicked}>
				<NewStockDialog impersonate={impersonate} 
					onCloseClicked={props.onStockCloseClicked} 
					onStockItemAdded={(event)=>{props.onStockAdded(event, false);}} />
			</Dialog>
			{/* dialog for displaying a loading spinner, currently used in the home page  */}
			<Dialog maxWidth="sm" open={loading} onClose={(event)=>{}}>
				<LoadingDialog message={loadingMessage} />
			</Dialog>
			{/* dialog for selecting a receive stock draft */}
			<Dialog maxWidth={"sm"} open={receiveStockDraft && !receiveStock} onClose={props.onReceiveStockDraftClosed}>
				<DraftSelectionDialog 
					drafts={drafts}
					onNewDraftClicked={(event)=>{onNewDraftClicked();}} 
					onCloseClicked={props.onReceiveStockDraftClosed} 
					onDeleteDraftClicked={(event,id)=>{onDeleteDraftClicked(id);}} 
					onResumeDraftClicked={(event,id)=>{onResumeDraftClicked(id);}} />
			</Dialog>
			{/* dialog for selecting a take stock draft */}
			<Dialog maxWidth={"sm"} open={takeStockDraft && !takeStock} onClose={props.onTakeStockDraftClosed}>
				<DraftSelectionDialog 
					drafts={drafts}
					onNewDraftClicked={(event)=>{onNewDraftClicked();}} 
					onCloseClicked={props.onTakeStockDraftClosed} 
					onDeleteDraftClicked={(event,id)=>{onDeleteDraftClicked(id);}} 
					onResumeDraftClicked={(event,id)=>{onResumeDraftClicked(id);}} />
			</Dialog>
			{/* dialog for receiving stock 
			    use when the business has received stock from a supplier */}
			<Dialog maxWidth="lg" fullWidth open={receiveStock} onClose={props.onReceiveStockClosed}>
				<ReceiveStockDialog onCloseClicked={props.onReceiveStockClosed} 
					onDraftFinalised={props.onDraftFinalised} 
					draftId={draftId} 
					impersonate={impersonate} />
			</Dialog>
			{/* dialog for performing a stock take - taking stock 
			    opened if there's no draft and the take stock button was clicked */}
			<Dialog maxWidth="lg" fullWidth open={takeStock} onClose={props.onTakeStockClosed}>
				<TakeStockDialog 
					impersonate={impersonate}
					draftId={draftId}
					onCloseClicked={props.onTakeStockClosed} 
					onDraftFinalised={()=>{props.onTakeStockClosed();props.onDraftFinalised();}} />
			</Dialog>
			{props.children}
		</Box>
	);
};
export default React.memo(Dialogs);
