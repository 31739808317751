// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Container,
	Typography, 
	Stack, Box,
	Switch, IconButton, 
	Paper, Grid, FormGroup, 
	Dialog,
	Tooltip,
	FormControlLabel } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material"; 
import EditIcon from '@mui/icons-material/Edit';
import UserContext from "./../Context/UserContext";
import DefaultLayout from "./../Layouts/DefaultLayout";
import StockItemDetails from "./../Components/StockItemDetails/StockItemDetails";
import AddStockDialog from "./../Components/AddStockDialog/AddStockDialog";
import InventoryTransactions from "./../Components/InventoryTransactions/InventoryTransactions";
const StockItemPage = (props) => {
	const navigate = useNavigate();
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const context = useContext(UserContext);
	const [item, setItem] = useState(context.stockItem); // grab currently selected item from context
	const [dialog, setDialog] = useState(null);
	const editStockItem = () => {
		context.stockItemName = item.name;
		const onUpdate = (stockItem) => {
			context.stockItem = stockItem;
			context.stockItemName = stockItem.name;
			setItem(stockItem);
		};	
		const dialog = (
			<Dialog maxWidth={"sm"} fullWidth open={true} onClose={()=>{setDialog(null);}}>
				<AddStockDialog impersonate={impersonate} editing={true} onStockUpdated={onUpdate} onCloseClicked={()=>{setDialog(null);}}/>
			</Dialog>
		);
		setDialog(dialog);	
	};
	const back = impersonate ? "/accounts/impersonate/stock-items" : "/stock-items";
	const content = (
		<Box sx={{padding:"25px"}}>
			{dialog}
			<Stack spacing={2}>
					<Box sx={{display:"inline-block"}}>
						<IconButton onClick={()=>{navigate(back);}}>
							<KeyboardArrowLeft sx={{color:"black", height:"24px", width:"auto"}}/>
						</IconButton>
						<span style={{verticalAlign:"middle", color:"black"}}>Back to Stock Items</span>
					</Box>
					<Grid container>
					<Grid item sm={12} sx={{textAlign:"left"}}>
					<Typography variant="span" sx={{verticalAlign:"middle", color:"black", fontSize:"25px", fontWeight:"bold"}}>
						{item.name}
					</Typography>
					<Tooltip title={"Click to edit"}>
					<IconButton onClick={editStockItem} sx={{marginLeft:"15px"}}>
						<EditIcon sx={{color:"black", height:"24px", width:"auto"}}/>
					</IconButton>
					</Tooltip>
					</Grid>
					</Grid>
					<StockItemDetails item={item} />
					<InventoryTransactions itemId={item.id} transactions={item.transactions} item={item} />
				</Stack>
			</Box>
	);
	if(impersonate){
		return content;
	}
	return (
		<DefaultLayout>
			{content}
		</DefaultLayout>
	);
};
export default StockItemPage;
