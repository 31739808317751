// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog, DialogContent, Container, Typography, Stack, Box, Button, Grid, TablePagination, Paper, IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import { InStockHeader, InStockButton, InStockSubHeader } from "./../Components/Styled";
import { Download, PersonSearch } from "@mui/icons-material";
import SortingHelper from "./../Helpers/SortingHelper";
import DataTable from "./../Components/DataTable/DataTable";
import AdminLayout from "./../Layouts/AdminLayout";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import FreeTrialBanner from "./../Components/FreeTrialBanner/FreeTrialBanner";
import StockAlertWidget from "./../Components/StockAlertWidget/StockAlertWidget";
import NewStockWidget from "./../Components/NewStockWidget/NewStockWidget";
import TableToolsDialog from "./../Components/TableToolsDialog/TableToolsDialog";
import TakeStockDialog from "./../Components/TakeStockDialog/TakeStockDialog";
import DraftSelectionDialog from "./../Components/DraftSelectionDialog/DraftSelectionDialog";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import UserContext from "./../Context/UserContext";
import ImpersonateView from "./../Components/ImpersonateView/ImpersonateView";
const fields = [
	{ field: "business_name", headerName: "Business" },
	{ field: "user_name", headerName: "User" },
	{ field: "user_email", headerName: "Email" },
	{ field: "main_location_name", headerName: "Main Location" },
	{ field: "active_session", headerName: "Has Session" },
	{ field: "actions", headerName: "Actions" }
];
const AccountsPage = (props) => {
	// mapper
	const mapper = (e, i) => {
		const hasToken = e.token !== null && e.token !== undefined;
		const hasMerchant = hasToken && e.token.merchant !== null && e.token.merchant !== undefined;
		return {
			key: e.id,
			business_name: hasToken && hasMerchant ? e.token.merchant.business_name : "",
			user_name: e.user.full_name,
			user_email: e.user.email,
			user_id: e.user.id,
			main_location_id: hasToken && hasMerchant ? e.token.merchant.main_location.id : "",
			main_location_name: hasToken && hasMerchant ? e.token.merchant.main_location.name : "",
			main_location_address: hasToken && hasMerchant ? e.token.merchant.main_location.default_address : "",
			timezone: hasToken && hasMerchant ? e.token.merchant.main_location.timezone : "",
			active_session: hasToken ? !e.token.expired ? "Yes" : "No" : false,	
			actions: <Tooltip title={"Impersonate User"}><IconButton size={"large"} onClick={(event)=>{doImpersonate(event, e);}}><PersonSearch sx={{color:"black", height:"30px", width:"auto"}}/></IconButton></Tooltip>
		};
	};
	// context
	const navigate = useNavigate();
	const context = useContext(UserContext);
	// state
	const [accounts, setAccounts] = useState(null);
	const [selectedHeader, setSelectedHeader] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [filterTerm, setFilterTerm] = useState("");
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState(null); // sorting state, clicking table headers manipulates this
	const loading = accounts === null;
	const [download, setDownload] = useState(false);
	const [fileToDownload, setFileToDownload] = useState("");
	const [dialog, setDialog] = useState(null);
	// loading spinner dialog
	const LoadingDialog = (
		<Dialog open={true} maxWidth={"sm"} fullWidth onClose={()=>{setDialog(null);}}>
			<DialogContent sx={{backgroundColor:"white"}}>
				<LoadingSpinner message={"Loading ... this might take some time"} />
			</DialogContent>
		</Dialog>
	);
	// events
	const doExport = () => {
		const headers = [
			"business_name",
			"user_name",
			"user_email",
			"main_location_name",
			"main_location_address",
			"timezone",
			"active_session"
		];
		const payload = {
			"headers": headers,
			"records": accounts
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setFileToDownload(data);
			setDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	const onSearchChanged = (phrase) => {
		// clearing the search phrase is the same as clearing search results
		if(phrase === ""){
			setAccounts([...context.accounts]);
			return;
		}
		const upper = phrase.toUpperCase();
		const predicates = {
			"Business": (x, value) => { return x.business_name.toUpperCase().indexOf(value) !== -1 },
			"User": (x, value) => { return x.user_name.toUpperCase().indexOf(value) !== -1 },
			"Email": (x, value) => { return x.user_email.toUpperCase().indexOf(value) !== -1 }
		};
		const matches = context.accounts.filter((x)=>{ return predicates[selectedHeader](x, upper)});
		setAccounts(matches);
	};
	const onFilterValueChanged = (filter, value) => {
		setFilterTerm(value);
		if(filter === "" || value === "None" || value === ""){
			setAccounts([...context.accounts]);
			return;
		}
		// filters don't directly map to fields
		// need to use a function to get the value from the data object
		// add to this map to implement new filters
		const mapping = {
			"Active Session": (item, value) => { return item.active_session === value }
		};	
		const f = mapping[filter];
		const matches = context.accounts.filter((x)=>{ return f(x, value) });
		setAccounts(matches);
	};
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	const doImpersonate = async (event, selected) => {
		// if impersonate is passed to the stock items page
		// it knows to look at the context to determine the user id and location
		const hasToken = selected.token !== null && selected.token !== undefined;
		const hasMerchant = hasToken && selected.token.merchant !== null && selected.token.merchant !== undefined;
		context.impersonatedUserId = selected.user.id;
		context.impersonatedLocationId = hasMerchant ? selected.token.merchant.main_location.id : null;
		context.impersonatedAccount = selected;
		const setupPayload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/setup`,
			"method": "GET"	
		};
		setDialog(LoadingDialog);
		let setup = await context.api.doImpersonateSync(context, setupPayload);
		setup = setup.data;
		setup.squareComplete = hasToken;
		context.setupData = setup;
		context.setupComplete = setup.setupComplete;
		context.locationCount = setup.locationCount;
		// set location data
		const locationPayload = {
			"method": "GET",
			"url": `/api/users/${context.impersonatedUserId}/locations`,
			"user_id": context.impersonatedUserId	
		};
		let locations = await context.api.doImpersonateSync(context, locationPayload);
		locations = locations.data;
		context.locations = locations;
		context.selectedLocation = !hasMerchant ? undefined : locations.find((x)=>{ return x.id === selected.token.merchant.main_location.id });
		context.selectedLocation = context.selectedLocation === undefined ? null : context.selectedLocation;
		context.stockCategories = context.selectedLocation.stock_categories;
		context.stocktakingLocations = context.selectedLocation.stocktaking_locations;
		setDialog(null);
		navigate("/accounts/impersonate/stock-items");
	};
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const sorting = new SortingHelper();
		const comparators = {
			"Business": ( a, b ) => { return s.mode === "asc" ? a.business_name.localeCompare(b.business_name) : b.business_name.localeCompare(a.business_name) },
			"User": ( a, b ) => { return s.mode === "asc" ? a.user_name.localeCompare(b.user_name) : b.user_name.localeCompare(a.user_name) },
			"Email": ( a, b ) => { return  s.mode === "asc" ? a.user_email.localeCompare(b.user_email) : b.user_email.localeCompare(a.user_email) }
		};	
		const copy = [...context.accounts];
		copy.sort(comparators[headerName]);
		setAccounts(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	const onHeaderClicked = (event, headerName) => {
		const config = {
			searchable: ["Business", "User", "Email"],
			filterable: ["Active Session"]
		};
		context.menuAnchor = event.target;
		// determine if searchable or filterable
		// once either of these actions has been checked
		// set selected header causing rendering ...
		const searching = config.searchable.find((x)=>{ return x === headerName });
		if(searching !== undefined){
			context.searchingTable = true;
			context.filteringTable = false;
			setSelectedHeader(headerName);
			return;
		}
		const filtering = config.filterable.find((x)=>{ return x === headerName });
		if(filtering !== undefined){
			context.filteringTable = true;
			context.searchingTable = false;
			setSelectedHeader(headerName);
			return;
		}
		context.filteringTable = false;
		context.searchingTable = false;
		setSelectedHeader(null);
	};
	const getFilters = (users) => {
		return { 
				"Active Session": ["Yes", "No", "None"]
		};
	};
	// load data ...
	useEffect(()=>{
		if(accounts !== null){
			return;		
		}
		const s = (response) => {
			const data = response.data.map(mapper);
			context.accounts = data;
			const sort = {
				"Business": { sorted: false, mode: "none" },
				"User": { sorted: false, mode: "none" },
				"Email": { sorted: false, mode: "none" }
			};
			setSort(sort);
			setAccounts(data);
		};
		const e = (error) => {
			toast.error("Failed to get accounts from backend, please contact support.");
		};
		context.api.getAccounts(s, e);
	});
	let content = <LoadingSpinner height={"100px"} message="Loading accounts, please wait ..."/>;
	if(!loading){
		const itemsPerPage = 10; 
		const offset = page * itemsPerPage;
		let paginated = accounts.slice(offset, offset + itemsPerPage);
		const filters = getFilters(context.accounts);
		let menuAction = null;
		if(context.searchingTable){
			menuAction = (
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD"}}>
					<TextField value={searchTerm} label="Search ..." size="small" 
						onChange={(event)=>{setSearchTerm(event.target.value);}} 
						onBlur={()=>{onSearchChanged(searchTerm);}} />
				</Box>
			);
		}
		if(context.filteringTable){
			menuAction = (	
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD", width:"200px"}}>
					<TextField select 
							fullWidth
							value={filterTerm} 
							label="Filter by ..." 
							size="small" 
							onChange={(event)=>{onFilterValueChanged(selectedHeader, event.target.value);}}>
						{filters[selectedHeader].map((e,i)=>{ return <MenuItem key={e} value={e}>{e}</MenuItem> })}
					</TextField>
				</Box>
			);
		}
		content = (
				<Paper elevation={3} sx={{padding:"25px", height:"100%"}}>
				<Stack spacing={2}>
				<Grid container>
					<Grid item sm={10}>
						<Typography variant={"h5"} sx={{color:"#C12393", fontWeight:"bold", fontSize:"25px"}}>Square Accounts</Typography>
					</Grid>		
					<Grid item sm={2} sx={{textAlign:"right"}}>
						<Tooltip title={"Download as CSV"}>
							<IconButton onClick={doExport}>
								<Download sx={{color:"black", fontSize:"2rem"}} />	
							</IconButton>	
						</Tooltip>
					</Grid>
				</Grid>
				<Box sx={{backgroundColor:"inherit"}}>
					<Typography variant={"body"} sx={{color:"#5E085A", fontSize:"20px", verticalAlign:"middle"}}>{accounts.length} RESULT(S)</Typography>
				</Box>
				<Box sx={{width:"100%"}}>
					<DataTable rows={paginated} 
						columns={fields} 
						onRowClicked={()=>{}} 
						onHeaderClicked={onHeaderClicked} 
						onSortClicked={onSortClicked} 
						sort={sort} 
						filters={filters} />
					<Box sx={{float:"right"}}>
						<TablePagination component={Box} count={accounts.length} onPageChange={onPageChanged} page={page} rowsPerPage={itemsPerPage} />
					</Box>
				</Box>
				</Stack>
				{/* Search menu ...*/}
				<Menu open={selectedHeader !== null} 
						anchorEl={context.menuAnchor} 
						id="search-menu" 
						onClose={(event)=>{ context.filteringTable=false; context.searchingTable=false; setSelectedHeader(null); }}>
					{menuAction}
				</Menu>
				</Paper>
		);
	}
	return (
		<AdminLayout>
			<Dialogs 
					download={download}
					fileToDownload={fileToDownload}
					onDownloadClosed={()=>{setDownload(false);}}>
				{dialog}
				<Box sx={{padding:"15px", height:"100%"}} id={"AccountsBox"}>
					{content}
				</Box>
			</Dialogs>
		</AdminLayout>	
	);
};
export default AccountsPage;
