import React from "react";
import { Stack, Grid } from "@mui/material";
import CollapsableSection from "./../CollapsableSection/CollapsableSection";
import TermsOfServiceSettings from "./AdminSettings/TermsOfServiceSettings";
import PrivacyPolicySettings from "./AdminSettings/PrivacyPolicySettings";
import SocialMediaLinks from "./AdminSettings/SocialMediaLinks";
import SeoSettings from "./AdminSettings/SeoSettings";
import LogoNameSettings from "./AdminSettings/LogoNameSettings";
const SiteSettingsView = (props) => {
	return (
		<Grid container>
			<Grid item lg={12} sm={12}>
				<Stack spacing={1}>
					<CollapsableSection title={"SEO"}>
						<SeoSettings /> 
					</CollapsableSection>
					<CollapsableSection title={"Logo and Name"}>
						<LogoNameSettings />
					</CollapsableSection>
					<CollapsableSection title={"Social Media"}>
						<SocialMediaLinks />
					</CollapsableSection>
					<CollapsableSection title={"Terms of Service"}>
						<TermsOfServiceSettings />
					</CollapsableSection>
					<CollapsableSection title={"Privacy Policy"}>
						<PrivacyPolicySettings />
					</CollapsableSection>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default SiteSettingsView;
