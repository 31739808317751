// vim: ts=2
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography, Dialog, Paper, Grid, Stack, TextField } from "@mui/material";
import { InStockHeader, InStockSubHeader, InStockButton, ErrorText } from "./../Components/Styled";
import InStockDialog from "./../Components/InStockDialog/InStockDialog";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import UserContext from "./../Context/UserContext";
import FormHelper from "./../Helpers/FormHelper"; 
const VerifyEmailPage = (props) => {
	// for query parameters
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	// effects
	useEffect(()=>{
		if(!loading){
			return;
		}
		const query = location.search;
		const s = (response) => {
			setLoading(false);
			navigate("/?message=Email verified, you can now login.");
		};
		const e = (error) => {
			toast.error("Something has gone wrong, please try again later.");
		};
		InStockClient.getInstance(null).verifyEmail(query, s, e);
	});
	// content
	let content = (
		<Stack spacing={2}>
			<LoadingSpinner message="Verifying your email ..."/>
		</Stack>
	);
	const logo = <img src={InStockClient.getInstance(null).getPublicLogoURL()} style={{height:"1.5em", width:"auto"}} alt={"InStockLogo"} />;
	return (
		<Dialog maxWidth="sm" fullWidth open={true}>
			<InStockDialog title={logo} enableClose={false}>
				{content}
			</InStockDialog>
		</Dialog>
	);
};
export default VerifyEmailPage;
