import React from "react";
import { Box, Typography, Stack, Paper } from "@mui/material";
const StockAlertWidget = (props) => {
	const stock = props.stock;
	const low = stock.filter((x)=>{ return x.under_replenish_level });
	return (
		<Paper elevation={3}>
			<Box sx={{backgroundColor:"inherit", textAlign:"center", pt:"25px", pb:"25px"}}>
				<Stack spacing={2}>
					<Typography variant="h6" sx={{color:"#5E085A", fontWeight:"regular", fontSize:"25px"}}>Items Under Replenish Level</Typography>	
					<Typography variant="h3" sx={{color:"#C12393", fontWeight:"bold", fontSize:"55px"}}>{low.length}</Typography>
				</Stack>
			</Box>
		</Paper>
	);
};
export default StockAlertWidget;
