// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Box, TablePagination, IconButton, Paper, Grid, Button, Typography, TextField, Stack } from "@mui/material"; 
import { MoreVert } from "@mui/icons-material";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import DataTable from "./../DataTable/DataTable";
import Tag from "./../Tag/Tag";
import UserContext from "./../../Context/UserContext";
import AddButton from "./../AddButton/AddButton";
import RemoveButton from "./../RemoveButton/RemoveButton";
import StockCounter from "./../StockCounter/StockCounter";
import Dialogs from "./../../Containers/Dialogs/Dialogs";

const TakeStockDialog = (props) => {

	// context
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;

	// state
	const [stock, setStock] = useState(null);
	const [page, setPage] = useState(null);
	const [draftId, setDraftId] = useState(props.draftId);
	const [moreActions, setMoreActions] = useState(false);
	const [confirmation, setConfirmation] = useState(false);
	const hasDraft = draftId !== null;

	// event handlers
	const onRowClicked = () => {};
	const onHeaderClicked = () => {};
	const onPageChanged = (event, page) => { setPage(page); };
	const doClearFilters = () => {};

	const finaliseConfirmed = () => {
		setConfirmation(false);
		const s = (response) => {
			toast.success("Draft finalised");
			props.onDraftFinalised();
		};
		const e = (error) => {
			toast.error("Failed to finalise draft, please contact support.");
			throw error;
		};
		if(!impersonate){
			context.api.finaliseTakeStockDraft(context, s, e, draftId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts/${draftId}/finalise`,
      "method": "PUT",
			"data": "{}"
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	// draft event handlers
	const finaliseDraft = () => {
		if(!hasDraft){
			toast.info("Please save your draft first.");
			return;
		}
		setConfirmation(true);
	};

	const saveDraft = (event) => {
		// sync context and state first
		// then save draft ...
		const stock = Object.keys(context.takeStockItems).reduce((a,c)=>{ a.push(context.takeStockItems[c]); return a; }, []);
		stock.sort((l,r)=>{ return l.stock_item_id - r.stock_item_id });
		setStock(stock);
		if(hasDraft){
			// need existing ids for updating data
			// cannot use same mapping function
			const items = stock.map((e, i)=>{
				return {
					"stock_item_id": e.stock_item_id,
					"quantity": e.actual_absolute_quantity,
					"take_stock_draft_id": draftId,
					"id": e.id
				};
			}); 
			const e = (error) => { toast.error("Failed to update draft, please contact support."); };
			const s = (response) => { const data = response.data; toast.success("Draft updated"); };
			if(!impersonate){
				context.api.updateTakeStockDraft(context, s, e, draftId, items);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts/${draftId}`,
      	"method": "PUT",
				"data": JSON.stringify(items)
			};
			context.api.doImpersonate(context, s, e, payload);
			return;
		}
		const items = stock.map((e, i)=>{
			return {
				"stock_item_id": e.stock_item_id,
				"quantity": e.actual_absolute_quantity
			};
		});
		const e = (error) => { toast.error("Failed to update draft, please contact support."); };
		const s = (response) => { const data = response.data; setDraftId(data.id); toast.success("Draft created"); };
		if(!impersonate){
			context.api.createTakeStockDraft(context, s, e, items);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts`,
      "method": "POST",
			"data": JSON.stringify(items)
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	// other
	const stockMapper = (e, i) => {
		return {
			"id": e.id, // used as key for the frontend, can be either stock item id or draft item id ... 
			"stock_item_id": e.id,
			"name": e.name,
			"standard_quantity": e.standard_quantity,
			"description": e.quantity_description,
			"expected_unit_quantity": (e.available_quantity / e.standard_quantity).toFixed(2),
			"expected_absolute_quantity": e.available_quantity,
			"actual_unit_quantity": (e.available_quantity / e.standard_quantity).toFixed(2),
			"actual_absolute_quantity": e.available_quantity,
			"category": e.category.name,
			"locations_to_check": e.stocktaking_tags,
			"units": e.units
		};
	};
	useEffect(()=>{
		if(props.draftId !== null && props.draftId !== draftId){
			setStock(null);
		}
	});
	// effects
	useEffect(()=>{
		if(stock !== null){
			return;
		}	
		// check if we're loading an existing draft
		// or loading stock items to create a draft ...
		if(!hasDraft){
			const s = (response) => {
				const stock = response.data.map(stockMapper);
				// transpose stock array to be mapped via stock item id
				// this will be used by the stock counter components 
				context.takeStockItems = stock.reduce((a,c)=>{ a[`${c.stock_item_id}`] = c; return a; }, {});
        stock.sort((l,r)=>{return l.stock_item_id - r.stock_item_id});
				setStock(stock);
			};
			const e = (error) => {
				toast.error("Failed to load stock, please contact support.");
			};
			if(!impersonate){
				context.api.getStockItems(context, s, e);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      	"method": "GET"
			};
			context.api.doImpersonate(context, s, e, payload);
			return;
		}
		const s = (response) => {
			const draft = response.data;
			const stock = draft.items.map((e,i)=>{
				return {
					"id": e.id,
					"stock_item_id": e.stock_item.id,
					"name": e.stock_item.name,
					"standard_quantity": e.stock_item.standard_quantity,
					"description": e.stock_item.description,
					"expected_unit_quantity": (e.stock_item.available_quantity / e.stock_item.standard_quantity).toFixed(2),
					"expected_absolute_quantity": e.stock_item.available_quantity,
					"actual_unit_quantity": (e.quantity / e.stock_item.standard_quantity).toFixed(2),
					"actual_absolute_quantity": e.quantity,
					"category": e.stock_item.category.name,
					"locations_to_check": e.stock_item.stocktaking_tags,
					"units": e.stock_item.units
				};
			});
			context.takeStockItems = stock.reduce((a,c)=>{ a[`${c.stock_item_id}`] = c; return a; }, {});
			stock.sort((l,r)=>{return l.stock_item_id - r.stock_item_id});
			setStock(stock);
		};
		const e = (error) => { 
			toast.error("Failed to get existing draft, please contact support.");
		};
		if(!impersonate){
			context.api.getTakeStockDraft(context, s, e, draftId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts/${draftId}`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	let content = <LoadingSpinner message={"Loading stock ..."} />;
	// if stock has been loaded
	// replace content with table of stock items
	// else show loading spinner
	if(stock !== null){
		const itemsPerPage = 8; 
		const offset = page * itemsPerPage;
		let paginated = stock.slice(offset, offset + itemsPerPage);
		const getName = (params) => {
			return (
				<Box>
					<Typography sx={{color:"black", fontSize:"16px", fontWeight:"regular"}}>{params.row.name}</Typography>			
					<Typography sx={{color:"black", fontSize:"14px", fontWeight:"light"}}>{params.row.description}</Typography>
				</Box>
			);		
		};
		const getLocationsToCheck = (params) => {
			return params.row.locations_to_check.map((e,i)=>{ return <Tag key={`location_${i}`} value={e}/>});
		};
		const getQuantityCounted = (params) => {
			return (
				<StockCounter 
					id={params.row.stock_item_id}
					standardQuantity={params.row.standard_quantity} 
					absoluteQuantity={Math.floor(params.row.actual_absolute_quantity)} 
					unitQuantity={params.row.actual_unit_quantity} 
					units={params.row.units} />
			);
		};
		const getExpectedQuantity = (params) => {
			return (
				<Stack>
					<Typography sx={{fontSize:"16px", fontWeight:"regular"}}>{`${params.row.expected_absolute_quantity} ${params.row.units}`}</Typography>			
					<Typography sx={{fontSize:"16px", fontWeight:"regular"}}>{`${params.row.expected_unit_quantity} unit(s)`}</Typography>			
				</Stack>
			);	
		};
		const sort = {};
		const headerStyles = {
			"Quantity Counted": { textAlign: "center" }
		};
		const fields = [
			{ field: "name", headerName: "Stock Item", valueGetter: getName, width: 200 },
			{ field: "locations_to_check", headerName:"Locations To Check", valueGetter: getLocationsToCheck, width: 150 },
			{ field: "actual_quantity", headerName:"Quantity Counted", width: 200, valueGetter: getQuantityCounted },
			{ field: "expected_quantity", headerName:"Expected", width: 200, valueGetter: getExpectedQuantity },
		];
		content = (
			<Paper elevation={3} sx={{padding:"25px"}}>
				<Grid container>
					<Grid item sm={12}>
						<Box sx={{maxHeight:"500px", overflowY:"scroll"}}>
							<DataTable headerStyles={headerStyles} 
								rows={paginated} 
								columns={fields} 
								onRowClicked={onRowClicked} 
								onHeaderClicked={onHeaderClicked} 
								sort={sort} />
						</Box>
					</Grid>
					<Grid item sm={12}>
						<Box sx={{marginTop:"15px"}}>
							<Grid container>
								<Grid item lg={7} sm={12} />
								<Grid item lg={5} sm={12} sx={{textAlign:"right"}}>
									<TablePagination component={Box} count={stock.length} onPageChange={onPageChanged} page={page} rowsPerPage={itemsPerPage}/>
									<Box sx={{marginTop:"15px"}}>
										<Grid container>
											<Grid item lg={6} sm={12}>
												<Box sx={{padding:"5px"}}>
													<Button variant="outlined" size="large" fullWidth sx={{color:"black", backgroundColor:"white", border:"1px solid #E6E6E6"}} onClick={saveDraft}>Save Draft</Button>
												</Box>
											</Grid>
											<Grid item lg={6} sm={12}>
												<Box sx={{padding:"5px"}}>
													<Button variant="contained" size="large" fullWidth className="pg" onClick={finaliseDraft}>Finalise</Button>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		);
	}
	return (
		<InStockDialog onCloseClicked={props.onCloseClicked} title={"Take Stock"}>
			<Dialogs 
					impersonate={impersonate}
					confirmAction={confirmation} 
					confirmationMessage={"Are you sure that you want to finalise this take stock draft?"} 
					onConfirmationClosed={()=>{setConfirmation(false);}} 
					onConfirmationAccepted={finaliseConfirmed} 
					onConfirmationRejected={()=>{setConfirmation(false);}}>
				{content}
			</Dialogs>
		</InStockDialog>
	);
};
export default React.memo(TakeStockDialog);
