// vim: ts=2
import React, { useState, useContext, useNavigation, useEffect } from "react";
import { Box, Grid, Typography, Stack, Paper, Dialog } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import UserContext from "./../../Context/UserContext";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import ManageModifierDialog from "./../ManageModifierDialog/ManageModifierDialog";
const Modifier = (props) => {	
	const data = props.data;
	const debug = props.debug;
	const [colour, setColour] = useState(data.configured ? "#83f28f" : "white");
	const onMouseEnter = () => {
		setColour("#EFEFEF");	
	};	
	const onMouseLeave = () => {
		setColour(data.configured ? "#83f28f" : "white");
	};
	let content = (
		<Stack>
			<Typography sx={{fontSize:"18px", color:"black", fontWeight:"bold"}}>{data.list}</Typography>
			<Typography sx={{fontSize:"20px", color:"black"}}>{data.name}</Typography>
			{debug ? <Typography sx={{fontSize:"20px", color:"black"}}>{data.id}</Typography> : null}
		</Stack>
	);
	const style = {
		paddingTop: "15px",
		paddingBottom: "15px", 
		margin: "5px 5px", 
		border: "1px solid #CDCDCD", 
		borderRadius: "5px", 
		backgroundColor: colour, 
		cursor: "pointer"
	};
	return (
		<Grid item xs={12} lg={3}>
			<Box 
				onMouseEnter={onMouseEnter} 
				onMouseLeave={onMouseLeave} 
				onClick={(event)=>{props.manageModifier(event, data.id)}} 
				sx={style}>
					{content}
			</Box>
		</Grid>
	);
};
const RecipeModifiers = (props) => {
	// state
	const debug = props.debug === undefined ? false : props.debug;
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const context = useContext(UserContext);
	const catalogItemId = props.catalogItemId;
	const recipe = props.recipe;
	const [modifiers, setModifiers] = useState(null);
	const [showDialog, setShowDialog] = useState(false);
	const [recipeModifiers, setRecipeModifiers] = useState([]);
	const [modifierId, setModifierId] = useState(null);
	// effects
 	useEffect(()=>{
		if(modifiers !== null){
			return;
		}	
		const s = (response) => {
			const data = response.data;
			const reducer = (acc, list) => {
				const m = list.modifiers.map((e,i)=>{ return {id: e.id, name:e.name, list:list.name, configured: e.configured}});
				return [...acc, ...m];
			};
			const modifiers = data.reduce(reducer, []);
			setModifiers(modifiers);
		};
		const e = (error) => {
			toast.error("Failed to load modifier lists.");
		};
		if(!impersonate){
			context.api.getModifierLists(context, s, e, catalogItemId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/catalog/items/${catalogItemId}/modifier-lists`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	// event handlers
	const manageModifier = (event, squareId) => {
		setModifierId(squareId);
		setShowDialog(true);
	};
	let content = (
		<Grid container>
			<Grid item xs={12}>
				<LoadingSpinner />
			</Grid>
		</Grid>
	);
	if(modifiers !== null){
		content = (
			<Typography sx={{fontSize:"20px"}}>This item isn't linked to any modifiers.</Typography>	
		);
		if(modifiers.length > 0){
			content = (
				<Grid container>
					{modifiers.map((e,i)=>{ return <Modifier key={e.id} data={e} debug={debug} manageModifier={manageModifier}/>})}
				</Grid>	
			);
		}
	}
	return (
		<Box sx={{textAlign:"center", mt:"15px"}}>
			<Dialog open={showDialog} onClose={(event)=>{setShowDialog(false);}}>
				<ManageModifierDialog impersonate={impersonate} 
					debug={debug} 
					recipeId={recipe.id} 
					modifierId={modifierId} 
					onCloseClicked={(event)=>{setShowDialog(false);}} />
			</Dialog>
			<Paper elevation={2} sx={{padding:"25px"}}>
				{content}
			</Paper>
		</Box>	
	);
};
export default RecipeModifiers;
