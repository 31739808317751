import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid, Box, Typography, Container, Paper, Stack, TextField, Button, InputAdornment, IconButton, useMediaQuery } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InStockLogo from "./../Assets/Images/instock.png";
import InStockClient from "./../Api/InStockClient";
import FormHelper from "./../Helpers/FormHelper";
const validator = require("validate.js");
const RegisterPage = (props) => {
	const navigate = useNavigate();
	const forms = new FormHelper();
	// state
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const tablet = useMediaQuery("(max-width: 1024px)");
	// define validation rules ...
	const rules = {
		"email": { email: true, presence: { allowEmpty: false }, length: { maximum: 255 } },
		"firstName": { presence: { allowEmpty: false }, length: { maximum: 255 } },
		"lastName": { presence: { allowEmpty: false }, length: { maximum: 255 } },
		"password": { length: { minimum: 8 } },
		"passwordConfirmation": { equality: "password" }
	};
	const validateSingle = (field, value) => {
		let data = { };
		data[field] = value;
		if(field === "passwordConfirmation"){
			data["password"] = password;
		}
		let constraints = {};
		constraints[field] = rules[field];
		const copy = {...errors};
		delete copy[field];
		setErrors(copy);
		let e = validator.validate(data, constraints);
		if(e !== undefined){
			if(field === "password"){
				if(!forms.isPasswordValid(value)){
					e["password"].push("Password must contain at least one lower case and upper case character and one digit.");
				}
			}
			setErrors({...e, ...copy});
			return;
		}	
		if(field === "password"){
			if(!forms.isPasswordValid(value)){
				e = {"password" : ["Password must contain at least one lower case and upper case character and one digit."]};
				setErrors(e);
			}
		}
	};
	// events
	const doSubmit = () => {
		setErrors({});	
		const data = {
			"email": email,
			"firstName": firstName,
			"lastName": lastName,
			"password": password,
			"passwordConfirmation": passwordConfirmation,
			"roleName": "Customer"
		};
		const errors = validator.validate(data, rules);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		if(!forms.isPasswordValid(data.password)){
			const passwordErrors = {
				"password": ["Password must contain at least one lower case and upper case character and one digit."]	
			};	
			setErrors(errors);
			return;
		}
		const s = (response) => {
			navigate(`/?message=Registration successful. Please check your imbox for your activation email.`);
		};
		const e = (error) => {
			if(error.status === 400){
				const msg = error.response.data;
				toast.warn(msg.message);	
				setErrors({email:[msg.message]});
				return;
			}
			toast.error("Failed to register user, please contact support.");
		};
		const api = InStockClient.getInstance(null);
		const payload = {
			"email": data.email,
			"first_name": data.firstName,
			"last_name": data.lastName,
			"password": data.password,
			"password_confirmation": data.passwordConfirmation,
			"role_name": data.roleName
		};
		api.addUser(s, e, payload);
	};
	const onEmailChanged = (event) => {
		setEmail(event.target.value);
	};
	const onPasswordChanged = (event) => {
		setPassword(event.target.value);
	};
	const onPasswordConfirmationChanged = (event) => {
		setPasswordConfirmation(event.target.value);
	};
	const onFirstNameChanged = (event) => {
		setFirstName(event.target.value);
	};
	const onLastNameChanged = (event) => {
		setLastName(event.target.value);
	};
	// helpers
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{ return <span key={`${field}_error_${i}`} style={{display:"block"}}>{e}</span>});
		}
		const helper = {
			"email": "Please enter your email address.",
			"firstName": "Please enter your first name.",
			"lastName": "Please enter your last name.",
			"password": "Password must contain a lower case and upper case letter and one digit.",
			"passwordConfirmation": "Please enter your password again."
		};
		return helper[field];
	};
	const getHasError = (field) => {
		return errors[field] !== undefined;
	};
	return (
		<Grid container>
			<Grid item xs={12} lg={7} sx={{backgroundColor:"#EFEFEF"}}>
				<Container fullWidth maxWidth={"sm"}>
					<Paper elevation={3} sx={{padding:"25px", mt:"100px", mb:"100px"}}>
						<Stack spacing={2}>
							<Typography variant={"h4"} sx={{color:"black", fontWeight:"bold", textAlign:"center"}}>
								Sign Up
							</Typography>
							<TextField value={email} 
								onChange={onEmailChanged} 
								label={"Email"} 
								name={"Email"}
								onBlur={()=>{validateSingle("email", email);}}
								helperText={getHelperText("email")} 
								error={getHasError("email")} 
								fullWidth size={"large"} />	
							<TextField value={firstName} 
								onChange={onFirstNameChanged} 
								label={"First Name"} 
								name={"First Name"}
								onBlur={()=>{validateSingle("firstName", firstName);}}
								helperText={getHelperText("firstName")} 
								error={getHasError("firstName")} fullWidth 
								size={"large"} />
							<TextField value={lastName} 
								onChange={onLastNameChanged} 
								label={"Last Name"} 
								name="Last Name"
								onBlur={()=>{validateSingle("lastName", lastName);}}
								helperText={getHelperText("lastName")} 
								error={getHasError("lastName")} fullWidth 
								size={"large"} />	
							<TextField value={password} 
								onChange={onPasswordChanged} 
								type={showPassword ? "text" : "password"} 
								label={"Password"} 
								name="Password"
								onBlur={()=>{validateSingle("password", password);}}
								helperText={getHelperText("password")} 
								error={getHasError("password")} 
								InputProps={{endAdornment:<InputAdornment position={"end"}><IconButton onClick={()=>{setShowPassword(!showPassword);}}>{showPassword?<VisibilityOff/>:<Visibility/>}</IconButton></InputAdornment>}}
								fullWidth 
								size={"large"} />	
							<TextField value={passwordConfirmation} 
								type={"password"} 
								onChange={onPasswordConfirmationChanged} 
								label={"Confirm Password"} 
								name="Confirm Password"
								onBlur={()=>{validateSingle("passwordConfirmation", passwordConfirmation);}}
								helperText={getHelperText("passwordConfirmation")} 
								error={getHasError("passwordConfirmation")} fullWidth 
								size={"large"} />	
							<Button variant={"contained"} color={"primary"} fullWidth size={"large"} onClick={doSubmit} type={"submit"}>
								Submit
							</Button>
						</Stack>	
					</Paper>
				</Container>
			</Grid>			
			{!tablet ? <Grid item xs={12} lg={5} sx={{backgroundColor:"#1976d2", minHeight:window.innerHeight}}>
				<Box sx={{width:"100%", textAlign:"center", mt:"40%"}}>
						<img src={InStockClient.getInstance(null).getPublicLogoURL()} alt={"InStock Logo"} style={{width:"400px", height:"auto"}}/>
						<Typography variant="h3" sx={{fontWeight:"bold", color:"white", verticalAlign:"middle", mt:"50px"}}>
							Sign up today for a free trial.
						</Typography>
				</Box>
			</Grid>: null}
		</Grid>
	);
};
export default RegisterPage;
