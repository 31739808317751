import React, { useContext, useState, useRef }  from "react";
import { IconButton, Box, Typography, Menu, MenuItem, useMediaQuery } from "@mui/material";
import UserContext from "./../../Context/UserContext";
import { KeyboardArrowDown, KeyboardArrowUp  } from "@mui/icons-material";
const PortalSelect = (props) => {
	const context = useContext(UserContext);
	const anchorRef = useRef(null);
	const [selected, setSelected] = useState(props.selected);
	const [options, setOptions] = useState(props.options);
	const [open, setOpen] = useState(false);
	const tablet = useMediaQuery("(max-width: 1024px)");
	let icon = <KeyboardArrowDown sx={{color:"#C12393", fontSize:"28px"}}/>;
	if(open){
		icon = <KeyboardArrowUp sx={{color:"#C12393", fontSize:"28px"}}/>;
	}
	const onSelectionChanged = (id) => {
		const s = options.find((x)=>{ return x.value === id});
		if(s === undefined){
			throw "Failed to find selection";
		}
		setSelected(s.value);
		setOpen(false);
		props.onChange(s.value);
	};
	const items = options.map((e,i)=>{ return <MenuItem key={e.key} onClick={(event)=>{onSelectionChanged(e.value);}}>{e.text}</MenuItem>});
	const value = options.find((e)=>{ return e.value === selected });
	let valueText = value.text;
	if(tablet && valueText.length > 5){
		valueText = `${valueText.substr(0, 5)}...`;
	}
	let anchor = null;
	if(open){
		anchor = anchorRef.current;
	}
	return (
		<Box ref={anchorRef} sx={{backgroundColor:"white", height:"67px", borderRadius:"33px 33px 33px 33px", textAlign:"center"}}>
			<Menu id="portal-menu" 
					open={open}	
					onClose={(event)=>{setOpen(false);}} 
					anchorOrigin={{vertical:"bottom", horizontal:"center"}}
					anchorReference="anchorPosition"
					anchorPosition={{top:anchor !== null ? anchor.offsetTop + anchor.offsetHeight : "0px", left: anchor !== null ? anchor.offsetLeft: "0px"}}>
				{items}		
			</Menu>
			<Box sx={{display:"inline", paddingLeft:tablet?"0px":"15px"}}>
				<span style={{verticalAlign:"middle", color:"#464646", fontSize:"16px"}}>{valueText}
					<IconButton sx={{height:"67px", ml:tablet?"0px":"15px"}} onClick={(event)=>{setOpen(!open);}}>
						{icon}
					</IconButton>
				</span>
			</Box>
		</Box>	
	);
};
export default PortalSelect;
