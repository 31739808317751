import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import InStockDialog from "./../InStockDialog/InStockDialog";
import { Grid, Box, Stack, Paper } from "@mui/material";
import { AccountCircle, Key, CreditCard } from "@mui/icons-material";
import UserContext from "./../../Context/UserContext";
import UserProfileForm from "./../UserProfileForm/UserProfileForm";
import UserCredentials from "./../UserCredentials/UserCredentials";
import UserSubscriptions from "./../UserSubscriptions/UserSubscriptions";
const UserProfileDialog = (props) => {
	// context
	const context = useContext(UserContext);
	// state
	const [selected, setSelected] = useState("profile");
	// event handling
	const getClassName = (view) => {
		return view === selected ? "InStockLink InStockLinkSelected" : "InStockLink";	
	};
	// switch out content based off selected view
	let content = null;
	if(selected === "profile"){
		// user profile form allows changing of first name, last name and profile picture.
		content = (
			<UserProfileForm user={context.user} />	
		);
	}
	if(selected === "credentials"){
		// credentials form changes email and password
		// changing email requires validation (activtion) again ...
		content = (
			<UserCredentials user={context.user} />
		);
	}
	if(selected === "subscriptions"){
		// subscriptions data
		// mostly listing subscriptions, show subscription details
		// next payment ... etc ...
		content = (
			<UserSubscriptions user={context.user} />
		);
	}
	let subscriptions = (
		<Link className={getClassName("subscriptions")} to={"#"} onClick={()=>{setSelected("subscriptions");}}>
			<span><CreditCard sx={{verticalAlign:"middle", fontSize:"2rem", mr:"10px"}}/>Subscriptions</span>
		</Link>
	);
	if(context.admin){
		subscriptions = null;
	}
	return (
		<InStockDialog title="User Settings" onCloseClicked={props.onCloseClicked} padding={{padding:"0px"}}>
			<Grid container>
				<Grid item md={3} className="pg">
					<Box sx={{padding:"15px"}}>
						<Box sx={{backgroundColor:"rgba(255,255,255,0.1)", padding:"15px"}}>
							<Stack spacing={2}>
								<Link className={getClassName("profile")} to={"#"} onClick={()=>{setSelected("profile");}}>
									<span><AccountCircle sx={{verticalAlign:"middle", fontSize:"2rem", mr:"10px"}}/>Profile</span>
								</Link>
								<Link className={getClassName("credentials")} to={"#"} onClick={()=>{setSelected("credentials");}}>
									<span><Key sx={{verticalAlign:"middle", fontSize:"2rem", mr:"10px"}}/>Credentials</span>
								</Link>
								{subscriptions}
							</Stack>
						</Box>
					</Box>
				</Grid>		
				<Grid item md={9}>
					<Box sx={{padding:"25px"}}>
						<Paper elevation={3} sx={{padding:"25px"}}>
							{content}
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</InStockDialog>
	);
};
export default UserProfileDialog;
