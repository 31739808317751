import React, { useState, useContext, useEffect } from "react";
import { Box, Grid, Stack, Typography, Container } from "@mui/material";
import { InStockHeader } from "./../Styled";
const UserSubscriptions = (props) => {
	const user = props.user;
	return (
			<Grid container>
				<Grid item sm={12} sx={{textAlign:"center"}}>
					<InStockHeader>The beta version of instock is free and no subscription is required.</InStockHeader>
				</Grid>
			</Grid>	
	);
};
export default UserSubscriptions;
