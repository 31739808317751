import React, { useState, useContext, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import StockCategories from "./StockCategories/StockCategories";
import StocktakingLocations from "./StocktakingLocations/StocktakingLocations";
const StockView = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	return (
		<Container maxWidth="lg" className="instock-view">
			<Grid container>
				<Grid xs={6} sx={{paddingRight:"15px"}}>
					<StockCategories impersonate={impersonate} />		
				</Grid>		
				<Grid xs={6} sx={{paddingLeft:"15px"}}>
					<StocktakingLocations impersonate={impersonate} />
				</Grid>		
			</Grid>
		</Container>	
	);
}
export default StockView;
