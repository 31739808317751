import React, {useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import UserContext from "./../../Context/UserContext";
import { TextField, Grid, Box, Stack, Typography, Container, Button, InputAdornment, IconButton } from "@mui/material";
import { Visibility, CloudUpload, AccountCircle } from "@mui/icons-material";
const validator = require("validate.js");
const UserProfileForm = (props) => {
	// context
	const context = useContext(UserContext);
	// props
	const user = props.user;
	// state
	const [errors, setErrors] = useState({});
	const [firstName, setFirstName] = useState(user.first_name);
	const [lastName, setLastName] = useState(user.last_name);
	const [profilePicture, setProfilePicture] = useState(user.profile_picture_data_uri);
	const [fileName, setFileName] = useState(""); // uploaded file name ...
	const [fileType, setFileType] = useState(user.profile_picture_mime_type); // uploaded file name ...
	// events
	const getHasError = (field) => {
		return errors[field] !== undefined;
	};
	const getHelperText = (field) => {
		const helper = {
			"firstName": "Please enter your first name",
			"lastName": "Please enter your last name"
		};
		if(getHasError(field)){
			return errors[field].join(", ");
		}
		return helper[field];
	}
	const onFirstNameChanged = (event) => {
		const value = event.target.value;
		const constraints = {"firstName":{presence:{allowEmpty:false},length:{maximum:255}}};
		const data = {"firstName":value};
		let e = errors;
		delete e["firstName"];
		setErrors(e);
		setFirstName(value);
		e = validator.validate(data, constraints);
		if(e !== undefined){
			setErrors({...e, ...errors});
			return;
		}
	};
	const onLastNameChanged = (event) => {
		const value = event.target.value;
		const constraints = {"lastName":{presence:{allowEmpty:false},length:{maximum:255}}};
		const data = {"lastName":value};
		let e = errors;
		delete e["lastName"];
		setErrors(e);
		setLastName(value);
		e = validator.validate(data, constraints);
		if(e !== undefined){
			setErrors({...e, ...errors});
			return;
		}
	};
	const onSaveClicked = (event) => {
		const payload = {
			"first_name": firstName,
			"last_name": lastName,
			"profile_picture": profilePicture,
			"profile_picture_mime_type": fileType
		};
		const constraints = {
			"firstName": { presence: { allowEmpty: false }, length: { maximum: 255 } }, 
			"lastName": { presence: { allowEmpty: false }, length: { maximum: 255 } }
		};
		const s = (response) => {
			const user = response.data;
			context.user = user;
			toast.success("User updated");
		};
		const e = (error) => {
			toast.error("Failed to update user, please contact support.");
		};
		context.api.updateUser(context, s, e, payload);
	};
	const onUploadClicked = () => {
		const upload = document.getElementById("upload");	
		upload.onchange = (event) => {
			const f = event.target.files[0];
			if(f === undefined){
				return;
			}
			const LIMIT = 1024 * 1024; // 1 MB
			if(f.size >= LIMIT){
				toast.info("Your image is too big, please select a smaller image.");
				return;
			}
			const name = f.name;
			const type = f.type; 
			const valid = [
				"image/jpg",
				"image/jpeg",
				"image/png"
			];
			const exists = valid.find((x)=>{ return x === type });
			if(exists === undefined){
				toast.info("Only images are allowed.");
				return;
			}		
			const reader = new FileReader();
			reader.onload = (event) => {
				toast.success("File read");
				let data = event.target.result;
				data = btoa(data);
				setFileName(name);
				setFileType(type);
				setProfilePicture(data);
			};
			reader.readAsBinaryString(f);	
		};
		upload.click();
	};
	let profile = <AccountCircle sx={{fontSize:"5rem"}} />;
	if(profilePicture !== undefined){
		const profileStyle = {
			borderRadius: "50%",
			border: "2px solid #E6E6E6",
			width: "100px",
			height: "auto",
			marginBottom: "15px"
		};
		profile = <img src={user.profile_picture_data_uri} alt="profile picture" style={profileStyle} />;	
	}
	return (
		<Container fullWidth maxWidth={"sm"}>
		<Grid container>
			<Grid item sm={12} sx={{textAlign:"center"}}>
				{profile}
			</Grid>
			<Grid item sm={12}>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<TextField value={firstName} 
							error={getHasError("firstName")}
							size={"large"} 
							fullWidth 
							onChange={onFirstNameChanged} 
							label={"First Name"}
							helperText={getHelperText("firstName")}
							/>			
					</Grid>	
					<Grid item sm={12}>
						<TextField value={lastName} 
							error={getHasError("lastName")}
							size={"large"} 
							fullWidth 
							onChange={onLastNameChanged} 
							label={"Last Name"} 
							helperText={getHelperText("lastName")}
							/>			
					</Grid>	
					<Grid item sm={12}>
						<Grid container>
							<Grid item sm={12}>
								<TextField value={fileName} 
									disabled 
									fullWidth 
									size={"large"} 
									label={"Profile Picture"} 
									helperText={"Please click on the cloud to upload an image (png and jpeg file types only)"} 
									InputProps={{endAdornment:<InputAdornment position={"end"}><IconButton onClick={onUploadClicked}><CloudUpload sx={{color:"black"}}/></IconButton></InputAdornment> }}/>
								<input style={{display:"none"}} type="file" id="upload" name="upload" />
							</Grid>
						</Grid>
					</Grid>
					<Grid item sm={12}>
						<Grid container>
						<Grid item xs={4}/>
						<Grid item xs={4}>
						<Button className="pg" size="large" fullWidth onClick={onSaveClicked} sx={{color:"white"}}>Save</Button>
						</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		</Container>
	);
};
export default UserProfileForm;
