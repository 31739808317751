import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography, Dialog, Paper, Grid, Stack } from "@mui/material";
import { InStockHeader, InStockSubHeader } from "./../Components/Styled";
import InStockDialog from "./../Components/InStockDialog/InStockDialog";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import UserContext from "./../Context/UserContext";
const SquareAuthorizationPage = (props) => {
	const location = useLocation();
	// context
	const context = useContext(UserContext);
	// state
	const [authorized, setAuthorized] = useState(false);
	// effects
	useEffect(()=>{
		if(authorized){
			return;
		}
		const s = (response) => {
			setAuthorized(true);
		};
		const e = (error) => {
			toast.error("Seems like somethings gone wrong.");
		};
		InStockClient.getInstance(null).completeAuthorization(s, e, location.search);
	});
	const logo = <img src={InStockClient.getInstance(null).getPublicLogoURL()} style={{height:"50px", width:"auto"}} alt={"InStockLogo"} />;
	let content = <LoadingSpinner />;
	if(authorized){
		content = (
			<Stack spacing={2}>
				<InStockHeader sx={{textAlign:"center"}}>Authorization Complete</InStockHeader>			
				<InStockSubHeader sx={{textAlign:"center"}}>Thank you for connecting your square account to InStock.</InStockSubHeader>
				<InStockSubHeader sx={{textAlign:"center"}}>You may close this window at any time.</InStockSubHeader>
			</Stack>
		);
	}
	return (
		<Dialog maxWidth="md" fullWidth open={true}>
			<InStockDialog title={logo} enableClose={false}>
				{content}
			</InStockDialog>
		</Dialog>
	);
};
export default SquareAuthorizationPage;
