import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Grid, Stack, Typography, Container, TextField, Button } from "@mui/material";
import UserContext from "./../../Context/UserContext";
import FormHelper from "./../../Helpers/FormHelper";
const validator = require("validate.js");
const PASSWORD_NOT_VALID = "Password must be at least 8 characters long and contain at least one upper and lower case character and one digit.";
const UserCredentials = (props) => {
	// props
	const user = props.user;
	// context
	const context = useContext(UserContext);
	const navigate = useNavigate();
	// state
	const [email, setEmail] = useState(user.email);
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [verified, setVerified] = useState(false);
	const [errors, setErrors] = useState({});
	// events
	const doUpdateCredentials = (event) => {
		const payload = {
			"password": password,
			"password_confirmation": passwordConfirmation,
			"email": email
		};
		const constraints = { 
			password: { presence: { allowEmpty: false }, }, 
			password_confirmation: { presence: { allowEmpty: false }, equality: "password" }  
		};
		const err = validator.validate(payload, constraints);
		if(err !== undefined){	
			toast.error("Please fix the errors before submitting.");
			setErrors({...e, ...err});
			return;
		}
		const helper = new FormHelper();
		if(!helper.isPasswordValid(payload.password)){
			setErrors({"password":[PASSWORD_NOT_VALID]});
			return;
		}
		const s = (response) => {
			toast.success("Credentials updated.");
			setVerified(false);
			setPassword("");
			setEmail("");
			setPasswordConfirmation("");
			// logout the user
			context.userId = null;
			context.token = null;
			context.locations = null;
			context.freeTrialAlert = false;
			navigate("/?message=You've been logged out.");
		};
		const e = (error) => {
			toast.error("Failed to update credentials, please contact support.");
		};
		context.api.updateCredentials(context, s, e, payload);
	};
	const onPasswordConfirmationChanged = (event) => {
		const value = event.target.value;
		setPasswordConfirmation(value);
		let e = errors;
		delete e["passwordConfirmation"];
		setErrors(e);
		const data = { password: password, passwordConfirmation: value };
		const constraints = { password: {presence:{allowEmpty:false}, }, passwordConfirmation: { presence: { allowEmpty: false }, equality: "password" } };
		e = validator.validate(data, constraints);
		if(e !== undefined){	
			setErrors({...e, ...errors});
			return;
		}
	};
	const verifyPassword = () => {
		if(errors["password"] !== undefined){
			toast.info("Please fix the errors highlighted in red before submitting.");
			return;
		}	
		const payload = { password: password };
		const s = (response) => {
			toast.success("Password verified");
			setVerified(true);
			setPassword("");
		};
		const e = (error) => {
			toast.error("Failed to verify password, please try again.");
		};
		context.api.verifyPassword(context, s, e, payload);
	};
	const onPasswordChanged = (event) => {
		const value = event.target.value;
		setPassword(value);
		let e = errors;
		delete e["password"];
		setErrors(e);
		const data = { password: value };
		const constraints = { password: { presence: { allowEmpty: false }, length: { minimum: 8 } } };
		e = validator.validate(data, constraints);
		if(e !== undefined){	
			setErrors({...e, ...errors});
			return;
		}
		const helper = new FormHelper();
		if(!helper.isPasswordValid(value)){
			setErrors({"password":[PASSWORD_NOT_VALID]});
		}
	};
	const onEmailChanged = (event) => {
		const value = event.target.value;
		setEmail(value);
		let e = errors;
		delete e["email"];
		setErrors(e);
		const data = { email: value };
		const constraints = { email: { presence: { allowEmpty: false }, email: true, length: { maximum: 255 } } };
		e = validator.validate(data, constraints);
		if(e !== undefined){	
			setErrors({...e, ...errors});
			return;
		}
	};
	const getHasError = (field) => {
		return errors[field] !== undefined;
	};
	const getHelperText = (field) => {
		const helper = {
			"password": "Please enter your password.",
			"email": "Please enter an email address.",
			"passwordConfirmation": "Please confirm your password."
		};
		if(getHasError(field)){
			return errors[field].join(", ");
		}
		return helper[field];
	};
	//rendering
	let content = (
		<Grid container>
			<Grid item sm={12}>
				<Stack spacing={2}>
				<Box sx={{backgroundColor:"#E6E6E6", border:"1px solid #CDCDCD", padding:"15px", borderRadius:"15px", textAlign:"center"}}>
					<Typography  sx={{color:"black", fontSize:"16px"}}>In order to keep your account secure, we need to verify your password before your credentials can be changed.</Typography>	
				</Box>		
				<TextField 
					type={"password"}
					label={"Password"}
					value={password}
					onChange={onPasswordChanged}
					helperText={getHelperText("password")}
					error={getHasError("password")}
					fullWidth
					size={"large"} />		
					<Grid container>
						<Grid item lg={4} sm={4}/>
						<Grid item lg={4} sm={4}>
							<Button className="pg" sx={{color:"white"}} fullWidth size={"large"} onClick={verifyPassword}>Submit</Button>
						</Grid>
					</Grid>
				</Stack>
			</Grid>			
		</Grid>
	);
	// once password has been verified show email and other password fields
	if(verified){
		content = (
			<Grid container>
				<Grid item sm={12}>
					<Stack spacing={2}>
						<Box sx={{backgroundColor:"#E6E6E6", border:"1px solid #CDCDCD", padding:"15px", borderRadius:"15px", textAlign:"center"}}>
							<Typography  sx={{color:"black", fontSize:"16px"}}>
								Please complete the following form to change your login credentials.<br/>
								If you change your email address you'll need to verify it again before logging in.<br/>
								Upon submitting of this form you'll be <b>logged out</b> of the application.
							</Typography>	
						</Box>		
						<TextField 
							value={email}
							label={"Email"}
					  	onChange={onEmailChanged}
							disabled
							fullWidth
							size={"large"} />
						<TextField 
							type={"password"}
							label={"New Password"}
							value={password}
							onChange={onPasswordChanged}
							helperText={getHelperText("password")}
							error={getHasError("password")}
							fullWidth
							size={"large"} />		
						<TextField 
							type={"password"}
							label={"Confirm New Password"}
							value={passwordConfirmation}
							onChange={onPasswordConfirmationChanged}
							helperText={getHelperText("passwordConfirmation")}
							error={getHasError("passwordConfirmation")}
							fullWidth
							size={"large"} />		
						<Grid container>
							<Grid item sm={12}>
								<Grid container>
									<Grid item xs={4} />
									<Grid item xs={4}> 
										<Button className={"pg"} size={"large"} fullWidth sx={{color:"white"}} onClick={doUpdateCredentials}>Submit</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Stack>
				</Grid>	
			</Grid>			
		);
	}
	return (
		<Container maxWidth={"small"} fullWidth>
			{content}
		</Container>
	);
};
export default UserCredentials;
