// vim: ts=2
import React, { useState, useContext } from "react";
import { Switch, FormGroup, FormControl, FormControlLabel, Grid, Typography, Modal, Container, Button, Card, CardContent, CardActions, Stack } from "@mui/material";
import { toast } from "react-toastify";
import UserContext from "./../../../Context/UserContext";
import ConfirmationDialog from "./../../ConfirmationDialog/ConfirmationDialog";
import Dialogs from "./../../../Containers/Dialogs/Dialogs";
import CheckIcon from "@mui/icons-material/Check";
import Tag from "./../../Tag/Tag";
const LocationToggle = (props) => {
	const l = props.location;
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [active, setActive] = useState(l.active);
	const [mainLocation, setMainLocation] = useState(l.id === context.mainLocationId);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [requiresConfirmation, setRequiresConfirmation]= useState(false);
	const doSetMainLocation = (id) => {
		const s = (response) => {
			toast.success("Main location changed");
			setRequiresConfirmation(false);
			context.mainLocationId = id;
			props.onMainLocationChanged();
		};
		const e = (error) => {
			toast.error("Failed to change main location.");
		};
		if(!impersonate){	
			context.api.setMainLocation(context, s, e, id);	
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${id}/main-location`,
      "method": "PUT",
			"data": "{}"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	const onMainLocationChanged = (event) => {
		const change = !mainLocation;
		if(!change){
			toast.info("If you wish to change main location please toggle another location.");
			return;
		}
		setConfirmationMessage(`Are you sure that you want to set ${l.name} as your main location?`);
		setRequiresConfirmation(true);
		setMainLocation(true);
	};
	const handleChange = (event) => {
		const current = !active;
		const action = current ? "activate" : "deactivate";
		let confirmationMessage = `Are you sure that you want to ${action} the location ${l.name}?`;
		setRequiresConfirmation(true);
		setConfirmationMessage(confirmationMessage);
		setActive(current);
	};
	const doActivateLocation = () => {
		const s = (response) => {
			toast.success("Location activated");
			setRequiresConfirmation(false);
		};
		const e = (error) => {
			toast.error("Failed to activate location, please contact support.");
		};
		if(!impersonate){
			context.api.activateLocation(context, s, e, l.id);
			return;
		}	
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${l.id}/activate`,
      "method": "PUT",
			"data": "{}"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	const doDeactivateLocation = () => {
		const s = (response) => {
			toast.success("Location deactivated");
			setRequiresConfirmation(false);
		};
		const e = (error) => {
			toast.error("Failed to deactivate location, please contact support.");
		};
		if(!impersonate){
			context.api.deactivateLocation(context, s, e, l.id);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${l.id}/deactivate`,
      "method": "PUT",
			"data": "{}"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	const doConfirmation = (confirmed) => {
		// action was not confirmed
		// or the user clicked off the dialog
		if(confirmed === false){
			// was main location toggled true?
			if(mainLocation){
				setMainLocation(false);
				setRequiresConfirmation(false);
				return;
			}
			const reverted = !active;
			setActive(reverted); // revert action 
			setRequiresConfirmation(false); // close dialog
			return;
		}
		// if main location was toggled true
		// we're changing the users main location
		// only one location can be the main location
		if(mainLocation){
			doSetMainLocation(l.id);
			return;
		}
		// active indicates the users' desired action
		// active would mean to activate a location
		// when acftive is false, deactivate
		if(active === true){
			doActivateLocation();
			return;
		}
		if(active === false){
			doDeactivateLocation();
			return;
		}
	};
	const activate = <Switch checked={active} color="secondary" onChange={handleChange} />;
	const mainToggle = <Switch checked={mainLocation} color="secondary" onChange={onMainLocationChanged} />;
	let check = null;
	if(l.synchronised){
		check = <CheckIcon sx={{verticalAlign:"middle", fontSize:"30px", mr:"5px"}}/>;
	}
	return (
		<Dialogs confirmationMessage={confirmationMessage} 
				confirmAction={requiresConfirmation} 
				onConfirmationClosed={()=>{setRequiresConfirmation(false);}} 
				onConfirmationRejected={()=>{doConfirmation(false);}} 
				onConfirmationAccepted={()=>{doConfirmation(true);}}>	 
			<Card fullWidth raised={true} sx={{padding:"10px"}}>
				<CardContent>
					<Stack spacing={1}>
						<Typography sx={{color:"black", fontSize:"18px", fontWeight:"bold"}}>{l.name}</Typography>
						<Typography sx={{color:"black", fontSize:"16px", fontWeight:"regular"}}>{`Created ${new Date(Date.parse(l.created_at)).toLocaleDateString()}`}</Typography>
						<Typography sx={{color:"black", fontSize:"16px", fontWeight:"regular"}}>{l.synchronised ? "Synchronised" : "Not Synchronised"}</Typography>
						<Typography sx={{color:"black", fontSize:"16px", fontWeight:"regular"}}>{`${l.items_count} Item(s) for sale.`}</Typography>
					</Stack>
				</CardContent>		
				<CardActions>
					<Stack spacing={1}>
						<FormGroup aria-label="position" row>
							<FormControlLabel value="start" control={activate} label={"Active"} labelPlacement={"end"} />
						</FormGroup>
						<FormGroup aria-label="position" row>
							<FormControlLabel value="start" control={mainToggle} label={"Main Location"} labelPlacement={"end"} />
						</FormGroup>
					</Stack>
				</CardActions>
			</Card>
		</Dialogs>
	);
};
export default LocationToggle;
