// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Box, TablePagination, IconButton, Paper, Grid, Button, Typography, TextField, Stack } from "@mui/material"; 
import { MoreVert } from "@mui/icons-material";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import DataTable from "./../DataTable/DataTable";
import UserContext from "./../../Context/UserContext";
import { Download } from "@mui/icons-material";
import Dialogs from "./../../Containers/Dialogs/Dialogs";

const ViewStockTakesDialog  = (props) => {
	// context
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	// state
	const [stocktakes, setStocktakes] = useState(null);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState(null);
	const [fileToDownload, setFileToDownload] = useState("");
	const [download, setDownload] = useState(false);
	// effects
	const doExport = () => {
		const headers = [
			"type",
			"status",
			"last_updated",
			"item_count",
			"item_details"
		];
		const mapper = (e, i) => {
			return {
				"type": e.type,
				"status": e.status,
				"last_updated": new Date(Date.parse(e.updated_at)).toLocaleDateString(),
				"item_count": e.items.length,
				"item_details": e.items.map((i,j)=>{ return i.stock_item.name }).join("; ")
			};
		};
		const records = stocktakes.map(mapper);
		const payload = {
			"headers": headers,
			"records": records
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setFileToDownload(data);
			setDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	const getStocktakes = () => {
		if(stocktakes !== null){
			return;
		}
		const s = async (response) => {
			const receive = response.data.map((e,i)=>{ return {...e, type:"Receive Stock"}});
			let take = null;
			if(!impersonate){
				take = await context.api.getAllTakeStockDraftsSync(context);
			}else{
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/take-stock-drafts/all`,
      		"method": "GET"
				};
				take = await context.api.doImpersonateSync(context, payload);
			}
			take = take.data.map((e,i)=>{ return {...e, type:"Take Stock"}})
			const drafts = [...receive, ...take];
			const sort = {
				"Status": { sorted:false, mode:"none" }, 
				"Last Updated": { sorted:false, mode:"none" }, 
				"Type": { sorted:false, mode:"none" }, 
				"Last Updated": { sorted:false, mode:"none" }
			};
			setSort(sort);
			context.stocktakes = drafts;
			setStocktakes(drafts);	
		};
		const e = (error) => { 
			toast.error("Failed to load stocktakes."); 
		};
		if(!impersonate){
			context.api.getAllReceiveStockDrafts(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/all`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	useEffect(getStocktakes);
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const comparators = {
			"Status": ( a, b ) => { return s.mode === "asc" ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status) },
			"Type": ( a, b ) => { return s.mode === "asc" ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type) },
			"Last Updated": ( a, b ) => { return s.mode === "asc" ? Date.parse(a.updated_at) - Date.parse(b.updated_at) : Date.parse(b.updated_at) - Date.parse(a.updated_at) },
		};	
		const copy = [...context.stocktakes];
		copy.sort(comparators[headerName]);
		setStocktakes(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	let content  = (
		<LoadingSpinner message="Loading stocktakes ..."/>	
	);
	if(stocktakes !== null){
		const offset = page * itemsPerPage;
		let paginated = stocktakes.slice(offset, offset + itemsPerPage);
		const fields = [
			{ field: "type", headerName:"Type", valueGetter: (params)=>{return params.row.type } },
			{ field: "status", headerName:"Status", valueGetter: (params)=>{return params.row.status } },
			{ field: "last_updated", headerName:"Last Updated", valueGetter: (params)=>{return new Date(Date.parse(params.row.updated_at)).toLocaleDateString() } },
			{ field: "item_count", headerName:"Item Count", valueGetter: (params)=>{return params.row.items.length} },
		];
		const filters = {};
		content = (
			<Stack spacing={2}>
			<Grid container>
					<Grid item sm={10}>
						<Typography variant={"h5"} sx={{textAlign:"left", color:"#C12393", fontWeight:"bold", fontSize:"25px"}}>Take and Receive Stocktakes</Typography>
					</Grid>		
					<Grid item sm={2} sx={{textAlign:"right"}}>
						<IconButton onClick={doExport}>
							<Download sx={{color:"black", fontSize:"2rem"}} />	
						</IconButton>	
					</Grid>
			</Grid>
			<Box sx={{width:"100%"}}>
				<DataTable rows={paginated} 
					columns={fields} 
					onRowClicked={()=>{}} 
					onHeaderClicked={()=>{}} 
					onSortClicked={onSortClicked} 
					sort={sort} 
					filters={filters} />
				<Box>
					<TablePagination component={Box} count={stocktakes.length} 
						onPageChange={onPageChanged} page={page} 
						rowsPerPage={itemsPerPage} 
						onRowsPerPageChange={(event)=>{setItemsPerPage(event.target.value);}} />
				</Box>
			</Box>
			</Stack>
		);
	}
	return (
		<InStockDialog onCloseClicked={props.onCloseClicked} title={"View Stocktakes"}>
			<Dialogs download={download} fileToDownload={fileToDownload} onDownloadClosed={()=>{setDownload(false);}}>
				{content}
			</Dialogs>
		</InStockDialog>
	);
};
export default ViewStockTakesDialog;
