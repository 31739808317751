import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography, Dialog, Paper, Grid, Stack, TextField } from "@mui/material";
import { InStockHeader, InStockSubHeader, InStockButton, ErrorText } from "./../Components/Styled";
import InStockDialog from "./../Components/InStockDialog/InStockDialog";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import UserContext from "./../Context/UserContext";
import FormHelper from "./../Helpers/FormHelper"; 
const ForgotPasswordPage = (props) => {
	const location = useLocation();
	// context
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const forms = new FormHelper();
	const constraints = {
		"email": { presence: { allowEmpty: false}, email: true, length: { maximum: 255 } }
	};
	// state
	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState({});
	// events
	const onSubmitClicked = (event) => {
		const payload = {
			"email": email
		};
		const s = (response) => {
			navigate(`/?message=We've sent a link to ${email} to reset your password.`);
		};
		const e = (error) => {
			toast.error("Somethings gone wrong, please try again.");
		};
		InStockClient.getInstance(null).doForgotPassword(payload, s, e);
	};
	const validateSingle = (field, value) => {
		setErrors({});
		const errors = forms.validateSingle(constraints, field, value);
		if(errors !== undefined){
			setErrors(errors);
		}
	};
	const onEmailChanged = (event) => {
		const value = event.target.value;
		setEmail(value);
	};
	const getHasError = (field) => {
		return errors[field] !== undefined;	
	};
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{ return <ErrorText key={`${field}_error_${i}`}>{e}</ErrorText>});
		}
		const mapping = {
			"email": "Your account email"
		};
		return mapping[field];
	};
	// effects
	// content
	let content = (
		<Stack spacing={2}>
			<InStockHeader sx={{textAlign:"center"}}>Forgot Password</InStockHeader>
			<InStockSubHeader sx={{textAlign:"center"}}>Please enter your email address to request a reset password link.</InStockSubHeader>
			<TextField 
				fullWidth
				error={getHasError("email")} 
				helperText={getHelperText("email")}
				onBlur={(event)=>{validateSingle("email", email);}}
				size="large" 
				label="Email" 
				value={email} 
				onChange={onEmailChanged} />
			<Grid container>
				<Grid item lg={4}/>
				<Grid item lg={4} xs={12}>
					<InStockButton onClick={onSubmitClicked} size={"large"} fullWidth>Submit</InStockButton>
				</Grid>
			</Grid>
		</Stack>
	);
	const logo = <img src={InStockClient.getInstance(null).getPublicLogoURL()} style={{height:"1.5em", width:"auto"}} alt={"InStockLogo"} />;
	return (
		<Dialog maxWidth="sm" fullWidth open={true}>
			<InStockDialog title={logo} enableClose={false}>
				{content}
			</InStockDialog>
		</Dialog>
	);
};
export default ForgotPasswordPage;
