// vim: ts=2

import React, { useState, useContext, useEffect } from "react";

import UserContext from "./../../../Context/UserContext";
import InStockClient from "./../../../Api/InStockClient";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
import { BorderedBox } from "./../../Misc/Misc";
import { toast } from "react-toastify";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import Dialogs from "./../../../Containers/Dialogs/Dialogs";

const LocationStatus = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const context = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [jobId, setJobId] = useState(0);
	const [count, setCount] = useState(0);
	useEffect(()=>{
		if(loading && count > 0){
			const s = (response) => {
				const job = response.data;
				const status = job.status;
				if(status !== "COMPLETE"){
					toast.info("Job is still processing ...");
					setTimeout(()=>{setCount(count+1);}, 5000);
					return;
				}
				setLoading(false);
				props.onLocationsSynced();
			};
			const e = (error) => {
				toast.error("Failed to get job status, please contact support.");
			};
			if(!impersonate){
				context.api.getSyncJobStatus(context, s, e, jobId);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/square/sync/${jobId}/status`,
      	"method": "GET"
			};
			context.api.doImpersonate(context, s, e, payload);
		}
	});
	const data = props.data;
	let content = <LoadingSpinner />;
	if(data !== null && data.locationCount === 0){	
		const doSync = () => {
			const s = (response) => { 
				const job = response.data;
				setJobId(job.id);
				setLoading(true);
				setTimeout(()=>{setCount(count+1);}, 5000);
			};
			const e = (error) => { toast.error("Failed to sync data, please contact support."); };
			if(!impersonate){
				context.api.getSyncJob(context, s, e);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/square/sync/new`,
      	"method": "GET"
			};
			context.api.doImpersonate(context, s, e, payload);
		};
		content = (
			<Box>
				<Stack spacing={2}>
					<InStockHeader>Synchronise Your Locations</InStockHeader>
					<InStockSubHeader>Please click the synchronise button to copy your square data into InStock.</InStockSubHeader>
					<Grid container>
						<Grid item sm={12} lg={4}/>		
						<Grid item sm={12} lg={4}>
							<InStockButton fullWidth onClick={doSync}>Synchronise</InStockButton>	
						</Grid>
					</Grid>
				</Stack>
			</Box>
		);
	}
	if(data !== null && data.locationCount > 0){
		const check = <CheckCircle sx={{mr:"15px",  color:"green", verticalAlign:"middle", fontSize:"2.5rem"}}/>;
		let mainLocation = data.mainLocation;
		let message = <span>{data.locationCount} Location(s) synched successfully</span>;
		if(mainLocation === null || mainLocation === undefined){
			message = <span>{data.locationCount} Location(s) synched successfully. Please configure your main location in the location tab.</span>;
		}
		content = (
			<Box>
				<Typography variant="body" element="span" sx={{fontSize:"20px", verticalAlign:"middle"}}>
						{check}{message}
				</Typography>
			</Box>
		);
	}
	return (
		<Dialogs loading={loading} loadingMessage={"Synchronising your data, please wait ..."}>
			<BorderedBox>
				{content}
			</BorderedBox>
		</Dialogs>
	);
}
export default LocationStatus;
