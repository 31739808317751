import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button } from "@mui/material";
import UserContext from "./../../../Context/UserContext";
import FormContext from "./../../../Context/FormContext";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
const SeoSettings = (props) => {
	const context = useContext(UserContext);
	const forms = useContext(FormContext);
	const [settings, setSettings] = useState(null);
	const [errors, setErrors] = useState({});
	const constraints = {
		"title": { presence: { allowEmpty: false }, length: { maximum: 255 } },
		"header": { presence: { allowEmpty: false }, length: { maximum: 255 } },
		"key_words": { presence: { allowEmpty: false }, length: { maximum: 255 } }
	};
	const addErrors = (e) => {
		const copy = {...errors, ...e};
		setErrors(copy);
	};
	const clearErrors = (field) => {
		const copy = {...errors};
		delete copy[field];
		setErrors(copy);
	};
	useEffect(()=>{
		if(settings !== null){
			return;
		}
		const s = (response) => {
			const settings = response.data;
			if(response.status === 204){
				setSettings({});
				return;
			}
			setSettings(settings);
		};
		const e = (error) => {
			toast.error("Failed to load seo settings, please contact support.");
			setSettings({});
		};
		context.api.getLatestSeoSettings(s, e);
	});	
	const getHasError = (field) => {
		return errors[field] !== undefined;
	};
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{return <small style={{display:"block"}} key={`${field}_error_${i}`}>{e}</small>})
		}
		const helperText = {
			"title": "The content for each pages <title> element.",
			"key_words": "The content for the landing pages header <h1> element.",
			"header": "Please enter some keywords that describe your site."
		};
		return helperText[field];
	};
	const validateSingle = (field, value) => {
		const copy = {...errors};
		delete copy[field];
		setErrors(copy);
		const e = forms.formHelper.validateSingle(constraints, field, value);
		if(e !== undefined){
			setErrors({...e, ...errors});
		}
	};
	const onTitleChanged = (event) => {
		const value = event.target.value;
		const copy = {...settings};
		copy.title = value;
		setSettings(copy);
	};
	const onKeyWordsChanged = (event) => {
		const value = event.target.value;
		const copy = {...settings};
		copy.key_words = value;
		setSettings(copy);
	};
	const onHeaderChanged = (event) => {
		const value = event.target.value;
		const copy = {...settings};
		copy.header = value;
		setSettings(copy);
	};
	const addSeoSettings = () => {
		const errors = forms.formHelper.validateAll(constraints, settings);	
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const s = (response) => {
			toast.success("SEO settings updated.");
			const data = response.data;
			setSettings(data);
		};
		const e = (error) => {
			toast.error("Error, failed to update seo settings.");
		};
		context.api.addSeoSettings(s, e, settings);
	};
	let content = (
		<LoadingSpinner />
	);	
	if(settings !== null){
		const exists = settings.created_at !== undefined;
		let header = `Current SEO Settings - None`;
		if(exists){
			const dateCreated = new Date(Date.parse(settings.created_at));
			header = `Current SEO Settings - Created ${dateCreated.toLocaleDateString()}`;
		}
		content = (
			<Stack spacing={2}>
				<Box sx={{textAlign:"center"}}>
					<Stack spacing={1}>
						<InStockHeader>{header}</InStockHeader>
						<InStockSubHeader>Please complete the following form to update SEO settings.</InStockSubHeader>
					</Stack>
				</Box>
				<TextField 
					error={getHasError("title")}
					type={"text"} 
					fullWidth 
					label="Title"
					size={"large"} 
					value={settings.title} 
					onChange={onTitleChanged}
					onBlur={()=>{validateSingle("title", settings.title);}}
					helperText={getHelperText("title")} />
				<TextField 
					error={getHasError("header")}
					type={"text"} 
					fullWidth 
					label={"Header"}
					size={"large"} 
					value={settings.header}  
					onChange={onHeaderChanged}
					onBlur={()=>{validateSingle("header", settings.header);}}
					helperText={getHelperText("header")} />
				<TextField 
					error={getHasError("key_words")}
					type={"text"} 
					fullWidth 
					label={"Key Words"}
					size={"large"} 
					value={settings.key_words} 
					onChange={onKeyWordsChanged}
					onBlur={()=>{validateSingle("key_words", settings.key_words);}}
					helperText={getHelperText("key_words")}/>
				<Grid container>	
					<Grid item lg={4} sm={12}/>
						<Grid item lg={4} sm={12}>
							<InStockButton size={"large"} fullWidth onClick={addSeoSettings}>Submit</InStockButton>
						</Grid>	
					</Grid>
			</Stack>
		);
	}
	return (
		<Container maxWidth="md">
			<Paper elevation={3} sx={{padding:"15px"}}>
				{content}
			</Paper>			
		</Container>
	);
};
export default SeoSettings;
