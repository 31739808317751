// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import InfoIcon from "@mui/icons-material/Info"; 
import DownloadIcon from "@mui/icons-material/Download"; 
import UserContext from "./../../Context/UserContext";
import ReportUsageContext from "./../../Context/ReportUsageContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import StockCategories from "./../Settings/StockCategories/StockCategories";
import StockCounter from "./../StockCounter/UnControlledStockCounter";
import DraftSelectionDialog from "./../DraftSelectionDialog/DraftSelectionDialog";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
import { units } from "./../../constants";
import DataTable from "./../DataTable/DataTable";
import { DatePicker }  from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Dialogs from "./../../Containers/Dialogs/Dialogs";

const EXPORT_HEADERS = [
	"stock_item_name",
	"standard_quantity",
	"stock_item_description",
	"units",
	"usage",
	"received",
	"shrinkage",
	"shrinkage_rate",
	"sell_through_rate",
	"stock_to_sales_ratio",
	"days_stock_on_hand",
	"starting_stock",
	"ending_stock"
];

const ITEMS_PER_PAGE = 10;

const DEFAULT_AGGREGATION = "daily"; 

const RANGE_OPTIONS = [
	{value: "", label:"Select a timeframe..." },
	{value: "1M", label:"One Month" },
	{value: "3M", label:"Current Quarter" },
	{value: "YTD", label:"Year to Date" },
	{value: "FYTD", label:"Financial Year to Date"},
	{value: "FY", label:"Financial Year"},
	{value: "MTD", label:"Month to Date" },
];

const AGGREGATION_OPTIONS = [
	{value: "", label:"Select an aggregation..." },
	{value: "daily", label:"Daily" },
	{value: "weekly", label:"Weekly" },
	{value: "monthly", label:"Monthly" },
];

const AUS_QUARTERS = [
	{ from: 7, to: 10, label: "Q1" },
	{ from: 10, to: 1, label:"Q2" },
	{ from: 1, to: 4, label: "Q3" },
	{ from: 4, to: 7, label: "Q4" },
];

const StockReportsDialog = (props) => {
  const context = useContext(UserContext);
	const usage = useContext(ReportUsageContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [dateFrom, setDateFrom] = useState(null); // moment object
	const [dateTo, setDateTo] = useState(null);  // moment object
	const [range, setRange] = useState("");
	const [aggregation, setAggregation] = useState(DEFAULT_AGGREGATION);
	const [metrics, setMetrics] = useState(null);
	const [download, setDownload] = useState(null);
	
	const getStockReport = () => {	
		if(dateFrom === null || dateFrom === undefined || dateTo === null || dateTo === undefined){
			toast.info("Please select a date range");
			return;
		}
		const s = (response) => {	
			const data = response.data;  // { metrics: [], unit: "", standard_quantity: "" }
			setMetrics(data);
		};
		const e = (error) => {
			toast.error("Failed to generate report.");
		};
		const startDate = dateFrom.format(moment.defaultFormatUtc);
		const endDate = dateTo.format(moment.defaultFormatUtc);
		if(!impersonate){
			context.api.getStockReport(context, s, e, startDate, endDate, aggregation);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-report`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const doOneMonthRange = () => {
		const end = moment();
		const start = moment().subtract(1, "month");
		setDateFrom(start);
		setDateTo(end);
	};

	const doMonthToDate = () => {
		const end = moment();
		const startString = moment().format("YYYY-MM-01");
		const startDate = moment(startString);
		setDateFrom(startDate);
		setDateTo(end);
	}
	
	const doYearToDate = () => {
		const end = moment();
		const startString = moment().format("YYYY-01-01");
		const startDate = moment(startString);
		setDateFrom(startDate);
		setDateTo(end);
	}
	
	const doCurrentQuarter = () => 	{
		const month = Number(moment().format("MM"));
		let quarters = AUS_QUARTERS.filter((x)=>{ return month >= x.from && month < x.to});
		let quarter = quarters[0];
		// handle scenario when quarter rolls over the year
		let rollYear = false;
		if(quarter === undefined){
			quarters = AUS_QUARTERS.filter((x)=>{ return month >= x.from && month > x.to });
			quarter = quarters[0];
			rollYear = true;
		}
		const year = parseInt(moment().format("YYYY"));
		const start = moment(`${year}-${quarter.from}-01`);
		const end = rollYear ? moment(`${year+1}-${quarter.to}-01`).subtract(1,"day") : moment(`${year}-${quarter.to}-01`).subtract(1,"day");
		setDateFrom(start);
		setDateTo(end);
	};	

	const doFinancialYearToDate = () => {
		const month = Number(moment().format("MM")); //  current month
		let year = parseInt(moment().format("YYYY")); // current year
		const end = moment();
		if(month >= 7){
			const start = moment(`${year}-07-01`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}else{
			// financial year already started	previous year 
			const start = moment(`${year-1}-07-01`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}
	}	
	
	const doFinancialYear = () => {
		const month = Number(moment().format("MM")); //  current month
		let year = parseInt(moment().format("YYYY")); // current year
		if(month >= 7){
			const start = moment(`${year}-07-01`);
			const end = moment(`${year+1}-06-30`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}else{
			// financial year already started	previous year 
			const start = moment(`${year-1}-07-01`);
			const end = moment(`${year}-06-30`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}
	}	

	const onRangeChanged = (event) => {
		setMetrics(null);
		const range = event.target.value;	
	 	switch(range){
			case "1M": 
				doOneMonthRange();
				break;
			case "3M":
				doCurrentQuarter();
				break;
			case "YTD":
				doYearToDate();
				break;
			case "FY":
				doFinancialYear();
				break;
			case "FYTD":
				doFinancialYearToDate();
				break;
			case "MTD":
				doMonthToDate();
				break;
		}
		setRange(range);
	};
	
	const doExport = () => {
		if(metrics === null){
			toast.info("Please run a report first.");
			return;
		}
		const records = metrics.map((e,i)=>{ return {
			"stock_item_name": e.stockItemName,
			"standard_quantity": e.standardQuantity,
			"stock_item_description": e.stockItemDescription,
			"units": e.units,
			"usage": e.usage,
			"received": e.received,
			"shrinkage": e.shrinkage,
			"shrinkage_rate": e.shrinkageRate,
			"sell_through_rate": e.sellThroughRate,
			"stock_to_sales_ratio": e.stockToSalesRatio,
			"days_stock_on_hand": e.daysStockOnHand,
			"starting_stock": e.startingStock,
			"ending_stock": e.endingStock
		}});
		const payload = {
			headers: EXPORT_HEADERS,
			records: records
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setDownload(data);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	
	const getReport = () => {
		// debugging
		const debug = true;
		// table columns
		const fields = [
			{ field: "stockItemName", headerName: "Name", valueGetter: (params)=>{ return <span>{params.row.stockItemName}<br/><span>({params.row.stockItemDescription})</span></span>} },
			{ field: "usage", headerName: "Usage", valueGetter: (params)=>{ return <span>{`${params.row.usage} ${params.row.units}`}<br/><span>{`(${params.row.usage/Number(params.row.standardQuantity)} units)`}</span></span> }},
			{ field: "received", headerName: "Received", valueGetter: (params)=>{ return <span>{`${params.row.received} ${params.row.units}`}<br/><span>{`(${params.row.received/Number(params.row.standardQuantity)} units)`}</span></span>}},
			{ field: "shrinkage", headerName: "Shrinkage", valueGetter: (params)=>{ return <span>{`${params.row.shrinkage} ${params.row.units}`}<br/><span>{`(${params.row.shrinkage/Number(params.row.standardQuantity)} units)`}</span></span>}},
			{ field: "shrinkageRate", headerName: "Shrinkage Rate", valueGetter: (params)=>{ return `${Number(params.row.shrinkageRate).toFixed(2)}%`}},
			{ field: "sellThroughRate", headerName: "Sell-through Rate", valueGetter: (params)=>{ return `${Number(params.row.sellThroughRate).toFixed(2)}%`}},
			{ field: "stockToSalesRatio", headerName: "Stock-to-sales Ratio" },
			{ field: "daysStockOnHand", headerName: "Days of stock on hand" },
		];
		if(debug){
			fields.push({field:"startingStock", headerName: "Starting Stock", valueGetter:(params)=>{ return <span>{`${params.row.startingStock} ${params.row.units}`}<br/><span>{`(${params.row.startingStock/Number(params.row.standardQuantity)} units)`}</span></span>}});
			fields.push({field:"endingStock", headerName:"Ending Stock", valueGetter:(params)=>{ return <span>{`${params.row.endingStock} ${params.row.units}`}<br/><span>{`(${params.row.endingStock/Number(params.row.standardQuantity)} units)`}</span></span>}});
		}
		const sort = {};
		const startDate = dateFrom.format("DD/MM/YYYY");
		const endDate = dateTo.format("DD/MM/YYYY");
		const daysInPeriod = metrics[0].daysInPeriod;
		return (
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
					<Typography variant={"body2"}><InfoIcon sx={{verticalAlign:"middle", mr:"5px"}}/>{`Showing stock report for period ${startDate} to ${endDate} (${daysInPeriod} days inclusive)`}</Typography>
					</Grid>
					<Grid item xs={12}>
						<DataTable rows={metrics} 
							columns={fields} 
							onRowClicked={()=>{}} 
							onHeaderClicked={()=>{}} 
							sort={sort} 
							onSortClicked={()=>{}} 
							filters={[]} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const helper = (
		<Grid item xs={12}>
			<Box sx={{textAlign:"center", marginTop:"15px"}}>
				<Typography variant={"body2"} sx={{display:"inline", border:"1px solid #CDCDCD", padding:"15px", borderRadius:"5px"}}>
					<InfoIcon sx={{verticalAlign:"middle", mr:"5px"}}/>Please select a date range.
				</Typography>
			</Box>
		</Grid>
	);
	
	let fileName = null;
	if(metrics !== null && dateTo !== null && dateFrom !== null){
		const shortDateFrom = dateFrom.format("YYYYMMDD");
		const shortDateTo = dateTo.format("YYYYMMDD");
		fileName = `stock_report_${shortDateFrom}_${shortDateTo}.csv`;
	}

	return (
		<Dialogs
			fileName={fileName}
			download={download !== null}
			fileToDownload={download}
			onDownloadClosed={()=>{setDownload(null);}}
>
		<InStockDialog title={`Stock Reports`} onCloseClicked={props.onCloseClicked}>
			<Paper elevation={3} sx={{padding:"25px"}}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<DatePicker format={"DD/MM/YYYY"} value={dateFrom} slotProps={{field:{clearable:true}}} onChange={(value, ctx)=>{setDateFrom(value);setMetrics(null);}} label={"From"} />		
							</Grid>
							<Grid item xs={3}>
								<DatePicker format={"DD/MM/YYYY"} value={dateTo} slotProps={{field:{clearable:true}}} onChange={(value, ctx)=>{setDateTo(value);setMetrics(null);}} label={"To"} />		
							</Grid>
							<Grid item xs={4}>
								<TextField value={range} onChange={onRangeChanged} label={"Timeframe"} helperText={"Select a pre-defined timeframe, all timeframes are inclusive."} select fullWidth>
									{RANGE_OPTIONS.map((e,i)=>{ return <MenuItem value={e.value} key={`range_option_${i}`}>{e.label}</MenuItem>})}
								</TextField>
							</Grid>
							<Grid item xs={1} sx={{textAlign:"center"}}>
								<Button onClick={getStockReport} 
									size="large" 
									variant="contained" 
									className="pg"
									sx={{color:"white", fontWeight:"bold", mr:"15px"}}>
										Run
								</Button>
							</Grid>
							<Grid item xs={1} sx={{textAlign:"center"}}>
								<IconButton onClick={doExport}>
									<DownloadIcon sx={{color:"black", fontSize:"2rem"}} />	
								</IconButton>	
							</Grid>
						</Grid>
					</Grid>
					{metrics === null || dateTo === null || dateFrom === null ? helper : getReport()}
				</Grid>
			</Paper>
		</InStockDialog>
		</Dialogs>
	);
};

export default React.memo(StockReportsDialog);
