import React, { useState, useContext, useEffect } from "react";
import UserContext from "./../../../Context/UserContext";
import InStockClient from "./../../../Api/InStockClient";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
import { BorderedBox } from "./../../Misc/Misc";
import { toast } from "react-toastify";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import Dialogs from "./../../../Containers/Dialogs/Dialogs";
const AccountStatus = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	let content = <LoadingSpinner />;
	const [checked, setChecked] = useState(false);
	const [connecting, setConnecting] = useState(false);
	const [account, setAccount] = useState(null);
	const [count, setCount] = useState(0);
	useEffect(()=>{
		if(account === null && checked && count > 0 && connecting){
			const s = async (response) => {
				const a = response.data; 
				// account not authorized yet
				// wait another 5 seconds and try again ...
				if(a.authorization_code === null){
					setTimeout(()=>{setCount(count+1);}, 5000);
					return;
				}
				// once authorized get a token from square
				const token = await context.api.getSquareTokenSync(context);
				if(token.status !== 204){
					toast.info("Trying to get access token from square, please wait ...");
					setTimeout(()=>{setCount(count+1);}, 5000);
					return;
				}
				toast.success("Square account connected!");
				setConnecting(false);
				props.onSquareConnected();
			};
			const e = (error) => {
				toast.info("Failed to get square account ... please contact support.");
				setTimeout(()=>{setCount(count+1);}, 5000);
				return;
			};
			if(!impersonate){
				context.api.getSquareAccount(context, s, e);
				return;
			}else{
				setConnecting(false);
				props.onSquareConnected();
			}
		}
	});
	useEffect(()=>{
		if(checked){
			return;
		}
		const s = (response) => {
			// no content means no account exists
			if(response.status === 204){
				setChecked(true);
				return;
			}
			const data = response.data;
			setAccount(data);
			setChecked(true);
		};
		const e = (error) => {
			toast.error("Failed to get square account ... please contact support.");
		};
		if(!impersonate){
			context.api.getSquareAccount(context, s, e);
			return;
		}else{
			setAccount(context.impersonatedAccount);
			setChecked(true);
		}
	});
	const data = props.data;
	if(data !== null && checked){
		if(!data.squareComplete){
			// connect to square
			const doSquareConnect = () => {
				const s = (response) => {
					const account = response.data;
					const l = document.getElementById("authorization_link");
					l.href = account.authorization_link;
					l.click();
					// watch account and check for authorization code
					setConnecting(true);
					setTimeout(()=>{setCount(count+1);}, 5000);
				};
				const e = () => {
					toast.error("Failed to connect to square, please contact support.");
				};
				// only attempt to connect to square if no account could be found
				if(account === null){
					context.api.connectToSquare(context, s, e);	
					return;
				}
			};
			content = (
				<Box sx={{textAlign:"left"}}>
					<Stack spacing={2}>
						<InStockHeader sx={{textAlign:"center"}}>Connect Your Square Account</InStockHeader>
						<InStockSubHeader sx={{textAlign:"center"}}>Square account not linked, please follow the process below to connect your square account.</InStockSubHeader>
						<ol>
							<li>Please <strong>allow popups</strong> from this site, clicking connect will <strong>open a new window.</strong></li>
							<li>Click <strong>connect</strong> to permit instock to access your square account.</li>
							<li>Once clicked you will need to login to square if you aren't already logged in.</li>
							<li>You should be presented with a page detailing permissions InStock requires.</li>
							<li>Please click the accept button, without approval InStock will not function correctly.</li>
							<li>Once the approval process has completed this page will refresh automatically.</li>
						</ol>
						<Grid container>
							<Grid item lg={4} sm={12} />
							<Grid item lg={4} sm={12}>
								<InStockButton fullWidth onClick={doSquareConnect}>Connect</InStockButton>
								{/* this link doesn't need to be visilbe ... its just here to open a new window upon clicking */}
								<a style={{display:"none"}} target="_blank" href="#" id="authorization_link">square link</a>
							</Grid>
						</Grid>
					</Stack>
				</Box>
			);		
		}else{
			const check = <CheckCircle sx={{mr:"15px",  color:"green", verticalAlign:"middle", fontSize:"2.5rem"}}/>;
			content = (
				<Box>
					{check}
					<Typography variant="body" element="span" sx={{fontSize:"20px", verticalAlign:"middle"}}>
						<span>Square account linked successfully</span>
					</Typography>
				</Box>
			);
		}
	}
	return (
		<Dialogs loading={connecting} loadingMessage={"Waiting for square ..."}>
			<BorderedBox>
				{content}
			</BorderedBox>
		</Dialogs>
	);
}
export default AccountStatus;
