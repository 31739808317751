import React, { useState } from "react";
const Tag = (props) => {
	const disabled = props.disabled;
	const darkPurple = "#5E085A";
	const lightPurple = "#C12393";
	const [checked, setChecked] = useState(false);
	const [border, setBorder] = useState(checked ? `1px solid ${darkPurple}` : "1px solid #E6E6E6");
	const [background, setBackground] =  useState(checked ? darkPurple : "#E6E6E6");
	let style = {
		color: checked ? "white" : "black",
		borderRadius:"12px 12px 12px 12px", 
		backgroundColor: background, 
		border: border, 
		marginRight:"5px", 
		padding:"5px 10px", 
		marginTop:"10px", 
		display:"inline-block"
	};
	const onClick = () => {
		if(disabled){
			return;
		}
		setChecked(true);
	};
	const onMouseEnter = (event) => {
		if(disabled){
			return;
		}
		if(checked){
			setBackground(lightPurple);
			return;
		}
		setBackground("white");
		setBorder("1px solid black");
	};
	const onMouseLeave = (event) => {
		if(disabled){
			return;
		}
		if(checked){
			setBackground(darkPurple);
			return;
		}
		setBackground("#E6E6E6");
		setBorder("1px solid #E6E6E6");
	};
	return (
		<span style={style} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{props.value}</span>	
	);
};
export default Tag;
