import React from "react";
import { InStockHeader } from "./../Styled"; 
import { Box } from "@mui/material";
const StripeSettingsView = (props) => {
	return (
		<Box sx={{textAlign:"center"}}>
			<Box sx={{border:"1px solid #CDCDCD", padding:"15px", borderRadius:"15px"}}>
				<InStockHeader>Stripe settings to be added after MVP.</InStockHeader>
			</Box>
		</Box>
	);
};
export default StripeSettingsView;
