import React, { useState, useContext, useEffect } from "react";
import UserContext from "./../../../Context/UserContext";
import InStockClient from "./../../../Api/InStockClient";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { BorderedBox } from "./../../Misc/Misc";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
const CatalogItemSyncStatusStatus = (props) => {
	const data = props.data;
	let content = <LoadingSpinner />;
	if(data !== null){
		const check = <CheckCircle sx={{mr:"15px",  color:"green", verticalAlign:"middle", fontSize:"2.5rem"}}/>;
		content = (
			<Box>
				{check}
				<Typography variant="body" element="span" sx={{fontSize:"20px", verticalAlign:"middle"}}>
					<span>{data.totalSalesItems} Sales item(s) across {data.locationCount} location(s) synched successfully.</span>
				</Typography>
			</Box>
		);
	}
	return (
		<BorderedBox>
			{content}
		</BorderedBox>
	);
}
export default CatalogItemSyncStatusStatus;
