// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import AddStockDialog from "./../AddStockDialog/AddStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { IngredientList } from "./../Recipe/Recipe";
import { units as ConstantUnits } from "./../../constants";
import { Dialog, Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";

const validator = require("validate.js");

const ManageModifierDialog = (props) => {
	// props and context
	const debug = props.debug;
	const context = useContext(UserContext);
	const recipeId = props.recipeId;
	const modifierId = props.modifierId;
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const ingredientMapper = (e) => {
		return {
			id: e.id,
			modifier_id: modifierId, // modifier id is global
			name: e.stock_item.name,
			stock_item_id: e.stock_item_id,
			quantity: e.quantity * 1,
			quantity_units: e.quantity_units,
			category: e.stock_item.category.name,
			recipe_id: recipeId,
			units_of_measure: e.stock_item.units_of_measure
		};
	};
	// fields
	const [errors, setErrors] = useState(null);
	// data
	const [recipeModifiers, setRecipeModifiers] = useState(null);
	const [recipe, setRecipe] = useState(null);
	const [stockItems, setStockItems] = useState(null);
	const [addingStockItem, setAddingStockItem] = useState(false);
	// event handlers
	const clearIngredients = () => {
		setRecipeModifiers([]);
	};
	// create new modifiers by copying the ingredients from the recipe
	const copyIngredients = () => {
		const s = (response) => {
			toast.success("Ingredients copied");
			let modifiers = response.data.map(ingredientMapper);
			setRecipeModifiers(modifiers);
		};
		const e = (error) => {
			toast.error("Failed to copy ingredients from recipe.");
		};
		if(!impersonate){
			context.api.copyRecipeIngredients(context, s, e, recipeId, modifierId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}/square-modifiers/${modifierId}/copy-ingredients`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	// effects
	// load all data at once ...
	// locations, categories ...
	useEffect(()=>{
		if(recipe !== null){
			return;
		}
		const s = async (response) => {
			let modifiers = response.data.map(ingredientMapper);
			let recipe = null;
			if(!impersonate){
				// regular user
				recipe = await context.api.getRecipeSync(context, recipeId);
			}else{
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}`,
      		"method": "GET"
				};
				recipe = await context.api.doImpersonateSync(context, payload);
			}
			let items = null;
			if(!impersonate){
				items = await context.api.getStockItemsSync(context);
			}else{	
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      		"method": "GET"
				};
				items = await context.api.doImpersonateSync(context, payload);
			}
			setStockItems(items.data);
			setRecipe(recipe.data);
			setRecipeModifiers(modifiers);
		};
		const e = async (error) => {
			toast.error("Error, failed to get recipe modifiers. Please contact support.");
		};
		if(!impersonate){
			context.api.getRecipeModifiersForSquareId(context, s, e, recipeId, modifierId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}/square-modifiers/${modifierId}/modifiers`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	// add modifier to recipe
	// update list using response from backend
	const onIngredientSaved = (event, i) => {
		const s = (response) => {
			toast.success("Modifier added");
			const data = response.data.map(ingredientMapper);
			setRecipeModifiers(data);
		};
		const e = (error) => {
			toast.error("Failed to add recipe modifier");
		};
		const payload = {...i};
		if(!impersonate){
			context.api.addRecipeModifier(context, s, e, recipeId, modifierId, payload);	
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}/square-modifiers/${modifierId}`,
      "method": "POST",
			"data": JSON.stringify(payload)
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const onIngredientEdited = (event, i) => {
		const s = (response) => {
			toast.success("Modifier updated");
			const data = response.data.map(ingredientMapper);
			setRecipeModifiers(data);
		};
		const e = (error) => {
			toast.error("Failed to update modifier, please contact support.");
		};
		if(!impersonate){
			context.api.updateRecipeModifier(context, s, e, recipeId, i.id, i);
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}/modifiers/${i.id}`,
      "method": "PUT",
			"data": JSON.stringify(i)
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const onIngredientDeleted = (event, i) => {
		const s = (response) => {
			toast.success("Modifier deleted.");
			const data = response.data.map(ingredientMapper);
			setRecipeModifiers(data);
		};
		const e = (error) => {
			toast.error("Failed to delete modifier, please contact support.");
		};
		if(!impersonate){
			context.api.deleteRecipeModifier(context, s, e, recipeId, modifierId, i.id);
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipeId}/square-modifiers/${modifierId}/modifiers/${i.id}`,
      "method": "DELETE"
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const addStockItem = () => {
		setAddingStockItem(true);
	};
	let content = (
		<LoadingSpinner />
	);
	// only  show the following when locations and categories
	// have been loaded
	if(recipeModifiers !== null){
		const units = Object.keys(ConstantUnits).reduce((a,c)=>{const items = ConstantUnits[c]; return [...a, ...items];}, []);
		content = (
			<Grid container>
				<Grid item xs={12}>
					<IngredientList 
						units={units} 
						ingredients={recipeModifiers} 
						onAddStockItem={addStockItem}
						stockItems={stockItems} 
						onIngredientSaved={onIngredientSaved} 
						onIngredientEdited={onIngredientEdited}
						onIngredientDeleted={onIngredientDeleted} />
				</Grid>		
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button variant={"contained"} className="pg" sx={{color:"white"}} size={"large"} fullWidth onClick={copyIngredients}>Copy Recipe</Button>		
						</Grid>			
						<Grid item xs={6}>
							<Button variant={"contained"} className="pg" sx={{color:"white"}} size={"large"} fullWidth onClick={clearIngredients}>Clear</Button>		
						</Grid>			
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return (
		<>
			<Dialog maxWidth="sm" fullWidth open={addingStockItem} onClose={()=>{setAddingStockItem(false);}}>
				<AddStockDialog impersonate={false} 
					onCloseClicked={()=>{setAddingStockItem(false);}} 
					onStockItemAdded={(event)=>{setAddingStockItem(false);setRecipe(null);}} />
			</Dialog>
			<InStockDialog title={debug ? `Manage Modifier - ${modifierId} - ${recipeId}` : "Manage Modifier"} 
					onCloseClicked={props.onCloseClicked} 
					width={12}>
				{content}
			</InStockDialog>
		</>
	);
};
export default ManageModifierDialog;
