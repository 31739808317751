import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import ActivateSalesItem from "./../Settings/ActivateSalesItem/ActivateSalesItem";
import StockCategories from "./../Settings/StockCategories/StockCategories";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
const validator = require("validate.js");
const ActivateSalesItemDialog = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	return (
		<InStockDialog title={"Activate Sales Item"} onCloseClicked={props.onCloseClicked}>
			<ActivateSalesItem onSalesItemActivated={props.onSalesItemActivated} impersonate={impersonate} />
		</InStockDialog>
	);
};
export default ActivateSalesItemDialog;
