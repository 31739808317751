import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog, Container, Typography, Stack, Box, Button, Grid, TablePagination, Paper, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { InStockHeader, InStockButton, InStockSubHeader } from "./../Components/Styled";
import { Download } from "@mui/icons-material";
import SortingHelper from "./../Helpers/SortingHelper";
import DataTable from "./../Components/DataTable/DataTable";
import AdminLayout from "./../Layouts/AdminLayout";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import FreeTrialBanner from "./../Components/FreeTrialBanner/FreeTrialBanner";
import StockAlertWidget from "./../Components/StockAlertWidget/StockAlertWidget";
import NewStockWidget from "./../Components/NewStockWidget/NewStockWidget";
import TableToolsDialog from "./../Components/TableToolsDialog/TableToolsDialog";
import TakeStockDialog from "./../Components/TakeStockDialog/TakeStockDialog";
import DraftSelectionDialog from "./../Components/DraftSelectionDialog/DraftSelectionDialog";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import UserContext from "./../Context/UserContext";
const UsersPage = (props) => {
	const navigate = useNavigate();
	const context = useContext(UserContext);
	const [users, setUsers] = useState(null);
	const [selectedHeader, setSelectedHeader] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [filterTerm, setFilterTerm] = useState("");
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState(null); // sorting state, clicking table headers manipulates this
	const loading = users === null;
	const [download, setDownload] = useState(false);
	const [fileToDownload, setFileToDownload] = useState("");
	const doExport = () => {
		const headers = [
			"full_name",
			"email",
			"roles",
			"free_trial_expired",
			"email_verified",
			"date_registered"
		];
		const mapper = (e, i) => {
			return {
				"full_name": e.full_name,
				"email": e.email,
				"roles": e.roles.map((e,i)=>{return e.role.name }).join(", "),
				"free_trial_expired": e.free_trial_expired ? "Yes" : "No",
				"email_verified": e.email_verified ? "Yes" : "No",
				"date_registered": new Date(Date.parse(e.created_at)).toLocaleDateString()
			};
		};
		const records = users.map(mapper);
		const payload = {
			"headers": headers,
			"records": records
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setFileToDownload(data);
			setDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	const onSearchChanged = (phrase) => {
		// clearing the search phrase is the same as clearing search results
		if(phrase === ""){
			setUsers([...context.users]);
			return;
		}
		const upper = phrase.toUpperCase();
		const predicates = {
			"Full Name": (x, value) => { return x.full_name.toUpperCase().indexOf(value) !== -1 },
			"Email": (x, value) => { return x.email.toUpperCase().indexOf(value) !== -1 }
		};
		const matches = context.users.filter((x)=>{ return predicates[selectedHeader](x, upper)});
		setUsers(matches);
	};
	const onFilterValueChanged = (filter, value) => {
		setFilterTerm(value);
		if(filter === "" || value === "None" || value === ""){
			setUsers([...context.users]);
			return;
		}
		// filters don't directly map to fields
		// need to use a function to get the value from the data object
		// add to this map to implement new filters
		const mapping = {
			"Roles": (item, value) => { return item.roles.find((x)=>{ return x.role.name === value }) !== undefined },
			"Free Trial Expired": (item, value) => { return item.free_trial_expired ? value === "Yes" : !item.free_trial_expired ? value === "No" : true },
			"Email Verified": (item, value) => { return item.email_verified ? value === "Yes" : !item.email_verified ? value === "No" : true }
		};	
		const f = mapping[filter];
		const matches = context.users.filter((x)=>{ return f(x, value) });
		setUsers(matches);
	};
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	const onRowClicked = (event, row) => {
		// dont need to do anything ...
	};
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const sorting = new SortingHelper();
		const comparators = {
			"Full Name": ( a, b ) => { return s.mode === "asc" ? a.full_name.localeCompare(b.full_name) : b.full_name.localeCompare(a.full_name) },
			"Email": ( a, b ) => { return s.mode === "asc" ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email) },
			"Date Registered": ( a, b ) => { return sorting.sortDates(a.created_at, b.created_at, s.mode) }
		};	
		const copy = [...context.users];
		copy.sort(comparators[headerName]);
		setUsers(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	const onHeaderClicked = (event, headerName) => {
		const config = {
			searchable: ["Full Name", "Email"],
			filterable: ["Roles", "Free Trial Expired", "Email Verified"]
		};
		context.menuAnchor = event.target;
		// determine if searchable or filterable
		// once either of these actions has been checked
		// set selected header causing rendering ...
		const searching = config.searchable.find((x)=>{ return x === headerName });
		if(searching !== undefined){
			context.searchingTable = true;
			context.filteringTable = false;
			setSelectedHeader(headerName);
			return;
		}
		const filtering = config.filterable.find((x)=>{ return x === headerName });
		if(filtering !== undefined){
			context.filteringTable = true;
			context.searchingTable = false;
			setSelectedHeader(headerName);
			return;
		}
		context.filteringTable = false;
		context.searchingTable = false;
		setSelectedHeader(null);
	};
	const getUniqueRoles = (users) => {	
		const reducer = (carry, item) => {
			const roles = item.roles.map((e)=>{ return e.role.name });	
			roles.forEach((i)=>{
				carry[i] = 1;
			});
			return carry;
		};
		const o = users.reduce(reducer, {});	
		return Object.keys(o);
	};
	const getFilters = (users) => {
		return { 
			"Roles": [ ...getUniqueRoles(users), "None" ],
			"Email Verified": [ "Yes", "No", "None" ],
			"Free Trial Expired": [ "Yes", "No", "None" ]
		};
	};
	// load data ...
	useEffect(()=>{
		if(users !== null){
			return;		
		}
		const s = (response) => {
			const data = response.data.map((e,i)=>{ return {...e, key: e.id}});
			context.users = data;
			const sort = {
				"Full Name": { sorted: false, mode: "none" },
				"Email": { sorted: false, mode: "none" },
				"Date Registered": { sorted: false, mode: "none" } 
			};
			setSort(sort);
			setUsers(data);
		};
		const e = (error) => {
			toast.error("Failed to get users from backend, please contact support.");
		};
		context.api.getUsers(s, e);
	});
	let content = <LoadingSpinner height={"100px"} message="Loading users, please wait ..."/>;
	if(!loading){
		const itemsPerPage = 10; 
		const offset = page * itemsPerPage;
		let paginated = users.slice(offset, offset + itemsPerPage);
		const fields = [
			{ field: "full_name", headerName: "Full Name" },
			{ field: "email", headerName: "Email" },
			{ field: "roles", headerName: "Roles", valueGetter: (params)=>{return params.row.roles.map((e,i)=>{return e.role.name }).join(", ")}},
			{ field: "free_trial_expired", headerName:"Free Trial Expired", valueGetter: (params)=>{return params.row.free_trial_expired ? "Yes": "No"}},
			{ field: "email_verified", headerName:"Email Verified", valueGetter: (params)=>{return params.row.email_verified ? "Yes": "No"}},
			{ field: "date_created", headerName:"Date Registered", valueGetter: (params)=>{ return new Date(Date.parse(params.row.created_at)).toLocaleDateString()}},
		];
		const filters = getFilters(context.users);
		let menuAction = null;
		if(context.searchingTable){
			menuAction = (
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD"}}>
					<TextField value={searchTerm} label="Search ..." size="small" 
						onChange={(event)=>{setSearchTerm(event.target.value);}} 
						onBlur={()=>{onSearchChanged(searchTerm);}} />
				</Box>
			);
		}
		if(context.filteringTable){
			menuAction = (	
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD", width:"200px"}}>
					<TextField select 
							fullWidth
							value={filterTerm} 
							label="Filter by ..." 
							size="small" 
							onChange={(event)=>{onFilterValueChanged(selectedHeader, event.target.value);}}>
						{filters[selectedHeader].map((e,i)=>{ return <MenuItem key={e} value={e}>{e}</MenuItem> })}
					</TextField>
				</Box>
			);
		}
		content = (
			<Stack spacing={2}>
				<Paper elevation={3} sx={{padding:"25px"}}>
				<Stack spacing={2}>
				<Grid container>
					<Grid item sm={10}>
						<Typography variant={"h5"} sx={{color:"#C12393", fontWeight:"bold", fontSize:"25px"}}>Registered Users</Typography>
					</Grid>		
					<Grid item sm={2} sx={{textAlign:"right"}}>
						<IconButton onClick={doExport}>
							<Download sx={{color:"black", fontSize:"2rem"}} />	
						</IconButton>	
					</Grid>
				</Grid>
				<Box sx={{backgroundColor:"inherit"}}>
					<Typography variant={"body"} sx={{color:"#5E085A", fontSize:"20px", verticalAlign:"middle"}}>{users.length} RESULT(S)</Typography>
				</Box>
				<Box sx={{width:"100%", maxHeight:"800px"}}>
					<DataTable rows={paginated} 
						columns={fields} 
						onRowClicked={onRowClicked} 
						onHeaderClicked={onHeaderClicked} 
						onSortClicked={onSortClicked} 
						sort={sort} 
						filters={filters} />
					<Box sx={{float:"right"}}>
						<TablePagination component={Box} count={users.length} onPageChange={onPageChanged} page={page} rowsPerPage={itemsPerPage} />
					</Box>
				</Box>
				</Stack>
				</Paper>
				{/* Search menu ...*/}
				<Menu open={selectedHeader !== null} 
						anchorEl={context.menuAnchor} 
						id="search-menu" 
						onClose={(event)=>{ context.filteringTable=false; context.searchingTable=false; setSelectedHeader(null); }}>
					{menuAction}
				</Menu>
			</Stack>
		);
	}
	return (
		<AdminLayout>
			<Dialogs 
					download={download}
					fileToDownload={fileToDownload}
					onDownloadClosed={()=>{setDownload(false);}}>
				<Box sx={{padding:"15px"}}>
					{content}
				</Box>
			</Dialogs>
		</AdminLayout>	
	);
};
export default UsersPage;
