import React from "react";
import { useNavigate } from "react-router-dom";
import { Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, South, North, ArrowDropDown, ArrowDropUp, Sort, FilterAlt } from "@mui/icons-material";
const DataTableHeader = (props) => {
	const col = props.col;
	const headerStyles = props.headerStyles;
	const sort = props.sort;
	const style = {
		cursor: "pointer",
		fontSize: "18px",
		fontWeight: "bold",
		color: "black",
		...headerStyles[col.headerName]
	};
	const headerSort = sort[col.headerName];
	let content = col.headerName;
	if(props.isFilterable){
		content = (
			<span>
				{col.headerName}
				<FilterAlt sx={{ml:"5px", color:"black", verticalAlign:"middle"}}/>
			</span>
		);
	}
	// if header sort is not undefined this means the column is sortable
	if(headerSort !== undefined){
		// columns that aren't sorted need to be presented differnetly
		// from columns that are
		let icon = null;
		if(!headerSort.sorted){
			icon = <Sort sx={{color:"black"}} />;
		}
		// column is sorted in ascending order
		if(headerSort.sorted && headerSort.mode === "asc"){
			icon = <ArrowDropUp sx={{color:"black"}} />;
		}
		// column is sorted in descending order
		if(headerSort.sorted && headerSort.mode === "desc"){
			icon = <ArrowDropDown sx={{color:"black"}} />;
		}
		content = (
			<span>
				{col.headerName}
				<span style={{verticalAlign:"middle", marginLeft:"5px"}}>
					<IconButton onClick={(event)=>{event.stopPropagation();props.onSortClicked(event, col.headerName);}}>
						{icon}
					</IconButton>
				</span>
			</span>
		);
	}
	return (
		<TableCell sx={style} onClick={(event)=>{props.onHeaderClicked(event, col.headerName);}}>
			{content}
		</TableCell>
	);
};
const DataTableRow = (props) => {
	const navigate = useNavigate();
	const data = props.data;
	const cols = props.cols;
	const even = props.index % 2 == 0;
	const headerStyles = props.headerStyles !== undefined ? props.headerStyles : {};
	const colour = "white";
	const handleClick = (event) => {
		props.onRowClicked(event, data);
	};
	const cells = cols.map((e)=>{ return <TableCell sx={{backgroundColor:colour, ...headerStyles[e.headerName]}} key={`${e.field}_${data['key']}`}>{e.valueGetter !== undefined ? e.valueGetter({row: data}) : data[e.field]}</TableCell>});
	return (
		<TableRow sx={{cursor:"pointer"}} onClick={handleClick}>
			{cells}
		</TableRow>
	);
};
const DataTable  = (props) => {
	const cols = props.columns;
	const data = props.rows;
	const sort = props.sort;
	const headerStyles = props.headerStyles !== undefined ? props.headerStyles : {};
	const filters = props.filters !== undefined ? props.filters : {};
	const headers = cols.map((e)=>{ return <DataTableHeader isFilterable={filters[e.headerName] !== undefined} headerStyles={headerStyles} col={e} sort={sort} key={`header_${e.headerName}`} onSortClicked={props.onSortClicked} onHeaderClicked={props.onHeaderClicked}/> });
	const rows = data.map((e,i)=>{ return <DataTableRow headerStyles={headerStyles} index={i} key={e.key} data={e} cols={cols} onRowClicked={props.onRowClicked}/>});
	return (
		<TableContainer component={Box} sx={{height:"100%"}}>
			<Table aria-label="data table">
				<TableHead>
					<TableRow>
						{headers}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows}
				</TableBody>
			</Table>	
		</TableContainer>	
	);
}
export default DataTable;
