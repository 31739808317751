import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import UserContext from "./../../../Context/UserContext";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
const TermsOfServiceSettings = (props) => {
	const context = useContext(UserContext)
	const [uri, setURI] = useState(null); // data URI for file ....
	const [data, setData] = useState(null); 
	const [fileName, setFileName] = useState(null);
	const [fileType, setFileType] = useState(null);
	const [dateCreated, setDateCreated] = useState(null);
	useEffect(()=>{
		if(uri !== null){	
			return;
		}
		const s = (response) => {
			const terms = response.data;
			const status = response.status;
			// handle scenario when there's no data
			if(status === 204){
				setURI("none");
				return;
			}
			// data exists ...
			const uri = terms.uri;
			const created = new Date(Date.parse(terms.created_at));
			setDateCreated(created);
			setURI(uri);
		};
		const e = (error) => {
			toast.error("Failed to load privacy policy ...");
		};
		context.api.getLatestPrivacyPolicy(s, e);
	});
	const addPrivacyPolicy = () => {
		const s = (response) => {
			toast.success("Privacy policy updated.");
			setURI(null);
		};
		const e = (error) => { toast.error("Failed to upload new privacy policy."); };
		const payload = {
			data: data,
			mime_type: fileType,
			file_name: fileName
		};
		context.api.addPrivacyPolicy(s, e, payload);
	};
	const doUpload = () => {
		document.getElementById("privacy-policy").click();
	};
	const onFileChanged = (event) => {
		const f = event.target.files[0];
		const type = f.type;
		const name = f.name;
		const reader = new FileReader();
		reader.onload = (event) => {
			let data = event.target.result;
			data = btoa(data);
			setFileName(name);
			setFileType(type);
			setData(data);
		};
		reader.readAsBinaryString(f);
	};
	const getObjectURL = () => {
		const index = uri.indexOf(";base64,");
		const base64 = uri.substring(index+";base64,".length);
		const binary = atob(base64);
		const blob = new Blob([binary], {type: "application/pdf"});
		return URL.createObjectURL(blob);
	};
	let content = (
		<Box sx={{padding:"15px"}}>
			<LoadingSpinner message={"loading privacy policy ..."}/>
		</Box>
	);
	if(uri !== null && uri !== "none"){
		content = (
			<Stack spacing={2}>
				<Typography sx={{textAlign:"center", color:"#C12393", fontWeight:"bold", fontSize:"20px"}}>
					{`Current Privacy Policy - Created on ${dateCreated.toLocaleDateString()}`}
				</Typography>
				<Typography sx={{textAlign:"center", color:"black", fontWeight:"regular", fontSize:"18px"}}>
					Please use the following file upload to create a new version.
				</Typography>
				<embed src={uri} type={"application/pdf"} alt={"Privacy policy file"} style={{width:"100%", marginBottom:"15px", height:"600px", overflowY:"scroll"}}/>
			</Stack>
		);
	}
	if(uri === "none"){
		content = (
			<Box sx={{textAlign:"center", padding:"20px", border:"1px solid #CDCDCD", borderRadius:"10px", marginBottom:"15px"}}>
				<Typography sx={{color:"black", fontSize:"20px", fontWeight:"bold"}}>No privacy policy exists, please upload one.</Typography>			
			</Box>
		);
	}
	return (
		<Container maxWidth={"lg"}>
			<Paper elevation={3} sx={{padding:"15px"}}>
				{content}
				<Grid container>
					<Grid item lg={10} sm={12}>
						<TextField 
							type={"text"} 
							fullWidth 
							size={"large"} 
							value={fileName} 
							placeholder={"Please upload a file ..."} 
							helperText={"Only PDF files are allowed, maximum file size 2MB."}
							disabled 
							InputProps={{endAdornment: <InputAdornment position={"end"}><IconButton onClick={doUpload}><CloudUpload sx={{color:"black"}}/></IconButton></InputAdornment>}}/>
						<input id={"privacy-policy"} type={"file"} style={{display:"none"}} onChange={onFileChanged}/>
					</Grid>
					<Grid item lg={2} sm={12}>
						<Button className="pg" onClick={addPrivacyPolicy} sx={{color:"white", ml:"10px"}} size="large">Submit</Button>
					</Grid>
				</Grid>
			</Paper>	
		</Container>
	);
};
export default TermsOfServiceSettings;
