import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import LoadingSvg from "./../../Assets/Images/instock-loading.svg";
const LoadingSpinner = (props) => {
	const message = props.message === undefined ? "Loading ..." : props.message;	
	const hasMessage = message !== null;
	const height = props.height === undefined ? "75px" : props.height;
	return (
		<Box sx={{textAlign:"center", backgroundColor:"rgba(255,255,255,0.0)"}}>
			<Stack spacing={2}>
		{hasMessage ? <Typography sx={{fontSize:"20px", fontWeight:"bold", color:"black"}}>{message}</Typography> : null }
				<img src={LoadingSvg} alt="loading" width="auto" height={height}/>
			</Stack>
		</Box>	
	);
};
export default LoadingSpinner;
