// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./../../../Context/UserContext";
import InStockClient from "./../../../Api/InStockClient";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { BorderedBox } from "./../../Misc/Misc";
import { toast } from "react-toastify";
import { Box, Grid, Typography, Button, Stack, TextField, MenuItem } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
const MainLocationSetup = (props) => {
	const getDefaultLocation = () => {
		return props.data.merchant.main_location.id;
	};
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const context = useContext(UserContext);
	const [mainLocation, setMainLocation] = useState(getDefaultLocation());
	const data = props.data;
	let content = <LoadingSpinner />;
	const onSubmitClicked = () => {
		const s = (response) => {
			toast.success("Main location set successfully.");
			const selected = props.data.locations.find((e)=>{ return e.id * 1 === mainLocation * 1});
			if(selected === undefined){
				toast.error("Failed to validate selected location.");
				return;
			}
			if(impersonate){
				context.impersonatedLocationId = selected.id;
			}
			context.selectedLocation = selected;
			props.onMainLocationSet();
		};
		const e = (error) => {
			toast.error("Failed to set main location, please contact support.");		
		};
		if(!impersonate){
			context.api.setMainLocation(context, s, e, mainLocation);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${mainLocation}/main-location`,
      "method": "PUT",
			"data": JSON.stringify({})
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	if(data !== null){
		if(data.mainLocationSet){
		const check = <CheckCircle sx={{mr:"15px",  color:"green", verticalAlign:"middle", fontSize:"2.5rem"}}/>;
		let message = <span>Your main location is <b>{data.mainLocation}.</b></span>;
			content = (
				<Box>
					<Typography variant="body" element="span" sx={{fontSize:"20px", verticalAlign:"middle"}}>
							{check}{message}
					</Typography>
				</Box>
			);
		}else{
		content = (
			<Stack spacing={2}>
				<InStockHeader>Select Your Main Location</InStockHeader>
				<InStockSubHeader>Please select your main business location.</InStockSubHeader>
				<InStockSubHeader>Square indicates that your main location is <strong>{data.merchant.main_location.name}</strong></InStockSubHeader>
				<Grid container>
					<Grid item lg={4}/>
					<Grid item lg={4} sm={12}>
					<TextField
						sx={{mt:"15px"}}
						select
						fullWidth
						value={mainLocation}
						onChange={(event)=>{setMainLocation(event.target.value);}}
						label="Location"
						helperText="Select your primary place of business."
						error={mainLocation === -1}>
							{data.locations.map((e,i)=>{ return <MenuItem key={`location_${e.id}`} value={e.id}>{e.name}</MenuItem>})}
					</TextField>
					</Grid>
					<Grid lg={12} sm={12}>
						<Grid container>
							<Grid item lg={4}/>
							<Grid lg={4} sm={12}>
								<InStockButton size={"large"} sx={{mt:"15px"}} fullWidth onClick={onSubmitClicked}>
									Submit
								</InStockButton>
							</Grid>
						</Grid>	
					</Grid>
				</Grid>
			</Stack>
		);
		}
	}
	return (
		<BorderedBox>
			{content}			
		</BorderedBox>		
	);
};
export default MainLocationSetup;
