import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./../../../Context/UserContext";
import InStockClient from "./../../../Api/InStockClient";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { BorderedBox } from "./../../Misc/Misc";
import { toast } from "react-toastify";
import { Box, Typography, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
const OnBoardingStatus = (props) => {
	const data = props.data;
	let content = <LoadingSpinner />;
	if(data !== null){
		content = (
			<Typography sx={{fontSize:"20px", fontWeight:"bold", textAlign:"center"}}>On boarding complete</Typography>
		);
	}
	return (
		<BorderedBox>
			{content}			
		</BorderedBox>		
	);
};
export default OnBoardingStatus;
