import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button, FormGroup, Switch, FormControlLabel } from "@mui/material";
import UserContext from "./../../../Context/UserContext";
import FormContext from "./../../../Context/FormContext";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
const SeoSettings = (props) => {
	const context = useContext(UserContext);
	const [settings, setSettings] = useState(null);
	useEffect(()=>{
		if(settings !== null){
			return;
		}
		const s = (response) => {
			const settings = response.data;
			if(response.status === 204){
				setSettings({email_verification: true}); // default enabled
				return;
			}
			setSettings(settings);
		};
		const e = (error) => {
			toast.error("Failed to load seo settings, please contact support.");
			setSettings({});
		};
		context.api.getLatestEmailVerificationSettings(s, e);
	});	
	const onVerificationChanged = (event) => {
		const copy = {...settings};
		copy.verification_enabled = !copy.verification_enabled;
		setSettings(copy);
	};
	const addEmailVerificationSettings = () => {
		const s = (response) => {
			toast.success("Email settings updated.");
			const data = response.data;
			setSettings(data);
		};
		const e = (error) => {
			toast.error("Error, failed to update email settings.");
		};
		context.api.addEmailVerificationSettings(s, e, settings);
	};
	let content = (
		<LoadingSpinner />
	);	
	if(settings !== null){
		const exists = settings.created_at !== undefined;
		let header = `Current Verification Settings - None`;
		if(exists){
			const dateCreated = new Date(Date.parse(settings.created_at));
			header = `Current Verification Settings - Created ${dateCreated.toLocaleDateString()}`;
		}
		const enableVerification = (
			<Switch size={"large"} color="secondary" checked={settings.verification_enabled} onChange={onVerificationChanged} />
		);
		content = (
			<Stack spacing={2}>
				<Box sx={{textAlign:"center"}}>
					<Stack spacing={1}>
						<InStockHeader>{header}</InStockHeader>
						<InStockSubHeader>Please complete the following form to update verification settings.</InStockSubHeader>
					</Stack>
				</Box>
				<FormGroup row={true}>
					<FormControlLabel control={enableVerification} label={"Enable Email Verification"} />
				</FormGroup>
				<Grid container>	
					<Grid item lg={4} sm={12}/>
						<Grid item lg={4} sm={12}>
							<InStockButton size={"large"} fullWidth onClick={addEmailVerificationSettings}>Submit</InStockButton>
						</Grid>	
					</Grid>
			</Stack>
		);
	}
	return (
		<Container maxWidth="md">
			<Paper elevation={3} sx={{padding:"15px"}}>
				{content}
			</Paper>			
		</Container>
	);
};
export default SeoSettings;
