import React from "react";
const context = React.createContext({
	userId: null, // id of useR
	token: null, // authorization client
	api: null, // instance of api client
	freeTrialAlert: false, // has the user seen the free trial alert
	selectedLocation: null,
	locations: null,
	selectedItem: null
});
export default context;
