class SortingHelper {
	sortDates = ( a, b, mode ) => {
		const l = Date.parse(a);
		const r = Date.parse(b);
		return this.sortNumbers(l, r, mode);
	}
	sortNumbers = ( l, r, mode ) => {
		if(mode === "asc"){
			if(l < r){
				return -1;
			}else if(l > r){
				return 1; 
			}else{
				return 0;
			}
		}
		if(mode === "desc"){
			if(l < r){
				return 1;
			}else if(l > r){
				return -1; 
			}else{
				return 0;
			}
		}
	}
}
export default SortingHelper;
