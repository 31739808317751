import React, { useState, useContext, useEffect } from "react";

import { InStockHeader, InStockSubHeader, InStockCardHeader, InStockCardContent, InStockButton } from "./../Styled";
import UserContext from "./../../Context/UserContext";
import InStockClient from "./../../Api/InStockClient";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { Container, Stack, Box, Grid, Card, CardContent } from "@mui/material";
import Dialogs from "./../../Containers/Dialogs/Dialogs";

const SynchronisationView  = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [job, setJob] = useState(null);
	const [count, setCount] = useState(0);
	const [jobId, setJobId] = useState(null);
	const [loading, setLoading] = useState(false);
	const onSynchroniseClicked = () => {
		const s = (response) => {
			const job = response.data;
			setJobId(job.id);
			setLoading(true);
			setTimeout(()=>{setCount(count+1);}, 5000);
		};
		const e = (error) => {
			toast.error("Failed to get new sync job, please contact support.");
		};
		if(!impersonate){
			context.api.getSyncJob(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/square/sync/new`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	useEffect(()=>{
		if(loading && jobId !== null && count > 0){
			const s = (response) => {
				const job = response.data;
				const status = job.status;
				if(status !== "COMPLETE"){
					toast.info("Job is still processing ...");
					setTimeout(()=>{setCount(count+1);}, 5000);
					return;
				}
				setLoading(false);
				setJobId(null);
				setJob(null);
			};
			const e = (error) => {
				toast.error("Failed to get job status, please contact support.");
			};
			if(!impersonate){
				context.api.getSyncJobStatus(context, s, e, jobId);
				return;
			}	
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/square/sync/${jobId}/status`,
      	"method": "GET"
			};
			context.api.doImpersonate(context, s, e, payload);
		}
	});
	useEffect(()=>{
		if(job !== null){
			return;
		}
		const s = (response) => {
			let data = null;
			if(response.status === 204){
				data = {};
			}
			data = response.data;
			setJob(data);
		};
		const e = (error) => {
			toast.error("Failed to get latest sync job, please contact support.");
		};
		if(!impersonate){
			context.api.getLatestSyncJob(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/square/sync/latest`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	let content = <LoadingSpinner/>;
	if(job !== null){
		const subTime = Date.parse(job.created_at);
		const upTime = Date.parse(job.updated_at);
		const submitted = new Date(subTime);
		const updated = new Date(upTime);
		const duration = (upTime - subTime)/1000.0;
		content = (
			<Grid container>
				<Grid item lg={6} sm={12}>
					<Box sx={{padding:"15px"}}>
						<Card fullWidth sx={{minHeight:"227px"}}>
							<CardContent>
								<Stack spacing={2}>
									<InStockCardHeader>Last Synchronised</InStockCardHeader>
									<Box sx={{textAlign:"left"}}>
									<Stack spacing={2}>
										<InStockCardContent>{`Job Submitted ${submitted.toLocaleDateString()} ${submitted.toLocaleTimeString()}`}</InStockCardContent>
										<InStockCardContent>{`Job Updated ${updated.toLocaleDateString()} ${updated.toLocaleTimeString()}`}</InStockCardContent>
										<InStockCardContent>{`Duration ${duration} seconds`}</InStockCardContent>
										<InStockCardContent>{`Status ${job.status}`}</InStockCardContent>
									</Stack>
									</Box>
								</Stack>	
							</CardContent>
						</Card>
					</Box>
				</Grid>
				<Grid item lg={6} sm={12}>
					<Box sx={{padding:"15px"}}>
						<Card fullWidth sx={{minHeight:"227px"}}>
							<CardContent>
								<Stack spacing={2}>
									<InStockCardHeader>Synchronise Now</InStockCardHeader>
									<InStockCardContent>Click the button below to synchronise your Square and InStock accounts.</InStockCardContent>
									<InStockButton size={"large"} onClick={onSynchroniseClicked}>Synchronise</InStockButton>
								</Stack>	
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>
		);
	}
	return (
		<Dialogs loading={loading} loadingMessage={"Synchronising your data, please wait ..."}>
			<Container maxWidth="lg" className="instock-view">
				<Box sx={{textAlign:"center"}}>
					<Stack spacing={2}>
						<InStockHeader>Synchronisation Status</InStockHeader>	
						<InStockSubHeader>Use this page to synchronise your Square and InStock accounts.</InStockSubHeader>	
						{content}
					</Stack>
				</Box>
			</Container>		
		</Dialogs>
	);
};
export default SynchronisationView;
