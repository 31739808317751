import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toast } from "react-toastify";
import InStockLogo from "./../Assets/Images/instock-admin.png";
import StockSvg from "./../Assets/Images/stock.svg";
import DashSvg from "./../Assets/Images/dash.svg";
import CogSvg from "./../Assets/Images/cog.svg";
import CartSvg from "./../Assets/Images/cart.svg";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import PortalSelect from "./../Components/PortalSelect/PortalSelect";
import InStockLink from "./../Components/InStockLink/InStockLink";
import DefaultUserAvatar from "bootstrap-icons/icons/person-fill.svg";
import ChevronDownSvg from "bootstrap-icons/icons/chevron-down.svg";
import ChevronUpSvg from "bootstrap-icons/icons/chevron-up.svg";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import UserContext from "./../Context/UserContext";
import { ShoppingCart, Dashboard, Inventory, Settings, People, SwitchAccount } from "@mui/icons-material";
import { Container, Stack, AppBar, Toolbar, Box, Button, Typography, Grid, TextField, MenuItem, Avatar, IconButton, Tooltip, Menu } from "@mui/material";
const AdminLayout = (props) => {
	const contentRef = useRef(null);
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const [accountSettingsAnchor, setAccountSettingsAnchor] = useState(null);
	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState(false);
	// view profile
	const doProfile = () => {
		setProfile(true);
	};
	// logout 
	// doesn't really matter if an error happens here
	// just means a stray token will get cleaned up later
	const doLogout = () => {
		const client = InStockClient.getInstance(context.token);
		const s = (response) => {
			context.userId = null;
			context.token = null;
			context.locations = null;
			context.freeTrialAlert = false;
			context.navigation = null;
			navigate("/?message=You've been logged out.");
		};
		const e = (error) => {
			context.userId = null;
			context.freeTrialAlert = false;
			context.token = null;
			context.locations = null;
			context.navigation = null;
			navigate("/?message=You've been logged out.");
		};
		client.logout(s, e);
	};
	// make sure that the user is logged in
	useEffect(()=>{
		if(context.token === null || 
				context.userId === null || 
				context.token === undefined || 
				context.token === undefined){
			navigate("/");
		}
	});
	// load user profile
	useEffect(()=>{	
		if(user !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			// may as well store user in context
			// for convenience ...
			context.user = data;
			setUser(data);
		};
		const e = (error) => {
			toast.error("Failed to load user profile, please contact support.");
		};
		context.api.getUser(context, s, e);
	});
  const getClassName = (view) => {
    return view === context.navigation ? "InStockLinkAdmin InStockLinkAdminSelected" : "InStockLinkAdmin";
  };
	const accountSettingsOpen = accountSettingsAnchor !== null;
	let chevronIcon = accountSettingsOpen ? "bi-chevron-up" : "bi-chevron-down";
	let profilePicture = DefaultUserAvatar;
	if(user !== null){
		profilePicture = user.profile_picture_data_uri;
	}
	return (
		<Dialogs profile={profile} onProfileClosed={()=>{setProfile(false);}}>
			<Grid container>
				<Grid item lg={12} sx={{paddingTop:"15px", paddingBottom:"15px", backgroundColor:"#E6E6E6"}}>
					<Grid container>
						<Grid item lg={2} xs={6} sx={{textAlign:"left", backgroundColor: "#E6E6E6", paddingLeft:"15px", paddingRight:"15px"}}>
							<img src={InStockLogo} height="auto" width="100%" style={{verticalAlign:"middle"}} />
						</Grid>	
						<Grid item lg={10} xs={6} sx={{backgroundColor: "#E6E6E6"}}>
							<Box sx={{flexGrow:1, width:"100%", backgroundColor:"#E6E6E6"}}>
								<Box sx={{backgroundColor:"white", width:"130px", height:"auto", float:"right", borderRadius:"50px 0px 0px 50px"}}>
									<IconButton disabled sx={{cursor: "arrow"}}>
										<Avatar sx={{width:"62px", height:"62px", bgcolor:"gray"}} src={profilePicture}>
										</Avatar>
									</IconButton>
									<IconButton onClick={(event)=>setAccountSettingsAnchor(event.currentTarget)} 
											size="medium" aria-haspopup="true"
											aria-expanded={accountSettingsOpen ? "true" : undefined} 
											area-controls={accountSettingsOpen ? "account-menu" : undefined}>
										<i className={`bi ${chevronIcon}`} style={{color:"#5E085A"}}></i>
									</IconButton>
								</Box>
							</Box>
							<Menu open={accountSettingsOpen} anchorEl={accountSettingsAnchor} id="account-menu" onClose={(event)=>setAccountSettingsAnchor(null)}>
								<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doProfile();}}>
									Profile
								</MenuItem>
								<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doLogout();}}>
									Logout
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>	
				</Grid>
				<Grid item lg={12} xs={12}>
					<Grid container>
					<Grid item lg={2} xs={12} sx={{paddingLeft:"15px", height:window.innerHeight-110, backgroundColor:"#E6E6E6"}}>
						<Stack spacing={3}>
							<Link className={getClassName("dashboard")} to={"/dashboard"} onClick={()=>{context.navigation = "dashboard";}}>
								<span style={{verticalAlign:"middle"}}>
									<Dashboard className="InStockLinkIcon" />
									Dashboard
								</span>
							</Link>
							<Link to="/admin-settings" className={getClassName("admin-settings")} onClick={(event)=>{context.navigation = "admin-settings";}}>
								<span style={{verticalAlign:"middle"}}>
									<Settings className="InStockLinkIcon" />
									Settings
								</span>
							</Link>
							<Link to="/users" className={getClassName("users")} onClick={(event)=>{context.navigation = "users";}}>
								<span style={{verticalAlign:"middle"}}>
									<People className="InStockLinkIcon" />
									Users
								</span>
							</Link>
							<Link to="/accounts" className={getClassName("accounts")} onClick={(event)=>{context.navigation = "accounts";}}>
								<span style={{verticalAlign:"middle"}}>
									<SwitchAccount className="InStockLinkIcon" />
									Accounts
								</span>
							</Link>
						</Stack>
					</Grid>
					<Grid item lg={10} xs={12} sx={{borderLeft:"2px solid #CDCDCD", borderTop:"2px solid #CDCDCD"}}>
						<Container maxWidth={false} disableGutters={true} ref={contentRef} sx={{maxHeight:window.innerHeight-110, overflowY:"scroll"}}>
            	{props.children}
          	</Container>
					</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Dialogs>
	);
};
export default React.memo(AdminLayout);
