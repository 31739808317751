// vim:  ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import StockCategories from "./../Settings/StockCategories/StockCategories";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
const validator = require("validate.js");
const AddCategoryDialog = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	return (
		<InStockDialog title={"Add Stock Category"} onCloseClicked={props.onCloseClicked}>
			<StockCategories impersonate={impersonate} onCategoryAdded={props.onCategoryAdded} onCategoryDeleted={props.onCategoryDeleted} />
		</InStockDialog>
	);
};
export default AddCategoryDialog;
