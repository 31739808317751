// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Grid } from "@mui/material";
import RemoveButton from "./../RemoveButton/RemoveButton";
import AddButton from "./../AddButton/AddButton";
import UserContext from "./../../Context/UserContext";

const UnControlledStockCounter = (props) => {

	// state
	const absoluteQuantity = props.absoluteQuantity;
	const unitQuantity = props.unitQuantity;
	const standardQuantity = props.standardQuantity;

	// event handlers	
	const onAbsoluteQuantityChanged = (quantity) => {
		const value = Number(quantity); // mui fields are text fields, value is a string ...
		const unitQuantity = (value/standardQuantity).toFixed(2);
		props.onQuantitiesChanged({"absolute": value, "units": unitQuantity});
	};

	const onUnitQuantityChanged = (units) => {
		const value = Number(units);
		const quantity = Math.floor(value * standardQuantity);
		props.onQuantitiesChanged({"absolute": quantity, "units": value});
	};

	const decUnitQuantity = () => {
		let value = Number(unitQuantity);
		if(value - 1 < 0){
			toast.info("Quantity must be at least one.");
			return;
		}
		value -= 1;
		onUnitQuantityChanged(value);
	};

	const incUnitQuantity = () => {
		let value = Number(unitQuantity);
		value += 1;
		onUnitQuantityChanged(value);
	};

	const incAbsoluteQuantity = () => {
		let value = Number(absoluteQuantity);
		value += 1;
		onAbsoluteQuantityChanged(value);
	};

	const decAbsoluteQuantity = () => {
		let value = Number(absoluteQuantity);
		if(value - 1 < 0){
			toast.info("Quantity must be at least one.");
			return;
		}
		value -= 1;
		onAbsoluteQuantityChanged(value);
	};

	const absolute = (
		<Grid item xs={12}>
			<Grid container>
			<Grid item xs={2} sx={{textAlign:"right"}}>
			<RemoveButton onClick={decAbsoluteQuantity}/>
			</Grid>
			<Grid item xs={8}>
			<TextField 
				value={absoluteQuantity} 
				size={"small"} 
				fullWidth
				onBlur={(event)=>{onAbsoluteQuantityChanged(event.target.value);}}
				onChange={props.onAbsoluteQuantityChanged} 
				label={`quantity (${props.units})`} />
			</Grid>
			<Grid item xs={2}>
			<AddButton onClick={incAbsoluteQuantity}/>
			</Grid>
			</Grid>
		</Grid>
	);

	const units = (
		<Grid item xs={12}>
			<Grid container>
			<Grid item xs={2} sx={{textAlign:"right"}}>
				<RemoveButton onClick={decUnitQuantity}/>
			</Grid>
			<Grid item xs={8}>
			<TextField 
				value={unitQuantity} 
				size={"small"} 
				fullWidth
				onBlur={(event)=>{onUnitQuantityChanged(event.target.value);}}
				onChange={props.onUnitQuantityChanged} 
				label={`quantity (units)`} />
			</Grid>
			<Grid item xs={2}>
			<AddButton onClick={incUnitQuantity}/>
			</Grid>
			</Grid>
		</Grid>	
	);

	return (
		<Grid container spacing={2}>
			{absolute}
			{units}
		</Grid>	
	);

};
export default UnControlledStockCounter;
