import React from "react";
import { Box, Container, Typography, Stack, Button, Grid } from "@mui/material"; 
import InStockDialog from "./../InStockDialog/InStockDialog";
const ConfirmationDialog = (props) => {
	const message = props.message;
	const accept = props.accept === undefined ? "Yes, I'm sure." : props.accept;
	const reject = props.reject === undefined ? "No, cancel." : props.reject;
	return (
		<InStockDialog title={"Please Confirm"} onCloseClicked={props.onCloseClicked}>
			<Stack spacing={2}>
				<Typography sx={{fontSize:"20px", color:"black", fontWeight:"bold", textAlign:"center"}}>
					{message}
				</Typography>	
				<Grid container>
					<Grid item sm={6} sx={{padding:"10px"}}>
						<Button className="pg" variant={"contained"} sx={{color:"white"}} fullWidth size={"large"} onClick={props.onConfirmed}>
							{accept}
						</Button>
					</Grid>		
					<Grid item sm={6} sx={{padding:"10px"}}>
						<Button variant={"outlined"} sx={{color:"black", border:"1px solid #CDCDCD"}} fullWidth size={"large"} onClick={props.onCancelled}>
							{reject}
						</Button>
					</Grid>
				</Grid>
			</Stack>
		</InStockDialog>
	);
};
export default ConfirmationDialog;
