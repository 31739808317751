import React, { useState, useEffect } from "react";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
export const BorderedBox = (props) => {
	return (
		<Paper elevation={3} sx={{textAlign:"center", padding:"25px"}}>
			{props.children}			
		</Paper>
	);	
};
export const FileUpload = (props) => {
	const placeholder = props.placeholder === undefined ? "Please upload a file ..." : props.placeholder;
	const helperText = props.helperText === undefined ? null : props.helperText;
	const id = props.id;
	const [fileName, setFileName] = useState(props.fileName);
	// allow clients to update the filename that's displayed
	// this will typically happen when a new file is accepted
	useEffect(()=>{
		if(props.fileName !== fileName){
			setFileName(props.fileName);
		}
	});
	const doUpload = () => {
		document.getElementById(id).click();
	};
	const onFileChanged = (event) => {
		const f = event.target.files[0];
		const type = f.type;
		const name = f.name;
		const reader = new FileReader();
		reader.onload = (event) => {
			let data = event.target.result;
			data = btoa(data);
			const file = {
				data: data,
				name: name,
				type: type
			};
			// chain file changed events to clients				
			props.onFileChanged(file);
		};
		if(!props.acceptFile(f)){
			props.rejectFile();
			return;
		}
		reader.readAsBinaryString(f);
	};
	const adornment = (
		<InputAdornment position={"end"}>
			<IconButton onClick={doUpload}>
				<CloudUpload sx={{color:"black"}}/>
			</IconButton>
		</InputAdornment>
	);
	return (
		<Grid container>
			<Grid item sm={12}>
				<TextField
					type={"text"}
          fullWidth
          size={"large"}
          value={fileName}
          placeholder={placeholder}
          helperText={helperText}
          disabled
          InputProps={{endAdornment: adornment}} />
				<input id={id} type={"file"} style={{display:"none"}} onChange={onFileChanged} />
			</Grid>			
		</Grid>	
	);
};
