import React from "react";
import CancelIcon from "@mui/icons-material/Cancel"; 
import { 
	Box, Grid, Stack, 
	IconButton, Typography, Paper, 
	TextField, MenuItem, 
	Chip, Button, Container } from "@mui/material";
const InStockDialog = (props) => {
	const title = props.title;
	const offset = props.offset !== undefined ? props.offset : 0; // 2 large columns offset
	const width = props.width !== undefined ? props.width : 12; // 8 large columns wide
	const enableClose = props.enableClose === undefined ? true : props.enableClose;
	let padding = {"padding":"25px"};
	if(props.padding !== undefined){
		padding = props.padding;	
	}
	let closeButton  = (
		<IconButton sx={{top:"0px", right:"0px", position:"absolute"}} onClick={props.onCloseClicked}>
			<CancelIcon sx={{color:"white"}}/>
		</IconButton>
	);
	if(!enableClose){
		closeButton = null;
	}
	return (
			<Grid container>
				<Grid item xs={12} lg={width}>
					<Box className="pg" sx={{borderRadius:"15px 15px 0px 0px",backgroundColor:"rgba(255,255,255,0.0) !important"}}>
						<Grid container sx={{padding:"25px"}}>
							<Grid item xs={12}>
								<Typography sx={{fontSize:"24px", fontWeight:"bold", color:"white", textAlign:"center"}}>
									{title}
								</Typography>
							</Grid>
								{closeButton}
						</Grid>
					</Box>
					{/* everything below this box is dynamic, need to replace wtih loading spinner once data is loading ...*/}
					<Box sx={{backgroundColor:"white", padding: padding, borderRadius:"0px 0px 15px 15px"}}>
						{props.children}
					</Box>
				</Grid>			
			</Grid>
	);
};
export default InStockDialog;
