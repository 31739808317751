// vim: ts=2

import Axios from "axios";
class InStockClient {
	static getInstance = (token) => {
		const instance = new InStockClient();
		instance.axios = this.getAxios(token);
		return instance;
	};
	static getAxios = (token) => {
		const backend = process.env.REACT_APP_BACKEND;
		const instance = Axios.create({
			baseURL: backend,
			timeout: 300000,
			headers: {
				"Content-Type": "application/json",
				"Authorization": token
			}
		});
		return instance;
	};
	// authentication and registration
	login = (data, s, e) => {
		this.axios.post("/api/login", data)
			.then(s)
			.catch(e);	
	};
	logout = (s, e) => {
		this.axios.get("/api/logout").
			then(s).
			catch(e);	
	};
	// square end points
	getSquareAccount = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/square/account`).
			then(s).
			catch(e);	
	};	
	updateUser = (context, s, e, payload) => {	
		this.axios.put(`/api/users/${context.userId}`, payload).
			then(s).
			catch(e);
	}
	getNewReportUsageDraft = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/report-usage-drafts/new`).then(s).catch(e);
	};
	getAllReportUsageDrafts = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/report-usage-drafts/all`).then(s).catch(e);
	};
	deleteReportUsageDraft = (context, s, e, id) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/report-usage-drafts/${id}/delete`).then(s).catch(e);
	};
	saveReportUsageDraft = (context, s, e, draft) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/report-usage-drafts/${draft.id}`, draft).then(s).catch(e);
	};
	finaliseReportUsageDraft = (context, s, e, draft) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/report-usage-drafts/${draft.id}/finalise`, draft).then(s).catch(e);
	};
	getAllReceiveStockDrafts = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/all`).then(s).catch(e);
	};
	getAllTakeStockDrafts = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/all`).then(s).catch(e);
	};
	getAllTakeStockDraftsSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/all`);
	};
	// get user information
	getUser = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}`).then(s).catch(e);
	}; 
	// get catalog items
	// these were synced *from* square
	// this api doesn't call square's api ..
	getCatalogItemsSync = async (context, s, e) => {
		return await this.axios.get(`/api/users/${context.userId}/catalog/items`);
	};
	// get locations
	// locations were synched from square
	// doesn't call square ...
	getLocationsSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/locations`);
	};
	// get locations async
	// same data as above
	// but async ...
	getLocations = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations`).
		then(s).
		catch(e);
	};
	// get user information
	getUser = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}`).then(s).catch(e);
	}; 
	// get catalog items
	// these were synced *from* square
	// this api doesn't call square's api ..
	getCatalogItemsSync = async (context, s, e) => {
		return await this.axios.get(`/api/users/${context.userId}/catalog/items`);
	};
	// get locations
	// locations were synched from square
	// doesn't call square ...
	getLocationsSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/locations`);
	};
	// get locations async
	// same data as above
	// but async ...
	getLocations = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations`).
			then(s).
			catch(e);
	}
	doReportUsage = (context, payload, stockItemId, s, e) => {
		return this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-items/${stockItemId}/usage`, payload).
			then(s).
			catch(e);
	}
	// get all stock items
	// using a synchronous call
	// not async
	getStockItemsSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-items`);
	}
	updateStockItem = (context, s, e, payload, itemId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-items/${itemId}`, payload).
			then(s).
			catch(e);
	};
	addStockItem = (context, s, e, payload) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-items`, payload).
			then(s).
			catch(e);
	};
	doImpersonateSync = async (context, payload) => {
		const backend = process.env.REACT_APP_BACKEND;
		payload["url"] = `${backend}${payload['url']}`;
		return await this.axios.post(`/api/admin/impersonate`, payload);
	};
	doImpersonate = (context, s, e, payload) => {
		const backend = process.env.REACT_APP_BACKEND;
		payload["url"] = `${backend}${payload['url']}`;
		this.axios.post(`/api/admin/impersonate`, payload).
			then(s).
			catch(e);
	};
	// get all stock items
	// using a asynchronous call
	getStockItems = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-items`).
			then(s).
			catch(e);
	}
	getStockReport = (context, s, e, f, t, agg) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-report?from=${f}&to=${t}&agg=${agg}`).
			then(s).
			catch(e);
	}
	getCategories = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-categories`).
			then(s).
			catch(e);	
	};
	addCategory = (context, s, e, data) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-categories`, data).
			then(s).
			catch(e);
	};
	// this is a soft delete
	// the category is not actually deleted
	deleteCategory = (context, s, e, id) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stock-categories/${id}`).
			then(s).
			catch(e);	
	};
	getStocktakingLocations = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stocktaking-locations`).
			then(s).
			catch(e);
	};
	getStocktakingLocationsSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stocktaking-locations`);
	};
	addStocktakingLocation = (context, s, e, data) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stocktaking-locations`, data).
			then(s).
			catch(e);
	};
	// same as category delete
	deleteStocktakingLocation = (context, s, e, id) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/stocktaking-locations/${id}`).
			then(s).
			catch(e);
	};
	activateLocation = (context, s, e, locationId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${locationId}/activate`).
			then(s).
			catch(e);	
	};
	deactivateLocation = (context, s, e, locationId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${locationId}/deactivate`).
			then(s).
			catch(e);
	};
	getSalesItems = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items`).
			then(s).
			catch(e);	
	};
	getSalesItemsSync = async (context, s, e) => {
		return await this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items`);
	};
	activateItemVariation = (context, s, e, itemId, variationId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items/${itemId}/variations/${variationId}/activate`).
			then(s).
			catch(e);
	};
	deactivateItemVariation = (context, s, e, itemId, variationId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items/${itemId}/variations/${variationId}/deactivate`).
			then(s).
			catch(e);
	};
	activateSalesItem = (context, s, e, itemId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items/${itemId}/activate`).
			then(s).
			catch(e);
	};
	deactivateSalesItem = (context, s, e, itemId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/sales-items/${itemId}/deactivate`).
			then(s).
			catch(e);
	};
	updateRecipe = (context, s, e, recipeId, data) => {
		this.axios.put(`/api/users/${context.userId}/recipes/${recipeId}`, data).
			then(s).
			catch(e);	
	};
	updateIngredient = (context, s, e, recipeId, ingredientId, data) => {
		this.axios.put(`/api/users/${context.userId}/recipes/${recipeId}/ingredients/${ingredientId}`, data).
			then(s).
			catch(e);
	};
	addIngredient = (context, s, e, recipeId, data) => {
		this.axios.post(`/api/users/${context.userId}/recipes/${recipeId}/ingredients`, data).
			then(s).
			catch(e);
	};
	deleteIngredient = (context, s, e, recipeId, ingredientId) => {
		this.axios.delete(`/api/users/${context.userId}/recipes/${recipeId}/ingredients/${ingredientId}`).
			then(s).
			catch(e);
	};
	// get modifier lists for a given catalog item
	getModifierLists = (context, s, e, itemId) => {
		this.axios.get(`/api/users/${context.userId}/catalog/items/${itemId}/modifier-lists`).
			then(s).
			catch(e);
	};
	// get all recipe modifiers
	// could be many records for a given square modifier
	// for example extra skinny milk would redact the existing milk ingredient
	// and add a new ingredient, resulting in two records 
	getRecipeModifiers = () => {
		alert("Not implemented.");	
	}
	// get all square modifiers used in this recipe
	// using the previous example this would return "extra - skinny milk" twice
	// however this end point de-dupes the data
	getRecipeSquareModifiers = () => {
		alert("Not implemented.");	
	};
	// same as above but for a single modifier
	getRecipeSquareModifier = () => {
		alert("Not implemented.");	
	};
	copyRecipeIngredients = (context, s, e, recipeId, modifierId) => {
		this.axios.get(`/api/users/${context.userId}/recipes/${recipeId}/square-modifiers/${modifierId}/copy-ingredients`)
			.then(s)
			.catch(e);
	};
	getRecipeModifiersForSquareId = (context, s, e, recipeId, squareId) => {
		this.axios.get(`/api/users/${context.userId}/recipes/${recipeId}/square-modifiers/${squareId}/modifiers`).
			then(s).
			catch(e);
	};
	getRecipeSync = async (context, recipeId) => {
		return await this.axios.get(`/api/users/${context.userId}/recipes/${recipeId}`);
	}
	addRecipeModifier = (context, s, e, recipeId, modifierId, payload) => {
		this.axios.post(`/api/users/${context.userId}/recipes/${recipeId}/square-modifiers/${modifierId}`, payload)
			.then(s)
			.catch(e);	
	};
	updateRecipeModifier = (context, s, e, recipeId, modifierId, payload) => {
		this.axios.put(`/api/users/${context.userId}/recipes/${recipeId}/modifiers/${modifierId}`, payload).
			then(s).
			catch(e);	
	};
	deleteRecipeModifier = (context, s, e, recipeId, modifierId, itemId) => {
		this.axios.delete(`/api/users/${context.userId}/recipes/${recipeId}/square-modifiers/${modifierId}/modifiers/${itemId}`).
			then(s).
			catch(e);	
	};
	// end points for receiving stock
	// these are used within the receive stock feature (dialog)
	// see the stock items spage
	getNewReceiveStockDraft = (context, s, e) => { 
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/new`).
			then(s).
			catch(e);	
	};
	createReceiveStockDraft = (context, s, e, payload) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts`, payload).
			then(s).
			catch(e);	
	};
	getReceiveStockDrafts = (context, s, e) => { 
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts`).
			then(s).
			catch(e);	
	};
	getReceiveStockDraftSync = async  (context, draftId) => {
		return await this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}`);
	};
	deleteReceiveStockDraft = (context, s, e, draftId) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}`).
			then(s).
			catch(e);	
	};
	saveReceiveStockDraftItems = (context, s, e, draftId, items) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}/items`, items).
			then(s).
			catch(e);	
	};
	finaliseReceiveStockDraft = (context, s, e, draftId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}/finalise`).
			then(s).
			catch(e);	
	};
	deleteReceiveStockDraftItem = (context, s, e, draftId, itemId) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}/items/${itemId}`).
			then(s).
			catch(e);	
	};
	// end points for taking stock
	// these are used within the take stock feature (dialog)
	// see the stock items spage
	getTakeStockDrafts = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts`).
			then(s).
			catch(e);	
	};
	createTakeStockDraft = (context, s, e, items) => {
		this.axios.post(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts`, items).
			then(s).
			catch(e);	
	};
	updateTakeStockDraft = (context, s, e, draftId, items) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/${draftId}`, items).
			then(s).
			catch(e);	
	};
	deleteTakeStockDraft = (context, s, e, draftId) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/${draftId}`).
			then(s).
			catch(e);	
	};
	getTakeStockDraft = (context, s, e, draftId) => {
		this.axios.get(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/${draftId}`).
			then(s).
			catch(e);	
	};
	finaliseTakeStockDraft = (context, s, e, draftId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/take-stock-drafts/${draftId}/finalise`).
			then(s).
			catch(e);	
	};
	verifyPassword = (context, s, e, payload) => {
		this.axios.post(`/api/users/${context.userId}/verify-password`, payload).
			then(s).
			catch(e);
	};
	updateCredentials = (context, s, e, payload) => {
		this.axios.post(`/api/users/${context.userId}/update-credentials`, payload).
			then(s).
			catch(e);
	};
	removeItemFromReceiveStockDraft = (context, s, e, draftId, itemId) => {
		this.axios.delete(`/api/users/${context.userId}/locations/${context.selectedLocation.id}/receive-stock-drafts/${draftId}/items/${itemId}`).
			then(s).
			catch(e);
	};
	getDataExport = (context, s, e, payload) => {
		this.axios.post(`/api/users/${context.userId}/report/export`, payload).
			then(s).
			catch(e);	
	};
	setMainLocation = (context, s, e, locationId) => {
		this.axios.put(`/api/users/${context.userId}/locations/${locationId}/main-location`).
			then(s).
			catch(e);	
	};
	// TODO oes this need to be here?
	// not sure its used ...
	getUserProfileImageURL =  (context) => {
		return `${process.env.REACT_APP_BACKEND}/api/users/${context.userId}/profile-picture/image`;
	};
	getUserSetupStatus = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/setup`).
			then(s).
			catch(e);	
	};
	getUserSetupStatusSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/setup`);
	};
	addUser = (s, e, payload) => {
		this.axios.post("/api/users", payload).
			then(s).
			catch(e);	
	};
	getUserCount = (s, e, role) => {
		this.axios.get(`/api/roles/${role}/users/count`).
			then(s).
			catch(e);
	};
	getLocationCount = (s, e) => {
		this.axios.get(`/api/locations/count`).
			then(s).
			catch(e);
	};
	getStockItemCount  = (s, e) => {
		this.axios.get(`/api/stock-items/count`).
			then(s).
			catch(e);
	};
	getSalesItemCount = (s, e) => {
		this.axios.get(`/api/sales-items/count`).
			then(s).
			catch(e);
	};
	getLatestTermsOfService = (s, e) => {
		this.axios.get(`/api/admin/site-settings/terms-of-service/latest`).
			then(s).
			catch(e);
	};
	getLatestPrivacyPolicy = (s, e) => {
		this.axios.get(`/api/admin/site-settings/privacy-policy/latest`).
			then(s).
			catch(e);
	};
	addTermsOfService = (s, e, payload) => {
		this.axios.post("/api/admin/site-settings/terms-of-service", payload).
			then(s).
			catch(e);
	};
	addPrivacyPolicy = (s, e, payload) => {
		this.axios.post("/api/admin/site-settings/privacy-policy", payload).
			then(s).
			catch(e);
	}
	getLatestSeoSettings = (s, e) => {
		this.axios.get(`/api/admin/site-settings/seo/latest`).
			then(s).
			catch(e);
	}
	addSeoSettings = (s, e, payload) => {
		this.axios.post("/api/admin/site-settings/seo", payload).
			then(s).
			catch(e);
	}
	getLatestSocialMediaLinks = (s, e) => {
		this.axios.get(`/api/admin/site-settings/social-media/latest`).
			then(s).
			catch(e);
	}
	addSocialMediaLinks = (s, e, payload) => {
		this.axios.post("/api/admin/site-settings/social-media", payload).
			then(s).
			catch(e);
	};
	getLatestLogoName = (s, e) => {
		this.axios.get(`/api/admin/site-settings/logo-name/latest`).
			then(s).
			catch(e);
	}
	addLogoNameSettings = (s, e, payload) => {
		this.axios.post("/api/admin/site-settings/logo-name", payload).
			then(s).
			catch(e);
	};
	getLatestEmailVerificationSettings = (s, e) => {
		this.axios.get(`/api/admin/site-settings/email-verification/latest`).
			then(s).
			catch(e);
	};
	addEmailVerificationSettings = (s, e, payload) => {
		this.axios.post(`/api/admin/site-settings/email-verification`, payload).
			then(s).
			catch(e);
	};
	getPublicLogoURL = () => {
		const backend = process.env.REACT_APP_BACKEND;
		return `${backend}/api/admin/site-settings/logo-name/latest/public/logo`;
	}
	connectToSquare = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/square/connect`).
			then(s).
			catch(e);
	};
	completeAuthorization = (s, e, queryString) => {
		this.axios.get(`/api/square-authorize${queryString}`).
			then(s).
			catch(e);
	};
	getSquareTokenSync = async (context) => {
		return await this.axios.get(`/api/users/${context.userId}/square/token`);
	};
	getSyncJob = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/square/sync/new`).
			then(s).
			catch(e);	
	};
	getSyncJobStatus = (context, s, e, jobId) => {	
		this.axios.get(`/api/users/${context.userId}/square/sync/${jobId}/status`).
			then(s).
			catch(e);	
	};
	getUsers = (s, e) => {
		this.axios.get(`/api/users`).
			then(s).
			catch(e);	
	};
	doForgotPassword = (payload, s, e) => {
		this.axios.post("/api/forgot-password", payload).
			then(s).
			catch(e);	
	};
	doResetPassword = (payload, s, e) => {
		this.axios.post("/api/reset-password", payload).
			then(s).
			catch(e);	
	};
	verifyEmail = (query, s, e) => {
		this.axios.get(`/api/verify-email${query}`).
			then(s).
			catch(e);	
	};
	getAccounts = (s, e) => {
		this.axios.get("/api/admin/square-accounts").
			then(s).
			catch(e);
	};
	getRecentEvents = (s, e, limit) => {
		this.axios.get(`/api/admin/square-events?limit=${limit}`).
			then(s).
			catch(e);
	};
	getLatestSyncJob = (context, s, e) => {
		this.axios.get(`/api/users/${context.userId}/square/sync/latest`)
			.then(s)
			.catch(e);
	};
}
export default InStockClient;
