import React from "react";
import { styled } from "@mui/system";
import { Typography, Button } from "@mui/material";
export const InStockCardContent = styled(Typography)({
	color: "black",
	fontSize: "16px",
	fontWeight: "regular"
});
export const InStockCardHeader = styled(Typography)({
	color: "black",
	fontSize: "18px",
	fontWeight: "bold"
});
export const InStockHeader = styled(Typography)({
	color: "#C12393",
	fontSize: "20px",
	fontWeight: "bold"
});
export const InStockSubHeader = styled(Typography)({
	color: "black",
	fontSize: "18px",
	fontWeight: "regular"
});
export const InStockButton = styled(Button)({
	color: "white",
	background: "linear-gradient(179.98deg, #5E085A 45.9%, #C12393 99.99%)"
});
export const ErrorText = styled(Typography)({
	color: "red",
	fontSize: "12px",
	display: "block" 
});
