import React from "react";
import { Remove } from "@mui/icons-material";
import { IconButton } from "@mui/material";
const RemoveButton = (props) => {
	return (
		<span>
			<IconButton sx={{mr:"5px"}} onClick={props.onClick}>
				<Remove sx={{color:"#C12393", fontSize:"1.5rem", verticalAlign:"middle"}}/>
			</IconButton>
		</span>
	);
};
export default RemoveButton;
