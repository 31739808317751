// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Stack, List, ListItem, ListItemText, IconButton, Link, Button, Grid } from "@mui/material";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import { Delete, Info } from "@mui/icons-material";

const DraftSelectionDialog = (props) => {

	const context = useContext(UserContext);
	const drafts = props.drafts === null || props.drafts === undefined ? [] : props.drafts;
	const mapper = (e,i) => {
		const d = new Date(Date.parse(e.created_at));
		return (
			<ListItem key={e.id}>
				<ListItemText sx={{cursor:"pointer"}}>
					<Link onClick={(event)=>{props.onResumeDraftClicked(event, e.id)}}>
						{`Draft created ${d.toLocaleDateString()} ${d.toLocaleTimeString()}`}
					</Link>
				</ListItemText>
				<IconButton onClick={(event)=>{props.onDeleteDraftClicked(event, e.id)}}>
					<Delete sx={{color:"#C12393"}}/>
				</IconButton>
			</ListItem>
		);
	};
	let items = null;
	if(drafts.length > 0){
		items = (
			<Stack spacing={2}>
				<Typography sx={{color:"black", fontSize:"18px", fontWeight:"bold", textAlign:"center"}}>
					You have one or more drafts in progress. Would you like to continue one of those?	
				</Typography>
				<List>
					{drafts.map(mapper)}	
				</List>
			</Stack>
		);
	}
	if(drafts.length === 0){
		items = (
			<Box sx={{padding:"15px", border:"1px solid #CDCDCD", backgroundColor:"#E6E6E6", textAlign:"center", borderRadius:"15px", mb:"15px"}}>
				<Typography sx={{color:"black", fontSize:"16px", fontWeight:"bold"}}>
					<Info sx={{verticalAlign:"middle", mr:"10px"}}/> No drafts exist, please use the new button to create one.
				</Typography>
			</Box>	
		);
	}
	return (
		<InStockDialog title={"Resume Draft"} onCloseClicked={props.onCloseClicked}>
				{items}
				<Grid container>
					<Grid item xs={12} lg={4}/>
					<Grid item xs={12} lg={4}>
					<Button variant={"contained"} fullWidth className="pg" size="large" onClick={(event)=>{props.onNewDraftClicked(event)}}>
						New	
					</Button>
					</Grid>
				</Grid>
		</InStockDialog>
	);
};

export default DraftSelectionDialog;
