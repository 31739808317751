// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Stack, TextField } from "@mui/material";
import RemoveButton from "./../RemoveButton/RemoveButton";
import AddButton from "./../AddButton/AddButton";
import UserContext from "./../../Context/UserContext";
const StockCounter = (props) => {
	// context
	const context = useContext(UserContext);
	// state
	const [absoluteQuantity, setAbsoluteQuantity] = useState(props.absoluteQuantity);
	const [unitQuantity, setUnitQuantity] = useState(props.unitQuantity);
	const [stockItemId, setStockItemId] = useState(props.id);
	const [standardQuantity, setStandardQuantity] = useState(props.standardQuantity);
	// effects
	useEffect(()=>{
		if(stockItemId !== props.id){
			setStockItemId(props.id);
			setAbsoluteQuantity(props.absoluteQuantity);
			setUnitQuantity(props.unitQuantity);
			setStandardQuantity(props.standardQuantity);
		}
	});
	// event handlers	
	const onAbsoluteQuantityChanged = (quantity) => {
		const value = Number(quantity); // mui fields are text fields, value is a string ...
		const unitQuantity = (value/standardQuantity).toFixed(2);
		// item might not exist if this StockCounter is used for something else 
		// other than a stock take
		if(context.takeStockItems !== undefined){
			const item = context.takeStockItems[`${stockItemId}`];
			item.actual_absolute_quantity = value;
			item.actual_unit_quantity = unitQuantity;
		}
		setAbsoluteQuantity(value);
		setUnitQuantity(unitQuantity);
	};
	const onUnitQuantityChanged = (units) => {
		const value = Number(units);
		const quantity = Math.floor(value * standardQuantity);
		// see above
		if(context.takeStockItems !== undefined){
		const item = context.takeStockItems[`${stockItemId}`];
			item.actual_unit_quantity = value;
			item.actual_absolute_quantity = quantity;
		}
		setUnitQuantity(value);
		setAbsoluteQuantity(quantity);
	};
	const decUnitQuantity = () => {
		let value = Number(unitQuantity);
		if(value - 1 < 0){
			toast.info("Quantity must be at least one.");
			return;
		}
		value -= 1;
		onUnitQuantityChanged(value);
	};
	const incUnitQuantity = () => {
		let value = Number(unitQuantity);
		value += 1;
		onUnitQuantityChanged(value);
	};
	const incAbsoluteQuantity = () => {
		let value = Number(absoluteQuantity);
		value += 1;
		onAbsoluteQuantityChanged(value);
	};
	const decAbsoluteQuantity = () => {
		let value = Number(absoluteQuantity);
		if(value - 1 < 0){
			toast.info("Quantity must be at least one.");
			return;
		}
		value -= 1;
		onAbsoluteQuantityChanged(value);
	};
	const absolute = (
		<span>
			<RemoveButton onClick={decAbsoluteQuantity}/>
			<TextField 
				value={absoluteQuantity} 
				size={"small"} 
				onBlur={(event)=>{onAbsoluteQuantityChanged(absoluteQuantity);}}
				onChange={(event)=>{setAbsoluteQuantity(event.target.value);}} 
				label={`quantity (${props.units})`} />
			<AddButton onClick={incAbsoluteQuantity}/>
		</span>	
	);
	const units = (
		<span>
			<RemoveButton onClick={decUnitQuantity}/>
			<TextField 
				value={unitQuantity} 
				size={"small"} 
				onBlur={(event)=>{onUnitQuantityChanged(unitQuantity);}}
				onChange={(event)=>{setUnitQuantity(event.target.value);}} 
				label={`quantity (units)`} />
			<AddButton onClick={incUnitQuantity}/>
		</span>	
	);
	return (
		<Stack spacing={2}>
			{absolute}
			{units}
		</Stack>	
	);
};
export default StockCounter;
