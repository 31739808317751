import React from "react";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { Box } from "@mui/material";
const LoadingDialog = (props) => {
	return (
		<Box sx={{backgroundColor:"white", padding:"50px"}}>
			<LoadingSpinner message={props.message} height={"100px"}/>			
		</Box>	
	);
};
export default LoadingDialog;
