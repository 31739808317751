import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "./../Layouts/AdminLayout";
import UserContext from "./../Context/UserContext";
import SiteSettingsView from "./../Components/Settings/SiteSettingsView";
import StripeSettingsView from "./../Components/Settings/StripeSettingsView";
import EmailSettingsView from "./../Components/Settings/EmailSettingsView";
import { Tabs, Tab, Box, Stack, Container, Paper, Grid } from "@mui/material";
const AdminSettingsPage = (props) => {
	const [tab, setTab] = useState(0);
	const getContent = () => {
		switch(tab){
			case 0:
				return <SiteSettingsView />;
			case 1:
				return <StripeSettingsView />;
			case 2:
				return <EmailSettingsView />;
		}
	};
	return (
		<AdminLayout>
			<Box sx={{padding:"15px"}}> 
			<Grid container>
				<Grid item lg={12} sm={12}>
			<Paper elevation={3} sx={{padding:"15px"}}>
			<Stack spacing={2}>
				<Box>
					<Tabs value={tab} onChange={(event, value) => {setTab(value);}}>	
						<Tab label="Site Settings" />
						<Tab label="Stripe Settings" />
						<Tab label="Email Settings" />
					</Tabs>
				</Box>
				<Box>
					{getContent()}	
				</Box>
			</Stack>
			</Paper>
			</Grid>
			</Grid>
			</Box>
		</AdminLayout>	
	);
};
export default AdminSettingsPage;
