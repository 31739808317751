import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
const validator = require("validate.js");
const TableToolsDialog = (props) => {
	const context = useContext(UserContext);
	// fields
	const [searchPhrase, setSearchPhrase] = useState("");
	const [filter, setFilter] = useState("");
	const [values, setValues] = useState(null);
	const [filterValue, setFilterValue] = useState("");
	// data
	// other state
	// effects
	// load all data at once ...
	// locations, categories ...
	const clear = () => {
		setSearchPhrase("");
		setValues(null);
		setFilterValue("");
		setFilter("");
		props.onSearchChanged("");
		props.onFilterValueChanged("", "");
	};
	const onFilterChanged = (event) => {
		const v = event.target.value;
		const filters = props.filters;
		const values = filters[v];
		setFilterValue("");
		setValues(values);
		setFilter(v);
	};
	const onFilterValueChanged = (event) => {
		const v = event.target.value;
		setFilterValue(v);
		props.onFilterValueChanged(filter, v); // include name of filter in event, category => retail ...
	}; 
	const filters = props.filters;
	let options = Object.keys(filters);
	options = options.map((e,i)=>{ return <MenuItem key={`value_${i}`} value={e}>{e}</MenuItem> });
	let filterOptions = null;
	if(values !== null){
		filterOptions = (
			<TextField 
				select
				value={filterValue} 
				fullWidth 
				size={"normal"}
				onChange={onFilterValueChanged} 
				label={`Select a ${filter}`} 
				helperText={`Select a ${filter} to filter by ...`}>
					{values.map((e,i)=>{ return <MenuItem key={`filter_value_${i}`} value={e}>{e}</MenuItem>})}
			</TextField>
		);	
	}
	return (
		<InStockDialog title={"Search / Filter"} onCloseClicked={props.onCloseClicked}>
			<Stack spacing={3}>
				<TextField 
					onBlur={(event)=>{props.onSearchChanged(searchPhrase);}}
					value={searchPhrase} 
					fullWidth 
					size={"normal"}
					onChange={(event)=>{setSearchPhrase(event.target.value);}} 
					label="Search" 
					helperText={"Search by name of product ..."} />
				<TextField 
						select
						value={filter} 
						fullWidth 
						size={"normal"}
						onChange={onFilterChanged} 
						label="Filter" 
						helperText={"Select a filter to use ..."}>
					{options}
				</TextField>
				{filterOptions}
				<Button size="large" sx={{color:"white"}} className="pg" fullWidth onClick={clear}>Clear</Button>
			</Stack>
		</InStockDialog>
	);
};
export default TableToolsDialog;
