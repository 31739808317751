// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import UserContext from "./../../../Context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import BinIcon from "@mui/icons-material/Delete";
import { InStockHeader, InStockSubHeader } from "./../../Styled";
import { Box, Container, Stack, Typography, IconButton, Grid, ListItem, ListItemText, TextField, InputAdornment, Paper } from "@mui/material";
const validator = require("validate.js");
const StocktakingLocations = (props) => {
	const [stockLocations, setStockLocations] = useState(null);
	const [stockLocation, setStockLocation] = useState(null)
	const [errors, setErrors] = useState(undefined);
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const loading = stockLocations === null;	
	const handleDeleteItem = (itemId) => {
		const s = (response) => {
			const data = response.data;
			toast.success("Success, stock taking location deleted.");
			setStockLocation("");
			setStockLocations(null);
			if(props.onStocktakingLocationDeleted !== undefined){
				props.onStocktakingLocationDeleted(data);
			}
		};
		const e = (error) => {
			toast.error("Failed to delete category, please contact support.");
		};		
		if(!impersonate){
			context.api.deleteStocktakingLocation(context, s, e, itemId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stocktaking-locations/${itemId}`,
      "method": "DELETE"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	useEffect(()=>{
		if(stockLocations !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setStockLocations(data);
		};
		const e = (error) => {
			toast.error("Failed to load stock taking locations, please contact support.");
		};
		if(!impersonate){
			context.api.getStocktakingLocations(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stocktaking-locations`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const addStocktakingLocation = () => {
		const constraints = {
			name: { presence: { allowEmpty: false }, length: { maximum: 255 } }
		};
		const data = {
			"name": stockLocation,
			"location_id": context.selectedLocation.id
		};
		setErrors(undefined);
		const errors = validator.validate(data, constraints);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const s = (response) => {
			const data = response.data;
			toast.success("Success, stock taking location added.");
			setStockLocation("");
			setStockLocations(null);
			if(props.onStocktakingLocationAdded !== undefined){
				props.onStocktakingLocationAdded(data);
			}
		};
		const e = (error) => {
			if(error.status === 400){
				const e = error.response.data;
				const msg = e.message;
				toast.error(msg);
				return;
			}
			toast.error("Failed to add stock taking location, please contact support.");
		};
		if(!impersonate){
			context.api.addStocktakingLocation(context, s, e, data);	
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stocktaking-locations`,
      "method": "POST",
			"data": JSON.stringify(data)
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	let content = <LoadingSpinner />;
	if(!loading){
		if(stockLocations.length === 0){
			content = (
				<Box sx={{textAlign:"center"}}>
					<InStockSubHeader>
						No categories exist, please add one below.
					</InStockSubHeader>
				</Box>
			);
		}
		if(stockLocations.length > 0){
			const items = stockLocations.map((e)=>{ return <ListItem sx={{borderBottom:"2px solid black"}} key={e.id} 
					secondaryAction={<IconButton onClick={(event)=>{handleDeleteItem(e.id)}}edge="end" aria-label="delete" sx={{color:"black"}}><BinIcon/></IconButton>}>
				<ListItemText primary={e.name} />
			</ListItem>});
			content = (
				<Stack spacing={3}>
					{items}
				</Stack>
			);
		}
	}
	return (
		<Paper className="instock-view" elevation={3}>
			<Stack spacing={2}>
				<InStockHeader sx={{textAlign:"center"}}>Stocktaking Locations</InStockHeader>
				{content}
          <TextField
						variant={"filled"}
						error={errors !== undefined && errors !== null && errors.name !== undefined}
						helperText={errors !== undefined && errors !== null ? errors.name : ""}
						label="Create Stocktaking Location"
            id="create-stock-location"
            type={"text"}
            value={stockLocation}
            onChange={(event)=>{setStockLocation(event.target.value)}}
            InputProps={{"endAdornment":
              <InputAdornment position="end">
                <IconButton
                  aria-label="add location"
                  onClick={addStocktakingLocation}
                  edge="end"
                >
									<AddIcon/>
                </IconButton>
              </InputAdornment>
            }}
          />
			</Stack>
		</Paper>
	);
};
export default StocktakingLocations;
