// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import DefaultLayout from "./../Layouts/DefaultLayout";
import UserContext from "./../Context/UserContext";
import SetupView from "./../Components/Settings/SetupView";
import StockView from "./../Components/Settings/StockView";
import LocationsView from "./../Components/Settings/LocationsView";
import SynchronisationView from "./../Components/Settings/SynchronisationView";
import { Tabs, Tab, Box, Stack, Container } from "@mui/material";
const SettingsPage = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [tab, setTab] = useState(0);
	const onTabClicked = (event, tab) => {
		// user cannot change tab until setup is complete
		if(!context.setupComplete && tab > 0){
			toast.info("Please connect and synchronise your square account first."); 
			event.stopPropagation();
			return;
		}
		setTab(tab);
	};
	const getContent = () => {
		switch(tab){
			case 0:
				return <SetupView impersonate={impersonate} />;
			case 1:
				return <StockView impersonate={impersonate} />;
			case 2:
				return <LocationsView impersonate={impersonate} />;
			case 3:
				return <SynchronisationView impersonate={impersonate} />;
		}
	};
	const content = (
		<Container maxWidth="lg" sx={{mt:"25px"}}>
			<Stack spacing={2}>
				<Box>
					<Tabs value={tab} onChange={onTabClicked}>
						<Tab label="Setup" />
						<Tab label="Stock Item Settings" />
						<Tab label="Locations" />
						<Tab label="Square Synchronisation" />
					</Tabs>
				</Box>
				<Box>
					{getContent()}	
				</Box>
			</Stack>
		</Container>
	);
	if(impersonate){
		return content;	
	}
	return (
		<DefaultLayout>
			{content}
		</DefaultLayout>	
	);
};
export default SettingsPage;
