import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Paper, Typography, Container, Box, Stack, TextField, MenuItem, Menu, IconButton, Grid, TablePagination } from "@mui/material";
import { AccessTime, Download } from "@mui/icons-material";
import DataTable from "./../DataTable/DataTable";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import UserContext from "./../../Context/UserContext";
import Dialogs from "./../../Containers/Dialogs/Dialogs";
const InventoryTransactions = (props) => {
	// context
	const context = useContext(UserContext);
	// state
	const [page, setPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [transactions, setTransactions] = useState(null);	
	const [itemId, setItemId] = useState(null);
	const [item, setItem] = useState(null);
	const [sort, setSort] = useState(null);
	const [selectedHeader, setSelectedHeader] = useState(null);
	const [filterTerm, setFilterTerm] = useState(null);
	const [fileToDownload, setFileToDownload] = useState(null);
	const [openDownload, setOpenDownload] = useState(false);
	// events
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	const exportData = () => {
		const s = (response) => {
			toast.success("Export generated");
			const data = response.data; // base 64 encoded
			setFileToDownload(data);
			setOpenDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate report, please contact support.");
			setOpenDownload(false);
			setFileToDownload(null);
		};
		const units = item.units;
		const stockUnit = item.quantity_description;
		const standardQuantity = item.standard_quantity;
		const mapper = (e, i) => {
			const date = new Date(Date.parse(e.created_at));
			return {
				"created_at": `${date.toLocaleDateString()}T${date.toLocaleTimeString()}`,
				"type": e.type,
				"quantity": e.quantity >= 0 ? `+ ${e.quantity}${units}` : `${e.quantity}${units}`,
				"quantity_remaining": `${e.quantity_remaining}${units}`,
				"stock_remaining": `${(e.quantity_remaining/(standardQuantity*1.0))}`
			}		
		};
		const payload = {
			"headers": ["created_at", "type", "quantity", "quantity_remaining", "stock_remaining"],
			"records": transactions.map(mapper)
		};
		context.api.getDataExport(context, s, e, payload);	
	};
	const getUniqueTransactionTypes = () => {
		const mapper = (e, i) => {
			return e.type;	
		};
		const reducer = (a, c) => {
			if(a[c] === undefined){
				a[c] = 1;
				return a;
			}
			return a;
		};
		const result = context.transactions.map(mapper).reduce(reducer, {});	
		const unique = Object.keys(result);
		return [...unique, "None"];
	};
	const onFilterValueChanged = (filter, value) => {
		setFilterTerm(value);
		if(filter === "" || value === "None" || value === ""){
			setTransactions([...context.transactions]);
			return;
		}
		// filters don't directly map to fields
		// need to use a function to get the value from the data object
		// add to this map to implement new filters
		const mapping = {
			"Type": (item, value) => { return item.type  === value },
		};	
		const f = mapping[filter];
		const matches = context.transactions.filter((x)=>{ return f(x, value) });
		setTransactions(matches);
	};
	const onHeaderClicked = (event, headerName) => {
		const config = {
			searchable: [],
			filterable: ["Type"]
		};
		context.menuAnchor = event.target;
		const searching = config.searchable.find((x)=>{ return x === headerName });
		if(searching !== undefined){
			context.searchingTable = true;
			context.filteringTable = false;
			setSelectedHeader(headerName);
			return;
		}
		const filtering = config.filterable.find((x)=>{ return x === headerName });
		if(filtering !== undefined){
			context.filteringTable = true;
			context.searchingTable = false;
			setSelectedHeader(headerName);
			return;
		}
		context.filteringTable = false;
		context.searchingTable = false;
		setSelectedHeader(null);
	};
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const comparators = {
			"Date/Time": ( a, b ) => { 
				const l = Date.parse(a.created_at);
				const r = Date.parse(b.created_at);
				if(s.mode === "asc"){
					if(l < r){
						return -1;
					}else if(l === r){
						return 0;
					}else{
						return 1;
					}
				}
				if(s.mode === "desc"){
					if(l < r){
						return 1;
					}else if(l === r){
						return 0;
					}else{
						return -1;
					}
				}
			}
		};	
		const copy = [...context.transactions];
		copy.sort(comparators[headerName]);
		setTransactions(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	// effect
	useEffect(()=>{
		if(itemId !== null){
			return;
		}
		const s = {
			"Date/Time": { sorted: false, mode: "asc" }
		};
		const trx = props.transactions.map((e,i)=>{ return { ...e, key: e.id } });
		context.transactions = trx;
		setSort(s);
		setTransactions(trx);
		setItemId(props.itemId);
		setItem(props.item);
	});
	useEffect(()=>{
		if(props.itemId !== itemId && itemId !== null){
			context.transactions = [...props.transactions];
			setTransactions(props.transactions);
			setItemId(props.itemId);
			setItem(props.item);
		}
	});
	if(transactions === null){
		return ( 
			<Paper elevation={3} sx={{padding:"25px"}}>
				<Typography sx={{color:"#C12393", fontSize:"25px", fontWeight:"bold"}}>Inventory Transactions</Typography>
				<LoadingSpinner messsage="Loading transactions ..." />
			</Paper>	
		);	
	}
	// start rendering ...
	const units = item.units;
	const stockUnit = item.quantity_description;
	const standardQuantity = item.standard_quantity;
	// render helper methods
	const getTimestamp = (params) => {
		const date = new Date(Date.parse(params.row.created_at));
		return (
			<Box>
				<Typography sx={{fontSize:"inherit", color:"black"}}>
					{date.toLocaleDateString()}
				</Typography>			
				<Typography sx={{fontSize:"inherit", color:"black"}}>
					<span style={{verticalAlign:"middle"}}>
						<AccessTime sx={{color:"black", fontSize:"1rem", marginRight:"5px"}}/>
					</span>
					{date.toLocaleTimeString()}
				</Typography>			
			</Box>
		);
	};
	const fields = [
		{ field: "created_at",  headerName: "Date/Time", valueGetter: getTimestamp },
		{ field: "type", headerName: "Type", valueGetter: (params)=>{ return params.row.type } },
		{ field: "quantity", headerName: "Adjustment", valueGetter: (params)=>{ return params.row.quantity >= 0 ? `+ ${params.row.quantity} ${units}` : `${params.row.quantity} ${units}`} },
	  { field: "quantity_remaining", headerName: `Quantity Remaining (${units})`, valueGetter: (params) => { return `${params.row.quantity_remaining} ${units}` } },
		{ field: "quantity_remaining_bottles", headerName: `Stock Remaining (${stockUnit})`, valueGetter: (params)=>{ return `${(params.row.quantity_remaining/(standardQuantity*1.0))}`}} 
	];
	let content = (
		<Container maxWidth="md" sx={{marginTop:"15px"}}>
			<Box sx={{textAlign:"center", backgroundColor:"#DEDEDE", padding:"25px", borderRadius:"15px"}}>
				<Typography sx={{color:"black", fontSize:"20px", fontWeight:"bold"}}>
					No transactions found.
				</Typography>
			</Box>			
		</Container>
	);
	if(transactions.length > 0){
		let menuAction = null;
		const offset = page * itemsPerPage;
		let paginated = transactions.slice(offset, offset + itemsPerPage);
		const filters = {
			"Type": getUniqueTransactionTypes()
		};
		if(context.filteringTable){
			menuAction = (	
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD", width:"200px"}}>
					<TextField select 
							fullWidth
							value={filterTerm} 
							label="Filter by ..." 
							size="small" 
							onChange={(event)=>{onFilterValueChanged(selectedHeader, event.target.value);}}>
						{filters[selectedHeader].map((e,i)=>{ return <MenuItem key={e} value={e}>{e}</MenuItem> })}
					</TextField>
				</Box>
			);
		}
		content = (
			<Box>
				<Menu open={selectedHeader !== null} 
						anchorEl={context.menuAnchor} 
						id="search-menu" 
						onClose={(event)=>{ context.filteringTable=false; context.searchingTable=false; setSelectedHeader(null); }}>
					{menuAction}
				</Menu>
				<Grid container>
					<Grid item lg={12} sm={12}>
						<DataTable rows={paginated} 
							columns={fields} 
							onRowClicked={()=>{}} 
							onHeaderClicked={onHeaderClicked} 
							sort={sort} 
							onSortClicked={onSortClicked} 
							filters={filters} />
					</Grid>
					<Grid item lg={12} sm={12}>
						<Grid container>
							<Grid item lg={8} sm={12}/>
							<Grid item lg={4} sm={12}>
								<TablePagination component={Box} 
									count={transactions.length} 
									onPageChange={onPageChanged} 
									page={page} 
									rowsPerPage={itemsPerPage} 
									onRowsPerPageChange={(event)=>{setItemsPerPage(event.target.value);}} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	}
	return (
		<Dialogs download={openDownload} fileToDownload={fileToDownload} onDownloadClosed={()=>{setOpenDownload(false);}}>
			<Paper elevation={3} sx={{padding:"25px"}}>
			<Grid container>
				<Grid item sm={10}>
					<Typography sx={{color:"#C12393", fontSize:"25px", fontWeight:"bold"}}>Inventory Transactions</Typography>
				</Grid>
				<Grid item sm={2} sx={{textAlign:"right"}}>
					<IconButton onClick={exportData}>
						<Download sx={{color:"black", fontSize:"2rem"}}/>
					</IconButton>	
				</Grid>
			</Grid>
			{content}
		</Paper>	
		</Dialogs>
	);
};
export default InventoryTransactions;
