import React, {useContext} from "react";
import Authorization from "./../../Context/UserContext";
const UserIsLoggedIn = (props) => {
	const context = useContext(Authorization);
	if(context.token === null || context.token === undefined){
		document.location = "/";
		return;
	}
	return (
		<>
			{props.children}
		</>
	);
};
export default UserIsLoggedIn;
