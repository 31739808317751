// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { Route, BrowserRouter, Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { Dialog, DialogContent, Container, Typography, Stack, Box, Button, Grid, TablePagination, Paper, IconButton, Menu, MenuItem, TextField, Tooltip, Fab } from "@mui/material";
import { Info } from "@mui/icons-material";
import UserContext from "./../../Context/UserContext";
import StockPage from "./../../Pages/StockPage";
import SalesPage from "./../../Pages/SalesPage";
import SalesItemPage from "./../../Pages/SalesItemPage";
import StockItemPage from "./../../Pages/StockItemPage";
import SettingsPage from "./../../Pages/SettingsPage";
import DefaultLayout from "./../../Layouts/DefaultLayout";
const ImpersonateView = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const route = params.page;
	const itemId = params.itemId;
	const subpage = params.subpage;
	const variationId = params.variationId;
	let pages = {
		"stock-items": <StockPage impersonate={true} />,
		"settings": <SettingsPage impersonate={true} />,
		"sales-items":  <SalesPage impersonate={true} />
	};
	let content = null;
	if(itemId !== undefined){
		// viewing an item from a certain page
		// viewing a sales item, or a stock item
		if(subpage === undefined){
			pages = {
				"sales-items": <SalesItemPage impersonate={true} />,
				"stock-items": <StockItemPage impersonate={true} />
			};
			content = pages[route];
		}else{
			// viewing a sub page 
			// viewing an item variation of a sales item
			pages = {
				"sales-items": {
					"variations": <SalesItemPage impersonate={true} variation={true} />
				}
			};
			content = pages[route][subpage];
		}
	}else{
		// viewing a base page
		// settings, sales items or stock items
		content = pages[route];
	}
	return (
		<Dialog open={true} maxWidth={"xl"} fullWidth onClose={()=>{}}>
			<DialogContent sx={{backgroundColor:"white", padding:"0px"}}>
				<Tooltip title={"You're viewing another persons account, you can only view and not perform any actions. To return to the accounts page, click this button."}>
					<Fab onClick={()=>{navigate("/accounts");}}variant={"large"} sx={{position:"fixed", top:"20px", right:"50px", backgroundColor:"white"}}>
						<Info color={"black"} sx={{height:"30px", width:"auto"}}/>
					</Fab>
				</Tooltip>
				<DefaultLayout impersonate={true} onDraftFinalised={()=>{}} onLocationChanged={()=>{}}>
					{content !== null ? content : <p>{`Route ${route} not handled, item id was ${itemId}`}</p>}
				</DefaultLayout>
			</DialogContent>	
		</Dialog>	
	);
};
export default ImpersonateView;
