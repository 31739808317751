import React from "react";
import { Grid, Stack, Typography, Container, Paper } from "@mui/material";
const DataCard = (props) => {
	const data = props.data === undefined ? { "data": undefined } : props.data;
	const mapper = (e,i) => {
		const label = e.split("_").map((e,i)=>{ return `${e[0].toUpperCase()}${e.substr(1)}`}).join(" ");
		return (
			<Grid item lg={6} xs={12} sm={12}>
				<Typography sx={{color:"black", fontSize:"18px", fontWeight:"bold"}}>{label}</Typography>
				<Typography sx={{color:"black", fontSize:"18px", fontWeight:"regular"}}>{data[e]}</Typography>
			</Grid>	
		);	
	};
	const cells = Object.keys(data).map(mapper);
	return (
		<Paper elevation={3} sx={{padding:"25px"}}>
			<Typography sx={{color:"#5E085A", fontSize:"20px", fontWeight:"regular"}}>{props.header}</Typography>
			<Container maxWidth="md">
				<Grid container spacing={2}>
					{cells}			
				</Grid>	
			</Container>
		</Paper>
	);
};
export default DataCard;
