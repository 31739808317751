import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import UserContext from "./../../Context/UserContext";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import LocationToggle from "./LocationToggle/LocationToggle";
import { Container, Box, Stack, Grid } from "@mui/material";
const LocationsView = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [locations, setLocations] = useState(null);
	useEffect(()=>{
		if(locations !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setLocations(data);
		};
		const e = (error) => {
			toast.error("Failed to get locations, please contact support.");
		};
		if(!impersonate){
			context.api.getLocations(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const loading = locations === null;
	let content = <LoadingSpinner />;
	if(!loading){
		const mapper = (e, i) => {
			return (
				<Grid item lg={4} sm={12}>
					<Box sx={{padding:"10px"}}>
						<LocationToggle impersonate={impersonate} key={e.id} location={e} onMainLocationChanged={()=>{setLocations(null);}}/>
					</Box>
				</Grid>
			);	
		};
		content = (
			<Grid container>
				{locations.map(mapper)}			
			</Grid>
		);
	}
	return (
		<Container maxWidth="medium" fullWidth className="instock-view">
			{content}
		</Container>	
	);
}
export default LocationsView;
