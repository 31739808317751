import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Divider, Stack, Paper } from "@mui/material";
const NewStockWidget = (props) => {
	const stock = props.stock;
	const limit = props.limit === undefined ? 1 : props.limit; // number of days for stock to be classified as new
	const filtered = stock.filter((x)=>{ return x.age_in_days <= limit });
	const listItems = filtered.map((e,i)=>{ return <ListItem key={e.id}><ListItemText primary={e.name} secondary={`${e.age_in_days} days old - Created ${new Date(Date.parse(e.created_at)).toLocaleDateString()}`}/></ListItem>});
	let list = <Typography variant={"body1"} sx={{fontSize:"18px", fontWeight:"regular"}}>No stock items were created within {limit} days.</Typography>;
	// do any items exist?
	if(listItems.length > 0){
		// do we need dividers ?
		if(listItems.length > 1){
			for(let i = 0; i < filtered.length; i++){
				const j = i * 2 + 1;
				listItems.splice(j,0,<Divider key={`divider_${j}`} component="li" />);
			}
		}
		list = (
			<List>
				{listItems}
			</List>	
		);
	}
	return (
		<Paper elevation={3}>
			<Box sx={{backgroundColor:"inherit", padding:"25px", maxHeight:"400px", overflowY:"scroll"}}>
				<Stack spacing={2}>
					<Typography variant="h6" sx={{color:"black", fontWeight:"bold", fontSize:"25px", color:"#C12393"}}>New Stock Items</Typography>
					<Typography variant="h5" sx={{color:"black", fontWeight:"regular", fontSize:"20px", color:"black"}}>Stock Items Created - {limit} Days</Typography>
					{list}
				</Stack>
			</Box>	
		</Paper>
	);
};
export default NewStockWidget;
