import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import StocktakingLocations from "./../Settings/StocktakingLocations/StocktakingLocations";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
const validator = require("validate.js");
const AddStocktakingLocationDialog = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	return (
		<InStockDialog title={"Add Stocktaking Location"} onCloseClicked={props.onCloseClicked}>
			<StocktakingLocations impersonate={impersonate} onStocktakingLocationAdded={props.onStocktakingLocationAdded} onStocktakingLocationDeleted={props.onStocktakingLocationDeleted} />
		</InStockDialog>
	);
};
export default AddStocktakingLocationDialog;
