import React from "react";
import { Paper } from "@mui/material";
import DataCard from "./../DataCard/DataCard";
import Tag from "./../Tag/Tag";
const StockItemDetails = (props) => {
	const item = props.item;
	const name = item.name;
	const category = item.category.name; 
	const uom = item.units_of_measure;
	const units = item.units;
	const quantity = item.quantity;
	const description = item.quantity_description;
	const availableQuantity = item.available_quantity;
	const standardQuantity = item.standard_quantity;
	const quantityAlert = item.quantity_alert;
	const data = {
		name: name,
		category: category,
		unit_of_measure: units === "units" ? "units" : `${uom} (${units})`,
		standard_quantity: units === "units" ? `${standardQuantity} unit(s)` : `${standardQuantity}${units}`,
		stockkeeping_unit_description: description,
		rolling_usage_7_days: item.rolling_usage_7_days,
		quantity_on_hand: `${(availableQuantity/(standardQuantity*1.0)).toFixed(2)} ${description}(s)`,
		stock_alert_level: `${(quantityAlert/(standardQuantity*1.0)).toFixed(2)} ${description}(s)`,
		stock_keeping_locations: item.stocktaking_tags.map((e,i)=>{ return <Tag disabled={true} value={e} key={`location_${i}`}/>})
	};
	return (
		<DataCard data={data} header={"Details"}/>
	);
};
export default StockItemDetails;
