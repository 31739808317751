// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"; 
import { toast } from "react-toastify";
import { Container, Typography, Stack, Box, Switch, IconButton, Paper, Grid, FormGroup, FormControlLabel } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material"; 
import { Check } from "@mui/icons-material"; 
import DataTable from "./../Components/DataTable/DataTable";
import DefaultLayout from "./../Layouts/DefaultLayout";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import DataCard from "./../Components/DataCard/DataCard";
import UserContext from "./../Context/UserContext";
import { RecipeCard, IngredientCard } from "./../Components/Recipe/Recipe";
import RecipeModifiers from "./../Components/RecipeModifiers/RecipeModifiers";
const SalesItemPage = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [item, setItem] = useState(context.selectedItem); // grab currently selected item from context
	const [itemVariation, setItemVariation] = useState(context.selectedVariation);
	const navigate = useNavigate();
	const viewVariation = props.variation; // is this a variation or parent item?
	const getPrice = () => {
		let costDollars = null;
		if(!viewVariation){
			costDollars = item.variations[0].variation.price_amount / 100.0;
		}else{
			costDollars = itemVariation.variation.price_amount / 100.0;
		}
		return costDollars.toFixed(2);
	};
	const getCurrency = () => {
		return viewVariation ? itemVariation.variation.price_currency : item.variations[0].variation.price_currency;	
	};	
	const getSalesCount = () => {
		return viewVariation ? itemVariation.sales_count : item.variations[0].sales_count;
	};
	const getItemData = () => {
		return {
			category: item.category_name,
			price: `\$${getPrice()} ${getCurrency()}`,
			SKU: "N/A",
			units: "Each",
			total_sales_count: getSalesCount()
		};
	};
	const costDollars = getPrice();
	const currency = getCurrency(); 
	const itemData = getItemData();
	const back = impersonate ? "/accounts/impersonate/sales-items" : "/sales-items";
	const content = (
		<Box sx={{padding:"15px"}}>
			<Stack spacing={2}>
				<Box sx={{display:"inline-block"}}>
					<IconButton onClick={()=>{navigate(back);}}>
						<KeyboardArrowLeft sx={{color:"black", height:"24px", width:"auto"}}/>
					</IconButton>
					<span style={{verticalAlign:"middle", color:"black"}}>Back to Sales items</span>
				</Box>
				<Typography variant="h4" sx={{color:"black", fontSize:"25px", fontWeight:"bold"}}>
					{viewVariation ? `${item.name} - ${itemVariation.variation.name}`: item.name}
				</Typography>
				<Paper sx={{padding:"25px"}} elevation={2}>
					<Stack spacing={1}>
						<DataCard data={itemData} header={"Details"} />
						<RecipeCard impersonate={impersonate} recipe={viewVariation ? itemVariation.recipe : item.recipe} />
						<Box sx={{paddingTop:"18px"}}>
							<Typography sx={{color:"#5E085A", fontSize:"20px"}}>MODIFIERS</Typography>	
							<ul>
								<li>
									<Typography sx={{color:"black", fontSize:"18px"}}>Please click on a modifier to configure it.</Typography>
								</li>
								<li>	
									<Typography sx={{color:"black", fontSize:"18px"}}>Modifiers that are configured will be coloured 
										<span style={{verticalAlign:"middle", fontWeight:"bold", color:"green"}}>&nbsp;GREEN</span>
									</Typography>
								</li>	
								<li>
									<Typography sx={{color:"black", fontSize:"18px"}}>Only modifiers that are configured will be considered when processing orders.</Typography>
								</li>	
							</ul>
							<RecipeModifiers impersonate={impersonate} debug={false} recipe={viewVariation ? itemVariation.recipe : item.recipe} catalogItemId={item.catalog_item_id} />
						</Box>
					</Stack>
				</Paper>
			</Stack>
		</Box>
	);
	if(impersonate){
		return content;
	}
	return ( 
		<DefaultLayout>
			{content}
		</DefaultLayout>	
	);
};
export default React.memo(SalesItemPage);
