import React, { useState, useContext, useEffect } from "react";
import { Add, Remove } from "@mui/icons-material";
import { Box, Typography, IconButton, Collapse, Paper, Divider } from "@mui/material"; 
const CollapsableSection = (props) => {
	const title = props.title;
	const [expanded, setExpanded] = useState(false);
	const toggleExpanded = () => {
		const value = !expanded;
		setExpanded(value);
	}
	let icon = (
		<Add sx={{color:"black", verticalAlign:"middle", fontSize:"2rem"}}/>
	);
	if(expanded){
		icon = <Remove sx={{color:"black", verticalAlign:"middle", fontSize:"2rem"}}/>;
	}
	return (
		<Box>
			<Box>
				<span>
					<span>
						<IconButton sx={{mr:"10px"}}onClick={toggleExpanded}>
							{icon}
						</IconButton>
					</span>
					<Typography className="FormHeader" sx={{display:"inline-block", verticalAlign:"middle"}}>{title}</Typography>
				</span>
			</Box>		
			<Divider sx={{mt:"5px", mb:"5px"}}/>
			<Collapse in={expanded}>
				<Box sx={{padding:"15px"}}>
					{props.children}	
				</Box>
			</Collapse>
		</Box>
	);
};
export default CollapsableSection;
