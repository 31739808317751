import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { TextField, Paper, Stack, Container, Grid, Box, IconButton, InputAdornment, Typography, Button } from "@mui/material";
import UserContext from "./../../../Context/UserContext";
import FormContext from "./../../../Context/FormContext";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";

const SocialMediaLinks = (props) => {
	// context
	const context = useContext(UserContext);
	const forms = useContext(FormContext);
	// state
	const [links, setLinks] = useState(null);
	const [errors, setErrors] = useState({});
	const constraints = {
		"facebook_href": { presence: { allowEmpty: true }, length: { maximum: 255 } },
		"twitter_href": { presence: { allowEmpty: true }, length: { maximum: 255 } },
		"instagram_href": { presence: { allowEmpty: true }, length: { maximum: 255 } },
	};
	useEffect(()=>{
		if(links !== null){
			return;
		}
		const s = (response) => { 
			if(response.status === 204){
				const links = {
					"facebook_href": "",
					"instagram_href": "",
					"twitter_href": ""
				};
				setLinks(links);
				return;
			}
			const data = response.data;
			setLinks(data);
		};
		const e = (error) => { toast.error("Failed to load social media links, please contact support."); };
		context.api.getLatestSocialMediaLinks(s, e);
	});
	// events
	const addSocialMediaLinks = (event) => {
		const errors = forms.formHelper.validateAll(constraints, links);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const s = (response) => {
			const data = response.data;
			toast.success("Social media links updated");
			setLinks(data);
		};
		const e = (error) => { toast.error("Failed to update social media links, please contact support."); };
		context.api.addSocialMediaLinks(s, e, links);
	};
	const onTwitterHrefChanged = (event) => {
		const value = event.target.value;
		const copy = {...links};
		copy.twitter_href = value;
		setLinks(copy);
	};
	const onFacebookHrefChanged = (event) => {
		const value = event.target.value;
		const copy = {...links};
		copy.facebook_href = value;
		setLinks(copy);
	};
	const onInstagramHrefChanged = (event) => {
		const value = event.target.value;
		const copy = {...links};
		copy.instagram_href = value;
		setLinks(copy);
	};
	// helpers
	const validateSingle = (field, value) => {
		const copy = {...errors};
		delete copy[field];
		setErrors(copy);
		const e = forms.formHelper.validateSingle(constraints, field, value);
		if(e !== undefined){
			setErrors({...e, ...errors});
		}
	};
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{ return <small style={{display:"block"}} key={`${field}_error_${i}`}>{e}</small>});
		}
		const helperText = {
			"facebook_href": "URL of your facebook page.",
			"twitter_href": "URL of your twitter page.",
			"instagram_href": "URL of your instagram page."
		};
		return helperText[field];
	}
	const getHasError = (field) => {
		return errors[field] !== undefined;
	}
	let content = <LoadingSpinner />;
	if(links !== null){
		const exists = links.id !== undefined;
		let header = `Current Social Media Links - None`;
		if(exists){
			const dateCreated = new Date(Date.parse(links.created_at));
			header = `Current Social Media Links - Created ${dateCreated.toLocaleDateString()}`;
		}
		content = (
			<Stack spacing={2}>
				<Box sx={{textAlign:"center"}}>
					<Stack spacing={1}>
						<InStockHeader>{header}</InStockHeader>
						<InStockSubHeader>Please complete the following form to update social media links.</InStockSubHeader>
						<InStockSubHeader>None of these inputs are required, to hide a link leave its URL blank.</InStockSubHeader>
					</Stack>
				</Box>
				<TextField 
					error={getHasError("facebook_href")}
					type={"text"} 
					fullWidth 
					label="Facebook"
					size={"large"} 
					value={links.facebook_href} 
					onChange={onFacebookHrefChanged}
					onBlur={()=>{validateSingle("facebook_href", links.facebook_href);}}
					helperText={getHelperText("facebook_href")} />
				<TextField 
					error={getHasError("twitter_href")}
					type={"text"} 
					fullWidth 
					label="Twitter"
					size={"large"} 
					value={links.twitter_href} 
					onChange={onTwitterHrefChanged}
					onBlur={()=>{validateSingle("twitter_href", links.twitter_href);}}
					helperText={getHelperText("twitter_href")} />
				<TextField 
					error={getHasError("instagram_href")}
					type={"text"} 
					fullWidth 
					label="Instagram"
					size={"large"} 
					value={links.instagram_href} 
					onChange={onInstagramHrefChanged}
					onBlur={()=>{validateSingle("instagram_href", links.instagram_href);}}
					helperText={getHelperText("instagram_href")} />
				<Grid container>	
					<Grid item lg={4} sm={12}/>
					<Grid item lg={4} sm={12}>
						<InStockButton size={"large"} fullWidth onClick={addSocialMediaLinks}>Submit</InStockButton>
					</Grid>	
				</Grid>
			</Stack>
		);
	}
	return (
		<Container maxWidth="md">
			<Paper elevation={3} sx={{padding:"15px"}}>
				{content}
			</Paper>			
		</Container>
	);
};

export default SocialMediaLinks;
