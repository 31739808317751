// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UserContext from "./../Context/UserContext";
import InStockClient from "./../Api/InStockClient";
import { Container, Dialog, Grid, Stack, AppBar, Toolbar, IconButton, Box, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff, Twitter, Facebook, Instagram } from "@mui/icons-material";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import SampleImage from "./../Assets/Images/browser.svg";
const validator = require("validate.js");
const HomePage = (props) => {
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [links, setLinks] = useState(null);
	const [loginOpen, setLoginOpen] = useState(false);	
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState({});
	const [notified, setNotified] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState("Logging in ...");
	// hooks
	useEffect(()=>{
		if(links !== null){
			return;
		}
		const s = (response) => {
			const settings = response.data;
			setLinks(settings);
		};
		const e = (error) => {
			toast.error("Failed to get social media links, please check backend.");
		};
		InStockClient.getInstance(null).getLatestSocialMediaLinks(s, e);
	});
	useEffect(()=>{
		if(notified){
			return;
		}
		const search = location.search;
		if(search.indexOf("message=") !== -1){
			let message = search.substring(search.indexOf("message=")+"message=".length);	
			message = unescape(message);
			toast.success(message);
		}
		setNotified(true);
	});
	// handle login
	const doLogin = () => {
		const data = { email: email, password: password };
		const constraints = {
			"email": { email: true, presence: { allowEmpty: false } },
			"password": { presence: { allowEmpty: false } }
		};
		setErrors({});
		const errors = validator.validate(data, constraints);
		if(errors !== undefined){
			toast.error("Please fix any errors and try again.");
			setErrors(errors);
			return;
		}
		const client = InStockClient.getInstance(null);
		const s = async (response) => {
			const token = response.data;
			context.mainLocationId = token.mainLocationId;
			context.admin = token.admin;
			context.userId = token.userId;
			context.token = token.token;
			context.locations = null;
			context.api = InStockClient.getInstance(context.token);
			if(context.admin){
				// admin user
				// don't need to load setup information here ...
				context.navigation = "dashboard";
				navigate("/dashboard");
				return;
			}
			setLoading(true);
			setLoadingMessage("Loading your locations ...");
			let setup = await context.api.getUserSetupStatusSync(context);
			setup = setup.data;
			context.setupComplete = setup.setupComplete;
			context.locationCount = setup.locationCount;
			let locations = await context.api.getLocationsSync(context);
			locations = locations.data;
			// locations = locations.filter((x)=>{ return x.active });
			context.locations = locations;
			context.selectedLocation = locations.find((x)=>{return x.id === context.mainLocationId  });
			context.selectedLocation = context.selectedLocation === undefined ? null : context.selectedLocation;
			context.stockCategories = context.selectedLocation === null ? [] : context.selectedLocation.stock_categories;
			context.stocktakingLocations = context.selectedLocation === null ? [] : context.selectedLocation.stocktaking_locations;
			setLoading(false);
			// normal user
			context.navigation = "settings";
			navigate("/settings");
		};
		const e = (error) => {
			toast.error("Failed to login, please check your credentials.");
			context.locations = null;
			context.userId = null;
			context.token = null;
			setLoading(false);
		};
		client.login(data, s, e);
	};
	const emailError = Object.keys(errors).find((e)=>{ return e === "email"}) !== undefined;
	const passwordError = Object.keys(errors).find((e)=>{ return e === "password"}) !== undefined;
	let emailHelp = "Please enter your email";
	let passwordHelp = "Please enter your password";
	if(emailError){
		emailHelp = errors["email"].join(", ");
	}
	if(passwordError){
		passwordHelp = errors["password"].join(", ");
	}
	const eye = (
		<InputAdornment position={"end"}>
			<IconButton onClick={()=>{setShowPassword(!showPassword);}}>
				{!showPassword ? <Visibility sx={{color:"black"}}/> : <VisibilityOff sx={{color:"black"}}/>}
			</IconButton>
		</InputAdornment>
	);
	let twitterLink = "#";
	let facebookLink = "#";
	let instagramLink = "#";
	if(links !== null){
		twitterLink = links.twitter_href;
		facebookLink = links.facebook_href;
		instagramLink = links.instagram_href;
	}
	return (
		<Dialogs loading={loading} loadingMessage={loadingMessage}>
		<Stack>
		<Dialog open={loginOpen} onClose={()=>setLoginOpen(false)} maxWidth="sm" fullWidth>
			<Box sx={{padding:"15px", backgroundColor:"white"}}>
				<Stack spacing={3}>
					<Typography variant="h3" align="center" sx={{color:"black"}}>Login</Typography>
					<TextField helperText={emailHelp} 
						fullWidth
						error={emailError} 
						size="large" 
						variant="outlined" 
						label="Email" 
						value={email} 
						onChange={(event)=>{setEmail(event.target.value)}} />
					<TextField helperText={passwordHelp} 
						fullWidth
						error={passwordError} 
						size="large" 
						variant="outlined"
						label="Password" 
						InputProps={{endAdornment: eye}}
						type={showPassword ? "text" : "password"} 
						value={password} 
						onChange={(event)=>{setPassword(event.target.value)}} />
					<Button type="submit" variant="contained" size="large" fullWidth onClick={doLogin}>Login</Button>
					<Box sx={{textAlign:"center", padding:"5px"}}>
						<Link style={{color:"black", textDecoration:"none"}} to={"/forgot-password"}>Forgot password?</Link>
					</Box>
				</Stack>
			</Box>
		</Dialog>
		<Container maxWidth="lg">
		<Box sx={{flexGrow: 1}}>
			<AppBar position="fixed">
					<Toolbar>
					<Typography variant="h3" component="div" sx={{flexGrow: 1}} color="white">Instock</Typography>
					<Button color="inherit" onClick={(event)=>{setLoginOpen(true);}}>Login</Button>
					<Button color="inherit" onClick={(event)=>{navigate("/free-trial");}}>Sign Up</Button>
					</Toolbar>
			</AppBar>
		</Box>
		<Box sx={{flexGrow: 1, marginTop:"150px"}}>
			<Grid container spacing={8}>
				<Grid xs={12} lg={6} item sx={{border:"1px solid white", borderRadius:"15px"}}>
					<Stack spacing={2} sx={{marginTop:"15px"}}>
						<Typography variant="h2" component="p" color="inherit" sx={{fontWeight:"bold"}}>
							InStock, It's Amazing. You should try it.
						</Typography>
						<Typography variant="caption" element="p" sx={{color:"#343434", fontSize:"18px"}}>
							Click the following buttons to start a trial.
						</Typography>
						<Stack direction="row" spacing={2}>
							<Button size="large" variant="contained">
								<Link to={"/free-trial"} style={{textDecoration:"none", "color": "white"}}>Get Started</Link>
							</Button>
							<Button size="large" variant="outlined">
								<Link to={"/contact-us"} style={{textDecoration:"none", "color": "inherit"}}>Contact Us</Link>
							</Button>
						</Stack>
					</Stack>
				</Grid>	
				<Grid xs={12} lg={6} item sx={{border: "1px solid white", borderRadius:"15px" }}>
					<img src={SampleImage} style={{height:"auto", width:"100%"}} alt="Sample image ..." title="Sample image"/>
				</Grid>	
			</Grid>
		</Box>
		<Box sx={{flexGrow:1, marginTop:"150px"}}>
			<Grid container>
				<Grid xs={12} lg={12} item>
					<Stack spacing={2}>
						<Typography variant="h2" component="p" align="center" sx={{color:"black", fontWeight:"bold"}}>
							Your product name or headline here.	
						</Typography>	
						<Typography variant="caption" element="p" align="center" sx={{color:"#343434", fontSize:"18px"}}>
							Click the following buttons to start a trial.
						</Typography>
						<Box sx={{flexGrow:1, textAlign:"center"}}>
							<Button size="large" sx={{mr:"15px"}} variant="contained"><Link to={"/free-trial"} style={{textDecoration:"none", "color": "white"}}>Get Started</Link></Button>
							<Button size="large" variant="outlined"><Link to={"/contact-us"} style={{textDecoration:"none", "color": "inherit"}}>Contact Us</Link></Button>
						</Box>
					</Stack>
				</Grid>		
			</Grid>	
		</Box>
		</Container>
		<Box sx={{flexGrow:1, marginTop:"150px", backgroundColor:"#1976d2", paddingTop:"50px", paddingBottom:"50px"}}>
			<Stack spacing={2}>
				<Typography variant="h3" component="p" align="center" sx={{color:"white", fontWeight:"bold"}}>
					Sign up today for a free trial.			
				</Typography>	
				<Box sx={{textAlign:"center"}}>
				<Button size="large"  sx={{color:"#1976d2", backgroundColor:"white", "&:hover":{backgroundColor:"white"}}} onClick={()=>{navigate("/free-trial");}}>Sign Up</Button>
				</Box>
			</Stack>
		</Box>
		<Box sx={{backgroundColor:"white", paddingTop:"2em", paddingBottom:"2em", textAlign:"center"}}>	
			<Typography sx={{mt:"1em", mb:"1em"}}>Copyright 2022 InStock Pty Ltd</Typography>
			<a href={twitterLink}><Twitter sx={{color:"black", mr:"10px"}}/></a>
			<a href={facebookLink}><Facebook sx={{color:"black", mr:"10px"}}/></a>
			<a href={instagramLink}><Instagram sx={{color:"black", mr:"10px"}}/></a>
			<Typography sx={{mt:"1em", mb:"1em"}}>Version 1.01</Typography>
		</Box>
		</Stack>
		</Dialogs>
	);
};
export default HomePage;
