// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import UserContext from "./../../../Context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import BinIcon from "@mui/icons-material/Delete";
import { InStockHeader, InStockSubHeader, InStockButton } from "./../../Styled";
import { Box, Container, Stack, Typography, IconButton, Grid, ListItem, ListItemText, FormControl, InputLabel, FilledInput, InputAdornment, Paper, MenuItem, TextField } from "@mui/material";
const validator = require("validate.js");
const ActivateSalesItem = (props) => {
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [salesItems, setSalesItems] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [errors, setErrors] = useState(undefined);
	const context = useContext(UserContext);
	const loading = salesItems === null;
	const onSubmitClicked = () => {
		if(itemId === -1 || itemId === null){
			toast.info("Please select an item first.");
			return;
		}			
		const s = (response) => {
			toast.success("Sales item activated.");		
			props.onSalesItemActivated();
		};
		const e = (error) => {
			toast.error("Failed to activate sales item, please contact support.");
		};
		if(!impersonate){
			context.api.activateSalesItem(context, s, e, itemId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/sales-items/${itemId}/activate`,
      "method": "PUT",
			"data": JSON.stringify({})
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	useEffect(()=>{
		if(salesItems !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setSalesItems(data);
		};
		const e = (error) => {
			toast.error("Failed to load salesItems, please contact support.");
		};
		if(!impersonate){
			context.api.getSalesItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/sales-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	let content = <LoadingSpinner />;
	if(!loading){
		let items = salesItems.map((e,i)=>{ return <MenuItem key={e.id} value={e.id}>{e.sales_item_name}</MenuItem>});
		items = [<MenuItem value={-1} key={-1}>Select an item ...</MenuItem>, ...items];
		content = (
			<Stack spacing={2}>
				<TextField 
						select
						onChange={(event)=>{setItemId(event.target.value);}}
						label={"Sales Item"}
						value={itemId}
						error={itemId === -1}
						helperText={"Select an item to activate"}>
					{items}		
				</TextField>
				<InStockButton fullWidth size={"large"} onClick={onSubmitClicked}>Submit</InStockButton>
			</Stack>
		);
	}
	return (
		<Paper elevation={2} className="instock-view">
			<Box sx={{textAlign:"center"}}>
				<Stack spacing={2}>
					<InStockHeader>Please select a sales item to activate.</InStockHeader>
					<InStockSubHeader>All variations of this item will be activated once this form is submitted.</InStockSubHeader>
					{content}
				</Stack>
			</Box>
		</Paper>
	);
};
export default ActivateSalesItem;
