import React from "react";
import { Stack, Grid } from "@mui/material";
import CollapsableSection from "./../CollapsableSection/CollapsableSection";
import VerificationSettings from "./AdminSettings/VerificationSettings";
const EmailSettingsView = (props) => {	
	return (
		<Grid container>
			<Grid item lg={12} sm={12}>
				<Stack spacing={1}>
					<CollapsableSection title={"Verification"}>
						<VerificationSettings /> 
					</CollapsableSection>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default EmailSettingsView;
